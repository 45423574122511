/* eslint-disable import/prefer-default-export */
import clsx from "clsx";

export const Rounded = () => (
  <div
    className={clsx(
      'rounded rounded-md	rounded-lg	rounded-xl	rounded-2xl	rounded-3xl	rounded-full rounded-t-none	',
      'rounded-t-sm	rounded-t	rounded-t-md	rounded-t-lg	rounded-t-xl	rounded-t-2xl	rounded-t-3xl	rounded-t-full	',
      'rounded-r-none	rounded-r-sm	rounded-r	rounded-r-md	rounded-r-lg	rounded-r-xl	rounded-r-2xl'
    )}
  />
);
export const TextBox = ({ className, children, label, ...rest }) => {
  return (
    <div
      className={`text-inherit text-current text-transparent text-black	text-white	text-slate-700	text-gray-700	text-zinc-700	text-neutral-700	text-stone-700	text-red-700	text-orange-700	text-amber-700	text-yellow-700	text-lime-700	text-green-700	text-emerald-700	text-teal-700	text-cyan-700	text-sky-700	text-blue-700	text-indigo-700	text-violet-700	text-purple-700	text-fuchsia-700	text-pink-700	text-rose-700	${className}`}
    >
      {label}
    </div>
  );
};

export const RoundBoxCustom = ({
  className,
  children,
  label,
  color,
  variant,
  rounded,
  ...rest
}) => {
  const colorVariant = variant || '700';
  return (
    <div
      className={clsx(
        'justify-center items-center inline-flex ',
        'font-semibold ',
        'border text-center px-1 mx-1',
        className,
        rounded ? `rounded-${rounded}` : 'rounded-full',
        color ? `border-${color}-${colorVariant}` : '',
        color ? `text-${color}-${colorVariant}`: '',
      )}
    >
      {label} {children}
    </div>
  );
};
