/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@gvlab/react-theme/mui';
import { RadioField } from '../styles';
/**
 * sample config
 * 
  {
  title: 'Platform',
  component: 'Radio',
  options: [
    {
      value: ' ',
      label: 'Please select the platform'
    },
    {
      value: 'mbb',
      label: 'MBB2U',
    },
    {
      value: 'tnb',
      label: 'TNB',
    },
    {
      value: 'm1',
      label: 'M1',
    },
  ],
  dataIndex: 'attributes.platform',
  key: 'platform',
}, 
 */
const RadioInput = (props) => {
  const { dataSource, onChange, onFormItemGetValue, onCheckDisabled, formRegister } = useSelector(
    ({ app }) => ({
      dataSource: app.form.props.options.dataSource,
      onChange: app.form.props.options.onChange,
      onFormItemGetValue: app.form.props.options.onFormItemGetValue,
      onCheckDisabled: app.form.props.options.onCheckDisabled,
      formRegister: app.form.props.options.formRegister,
    }),
  );
  const { field, fieldProps, ...rest } = props;

  return (
    <RadioField>
      <FormLabel>{fieldProps.title}</FormLabel>
      <RadioGroup
        name={field?.key}
        defaultValue={fieldProps.defaultValue}
        {...formRegister(field.key, fieldProps)}
        {...rest}
      >
        {field.options.map((option, uniqueId) => {
          return (
            <FormControlLabel
              key={uniqueId}
              value={option.value}
              control={<Radio key={option.key} size="small" />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
      {fieldProps.helperText && <FormHelperText>{fieldProps.helperText}</FormHelperText>}
    </RadioField>
  );
};

export default RadioInput;
