/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';

const initialTokens = {
  state: false,
  error: false,
  token: '',
  refreshToken: '',
}

const authTokensSlice = createSlice({
  name: 'tokens',
  initialState: initialTokens,
  reducers: {
    init:  (state, action) => {
      state = initialTokens
    },
    setTokens: (state, action) => {
      state.state = 'DONE';
      state.error = false;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    setError: (state, action) => {
      state.state = 'ERROR';
      state.error = action.payload;
      state.token = '';
      state.refreshToken = '';
    },
  },
});

export const { init, setTokens, setError } = authTokensSlice.actions;

export default authTokensSlice.reducer;
