/* eslint-disable import/no-extraneous-dependencies */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';

const CardFooterBase = styled('div')(({ theme }) => ({
}));

const CardFooter = (props) => {
  const { children, className } = props;

  return (
    <CardFooterBase className={clsx('card-footer', className)}>
      {children}
    </CardFooterBase>
  );
};

CardFooter.propTypes = {
  className: PropTypes.string,
};

export default CardFooter;
