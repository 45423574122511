/* eslint-disable import/no-anonymous-default-export */
import { BaseModel } from '@gvlab/react-dataset';
import { dateUtils, numberUtils } from '@gvlab/react-lib/utils';
import { ChevronRight as ChevronRightIcon } from '@gvlab/react-icons';
import { useDispatch } from 'react-redux';
import { show, close } from 'store/loadingSlice';

import DocumentStatus from './components/DocumentStatus';

// default value
export const defaultValue = {
  project: {
    type: 'project',
    subject: '',
    description: '',
    budget: 0,
    total_amount: 0,
    duedate: '',
  },
  owner: {
    fullName: '',
    phone: '',
    passport: false,
  },
  property: {
    description: false,
    line1: '',
    line2: '',
    city: '',
    country: 'malaysia',
    postcode: '',
    bedroom: 0,
    bathroom: 0,
    size: 1400,
    uom: 'sqft',
    type: 'apartment',
  },
};

// quotation model
export default () => {
  const dispatch = useDispatch();

  return BaseModel({
    name: 'quotations',
    attributes: [
      {
        title: '',
        component: 'Input',
        dataIndex: 'id',
        key: 'seq2',
        width: '30%',
        cell: (dataset) => {
          const ttlAmount = numberUtils.currencyFormat(dataset.totalAmount, ' ');
          const { subject } = dataset;
          return (
            <div className="flex flex-col">
              <h5 variant="h5" className="text-lg prose prose-lg">
                {subject}
              </h5>
              <p className="text-sm prose prose-lg">
                # <span className="uppercase font-bold">{dataset.id.slice(-12)}</span>
              </p>
              <p className="text-sm prose prose-lg">Total of {ttlAmount}</p>
            </div>
          );
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: '20%',
        cell: (dataset) => {
          const labelUpdateAt = dataset.updateAt
            ? dateUtils.renderDate(dataset.updateAt)
            : dateUtils.renderDate(dataset.createAt);
          const labelSentAt = dataset?.sentAt ? dateUtils.renderDate(dataset.sentAt) : '-';
          const labelDueAt = dataset?.dueAt ? dateUtils.renderDate(dataset.dueAt) : '-';

          return (
            <div>
              Update {labelUpdateAt} <br />
              Sent {labelSentAt} <br />
              Due {labelDueAt}
            </div>
          );
        },
      },
      {
        title: '',
        align: 'right',
        component: 'Input',
        dataIndex: 'status',
        key: 'statusExt',
        width: '15%',
        cell: (dataset) => <DocumentStatus status={dataset.status} />,
      },
      {
        title: '',
        align: 'right',
        component: 'Input',
        dataIndex: 'status',
        key: 'arrow',
        width: '5%',
        cell: (dataset) => <ChevronRightIcon />,
      },
    ],
    beforeFetch: ({ parent, request }) => {
      let paramStr = '?userType=pro';
      if (request?.quotationsId) {
        paramStr += `&quotationsId=${request?.quotationsId}`;
      }

      if (request?.customer) {
        paramStr += `&customer=${request?.customer}`;
      }

      if (request?.passport) {
        paramStr += `&passport=${request?.passport}`;
      }

      if (request?.phone) {
        paramStr += `&phone=${request?.phone}`;
      }

      if (request?.email) {
        paramStr += `&email=${request?.email}`;
      }

      if (request?.profilesId) {
        paramStr += `&profilesId=${request?.profilesId}`;
      }

      if (request?.projectsId) {
        paramStr += `&projectsId=${request?.projectsId}`;
      }

      if (request?.propertyId) {
        paramStr += `&propertyId=${request?.propertyId}`;
      }

      if (request?.status) {
        const status = request.status.map((item) => {
          return item.value;
        });
        paramStr += `&status=${status.toString()}`;
      }

      dispatch(show());

      return parent.formatParams('get', `/user/v1/quotations${paramStr}`, request);
    },
    afterFetch: ({ parent, response, request }) => {
      dispatch(close());
      return response.data;
    },
    // beforeCreate - function trigger before submit to server
    beforeCreate: ({ parent, request }) => {
      const changes = {
        project: {
          type: 'project',
          subject: request.project_subject || '',
          description: request.project_description || '',
          budget: request.project_budget || 0,
          total_amount: request.project_amount || 0,
          duedate: request.project_duedate,
        },
        owner: {
          fullName: request.owner_fullName,
          phone: request.owner_phone,
          passport: request.owner_passport,
        },
        property: {
          description: request.property_description,
          line1: request.property_line1 || '',
          line2: request.property_line2 || '',
          city: request.property_city || '',
          state: request.property_state || 'kuala lumpur',
          country: request.property_country || 'Malaysia',
          postcode: request.property_postcode || '50000',
          bedroom: request.property_bedroom || 0,
          bathroom: request.property_bathroom || 0,
          size: request.property_size || 1400,
          uom: request.property_uom || 'sqft',
          type: request.property_type,
        },
      };
      return { method: 'post', url: '/user/v1/quotations?userType=pro', params: changes };
    },
    // beforeDelete - function trigger before submit a delete to server
    beforeDelete: ({ parent, request }) => {
      const { id } = request;
      return parent.formatParams('delete', `/user/v1/quotations/${id}?userType=pro`, request);
    },
    // beforeUpdate - function trigger before submit a update to server
    beforeUpdate: ({ parent, request }) => {
      const { id } = request;
      const changes = {};

      if (request.items) {
        changes.items = request.items
          .filter((item, idx) => idx >= 0)
          .map((item, idx) => {
            return {
              ...item,
              seq: idx,
              price: item.amount,
              billable: 't',
              group_id: item.groupId,
            };
          });
      }

      if (request.owner_email) {
        changes.owner = {
          ...changes.owner,
          email: request.owner_email,
        };
      }

      if (request.owner_fullname) {
        changes.owner = {
          ...changes.owner,
          fullName: request.owner_fullname,
        };
      }

      if (request.owner_name) {
        changes.owner = {
          ...changes.owner,
          fullName: request.owner_name,
        };
      }

      if (request.owner_phone) {
        changes.owner = {
          ...changes.owner,
          phone: request.owner_phone,
        };
      }

      if (request.owner_passport) {
        changes.owner = {
          ...changes.owner,
          passport: request.owner_passport,
        };
      }

      if (request.status) {
        changes.project = {
          ...changes.project,
          status: request.status,
        };
        changes.status = request.status;
      }

      if (request.project_budget) {
        changes.project = {
          ...changes.project,
          budget: Number(request.project_budget),
        };
      }

      if (request.project_amount) {
        changes.project = {
          ...changes.project,
          amount: Number(request.project_amount),
        };
      }

      if (request.project_description) {
        changes.project = {
          ...changes.project,
          description: request.project_description,
        };
      }

      if (request.project_subject) {
        changes.project = {
          ...changes.project,
          subject: request.project_subject,
        };
      }

      if (request.project_duedate) {
        changes.project = {
          ...changes.project,
          duedate: request.project_duedate,
        };
      }

      if (request.property_description) {
        changes.property = {
          ...changes.property,
          description: request.property_description,
        };
      }

      if (request.property_state) {
        changes.property = {
          ...changes.property,
          state: request.property_state,
        };
      }

      if (request.property_postcode) {
        changes.property = {
          ...changes.property,
          postcode: request.property_postcode,
        };
      }

      if (request.property_zipCode) {
        changes.property = {
          ...changes.property,
          postcode: request.property_zipCode,
        };
      }

      if (request.property_line1) {
        changes.property = {
          ...changes.property,
          line1: request.property_line1,
        };
      }

      if (request.property_line2) {
        changes.property = {
          ...changes.property,
          line2: request.property_line2,
        };
      }

      if (request.property_city) {
        changes.property = {
          ...changes.property,
          city: request.property_city,
        };
      }

      if (request.property_state) {
        changes.property = {
          ...changes.property,
          state: request.property_state,
        };
      }

      if (request.property_country) {
        changes.property = {
          ...changes.property,
          country: request.property_country,
        };
      }

      if (request.property_bedroom) {
        changes.property = {
          ...changes.property,
          bedroom: request.property_bedroom,
        };
      }

      if (request.property_bathroom) {
        changes.property = {
          ...changes.property,
          bathroom: request.property_bathroom,
        };
      }

      if (request.property_size) {
        changes.property = {
          ...changes.property,
          size: request.property_size,
        };
      }

      if (request.property_uom) {
        changes.property = {
          ...changes.property,
          uom: request.property_uom,
        };
      }

      if (request.property_type) {
        changes.property = {
          ...changes.property,
          type: request.property_type,
        };
      }

      if (changes?.status === 'confirm') {
        return {
          method: 'post',
          url: `/user/v1/quotations/${id}/confirm?userType=pro`,
          params: changes,
        };
      }

      dispatch(show());
      return { method: 'put', url: `/user/v1/quotations/${id}?userType=pro`, params: changes };
    },
    afterUpdate: ({ parent, response, request }) => {
      dispatch(close());

      this.refetch();

      return response.data;
    },
    getChanges: ({ datasource, fields, changes }) => {
      // for existing record
      if (datasource && datasource?.id) {
        return {
          id: datasource.id,
          ...changes,
        };
      }

      // for new record
      return {
        ...changes,
      };
    },
  });
};
