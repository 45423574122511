/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from 'react-redux';

import { Typography, styled } from '@gvlab/react-theme/styles';
import { Avatar, Divider, BottomNavigation, BottomNavigationAction } from '@gvlab/react-theme/mui';
import {
  Person as PersonIcon,
  Input as InputIcon,
  Settings as SettingsIcon,
} from '@gvlab/react-icons';
import { useAuth } from '@gvlab/react-create-app-gv';
import { selectGvUser } from '@gvlab/react-create-app-gv/stores';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import { closeProfileBar, selectLayoutOptions } from 'Layout/Dashboard/store/layoutSlice';

const drawerWidth = 256;

const StyledProfile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& h1': {
    color: 'black',
  },
  width: drawerWidth,
  minWidth: drawerWidth,
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
}));

const StyledBio = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(1),
  textAlign: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${theme.palette.white}`,
  height: 120,
  width: 120,
}));

const StyledPersonName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textTransform: 'uppercase',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

const StyledToolbar = styled(BottomNavigation)(({ theme }) => ({
  textAlign: 'center',
  height: '60px',
}));

const ProfileBar = () => {
  const dispatch = useDispatch();
  const authCtx = useAuth();
  const user = useSelector(selectGvUser);
  const layoutOptions = useSelector(selectLayoutOptions);

  return (
    <Drawer
      actions={() => ''}
      fullSize={false}
      onClose={() => dispatch(closeProfileBar())}
      open={layoutOptions.profileBar}
      title="Profile"
    >
      <div className="h-full flex flex-col justify-start items-center bg-gray-50 text-gray-800 p-8 shadow-md rounded-xl sm:px-12">
        {user && (
          <StyledProfile>
            <StyledAvatar alt="Person" src={user?.avatar || '/images/avatars/default.png'} />
            <StyledBio>
              <Typography component="h3" gutterBottom>
                {user?.email}
              </Typography>
              <Typography component="h2" gutterBottom variant="overline">
                {user?.role}
              </Typography>
              <StyledPersonName variant="h6">{user?.fullName}</StyledPersonName>
            </StyledBio>
          </StyledProfile>
        )}
        <StyledDivider />
        <StyledToolbar showLabels>
          <BottomNavigationAction
            label="Profile"
            icon={
              <PersonIcon
                onClick={() => {
                  authCtx.redirect('/profile');
                }}
              />
            }
          />
          {user?.role === 'superAdmin' && (
            <BottomNavigationAction
              label="Dashboard"
              icon={
                <SettingsIcon
                  onClick={() => {
                    authCtx.redirect(`/dashboard/overview`);
                  }}
                />
              }
            />
          )}
          <BottomNavigationAction
            label="Exit"
            icon={<InputIcon />}
            onClick={() => {
              authCtx.logout();
            }}
          />
        </StyledToolbar>
      </div>
    </Drawer>
  );
};

export default ProfileBar;
