/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { safeSetValue } from '@gvlab/react-lib/utils';

const initialValue = {
  mode: null,
  datasource: null,
  errors: null,
};
const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    state: false,
    options: initialValue,
  },
  reducers: {
    openDialog: (state, action) => {
      state.state = true;
      state.options = safeSetValue(state.options, action.payload);
    },
    closeDialog: (state, action) => {
      state.state = false;
      state.options = initialValue;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
