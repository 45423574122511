import { lazy } from 'react';
import { BlankLayout } from '@gvlab/react-layout';

const routes = [
  {
    path: '/public',
    component: (props) => <BlankLayout {...props} />,
    routes: [
      {
        path: '/public/landing',
        exact: true,
        component: lazy(() => import('../components/Public/Landing')),
      },
      {
        path: '/public/page',
        exact: true,
        component: lazy(() => import('../components/Public/Page')),
      },
      {
        path: '/public/storybook',
        exact: false,
        component: BlankLayout,
        routes: [
          {
            path: '/public/storybook/card',
            exact: true,
            component: lazy(() => import('../components/Public/Storybook/Cards')),
          },
          {
            path: '/public/storybook/chat',
            exact: true,
            component: lazy(() => import('../components/Public/Storybook/Chat')),
          },
          {
            path: '/public/storybook/sidebar',
            exact: true,
            component: lazy(() => import('../components/Public/Storybook/Sidebar')),
          },
          {
            path: '/public/storybook/sidebar',
            exact: true,
            component: lazy(() => import('../components/Public/Storybook/Sidebar')),
          },
          {
            path: '/public/storybook/tab',
            exact: true,
            component: lazy(() => import('../components/Public/Storybook/Tabs')),
          },

          {
            component: lazy(() => import('../components/Public/Storybook')),
          },
        ],
      },
    ],
  },
];

export default routes;
