import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, colors } from '@gvlab/react-theme/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Menu = (props) => {
  const { className, children } = props;
  const classes = useStyles();

  return <nav className={clsx(classes.root, className)}>{children}</nav>;
};

Menu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Menu.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: 'contained',
  shape: 'square',
};

export default Menu;
