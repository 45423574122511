/* eslint-disable import/prefer-default-export */
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

export const FormBase = ({ defaults, values, children }) => {
  const formProp = {
    mode: 'onChange',
    defaultValues: values,
  };
  const formMethods = useForm(formProp);
  const {
    register,
    getValues: formGetValues,
    control: formControl,
    formState: { errors },
  } = formMethods;
  const formItemsMethods = useFieldArray({
    control: formControl,
    name: 'items',
  });

  return (
    <FormProvider {...formMethods} formItems={formItemsMethods} errors={errors}>
      {children}
    </FormProvider>
  );
};
