/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { createContext, useContext, useMemo } from 'react';
import { v1 as uuid } from 'uuid';

import objectByString from './objectByString';
import updateObjectByString from './updateObjectByString';
import numberFormat from './numberUtils';
import * as responseUtils from './responseUtils';
import * as listUtils from './listUtils';
import * as dateUtils from './dateUtils';
import * as promises from './promises';

const UtilsContext = createContext(null);

const useUtilsContext = () => {
  const context = useContext(UtilsContext);
  if (!context) {
    throw new Error('useUtilsContext must be used within a UtilsContextProvider');
  }
  return context;
};

const UtilsProvider = ({ children, utils }) => {
  return (
    <UtilsContext.Provider
      value={useMemo(
        () => ({
          ...promises,
          dateUtils,
          ...responseUtils,
          ...listUtils,
          objectByString,
          uniqueKey: () => uuid(),
          updateObjectByString,
          numberFormat,
          ...utils,
        }),
        [],
      )}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export { UtilsContext, useUtilsContext, UtilsProvider };
