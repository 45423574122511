/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import MenuItemBlock from './MenuItemBlock';

// Rendering MenuItem block
/*
  {
    title: 'General',
    pages: [
      {
        title: 'Overview',
        href: '/dashboard/overview',
        icon: icons['Home'],
        label: green[500], Mock
      },
      ...
      {
        title: 'Inventory',
        href: '/Inventory',
        icon: icons['Inventory'],
        children: [
          {
            title: 'Children block'
            ...
          }
          ...
        ]
      },
      ...
    ],
  },
 * */

const MenuItem = ({ title, pages, className, component: Component, ...rest }) => (
  <Component className="mb-1" {...rest}>
    {title &&<h2 className="text-sm font-semibold tracking-widest uppercase">
      {title}
    </h2>}
    {pages && <MenuItemBlock depth={0} pages={pages} />}
  </Component>
);

MenuItem.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

MenuItem.defaultProps = {
  component: 'nav',
};

export default MenuItem;
