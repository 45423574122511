/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Add as AddIcon } from '@gvlab/react-icons';
import { numberUtils } from '@gvlab/react-lib/utils';
import { Button } from '@gvlab/react-ui/v2';

import { setStatus, showDraft, selectTaskDraftState } from 'store/Dashboard/taskDraftSlice';

import { ListTask } from './ListTask';

const initialValues = {};

export const ListPhase = ({ phase, formState, groupId, total, onRefresh, onView, onViewTask }) => {
  const dispatch = useDispatch();
  const { setValue, getValues, formItems } = useFormContext();
  const [order] = useState(() => {
    if (phase?.order && phase?.order[0]) {
      return phase?.order[0];
    }
    return false;
  });
  const taskDraftState = useSelector(selectTaskDraftState);

  const handleRefresh = () => {
    dispatch(setStatus('fetching'));
  };

  const handleUpdateTask = (taskId) => {
    // default holder for group
    const oldItem = getValues().items[taskId];

    formItems.update(taskId, {
      ...oldItem,
      amount: Number(oldItem.amount),
    });

    handleRefresh();
  };

  const handleRemoveTask = (taskId) => {
    formItems.remove(taskId);

    handleRefresh();
  };

  const itemList = formItems.fields
    .map((task, idx) => ({ ...task, idx }))
    .filter((task) => {
      return task.groupId === groupId;
    });

  return (
    <div className="col-span-6 overflow-hidden mb-4 border-collapse">
      <ul role="list" className="divide-y divide-gray-200">
        <li className="border-t border-gray-200 bg-gray-200 ">
          <div className="flex flex-1 flex-col w-full px-4">
            <div className="flex justify-between text-base font-medium text-gray-900 py-2 px-2 items-center">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Phase {groupId}</h3>
              </div>
              <Button
                className="button button-info text-info"
                onClick={() => {
                  dispatch(showDraft(groupId));
                }}
              >
                <AddIcon fontSize="small" /> Add Item
              </Button>
            </div>
          </div>
        </li>
        {Array.isArray(itemList) &&
          itemList.map((item) => {
            return (
              <ListTask
                key={item.id}
                task={item}
                formState={formState}
                groupId={groupId}
                onRemove={handleRemoveTask}
                onUpdate={handleUpdateTask}
                onViewTask={onViewTask}
              />
            );
          })}
        {itemList.length === 0 && (
          <li className="border-t border-gray-200 ">
            <div className="flex flex-1 flex-col w-full px-4">
              <div className="flex justify-between text-base font-medium text-gray-900 py-2 px-2 items-center">
                <p className="ml-4 text-sm">No Record</p>
              </div>
            </div>
          </li>
        )}
        <li className="border-t border-gray-200 bg-gray-200 ">
          <div className="flex flex-1 flex-col w-full px-4">
            <div className="flex justify-between text-base font-medium text-gray-900 py-2 px-2 items-center">
              <p className="ml-4 text-sm">
                {Array.isArray(itemList) && `Number of item: ${itemList.length}`}
              </p>
              <p className="ml-4 text-sm">
                Total of <b>{numberUtils.currencyFormat(total, 0, ' ')}</b> credit
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
