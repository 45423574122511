import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider } from '@gvlab/react-theme/mui';
import MainMenu from '@gvlab/react-ui/v2/Menu/MainMenu';
import Menu from '@gvlab/react-ui/v2/Menu/Menu';
import { Input as InputIcon } from '@gvlab/react-icons';
import { styled } from '@gvlab/react-theme/styles';
import { useAuth } from '@gvlab/react-create-app-gv';
import { selectGvMenus } from '@gvlab/react-create-app-gv/stores';

const StyledContent = styled('div')(({ theme }) => ({
  width: '250px',
}));
const StyledMainMenu = styled(MainMenu)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
const StyledProfile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '64px',
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  height: '1px',
}));
const StyledLogo = styled('img')(({ theme }) => ({
  color: 'black',
  // filter: 'invert(1)',
  padding: '4px',
}));
const StyledNavigation = styled(Menu)(({ theme }) => ({
  padding: theme.spacing(2),
  '& overline': {
    color: theme.palette.primary.main,
  },
}));
const StyledLoginPanel = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
const StyledLogoutButton = styled(Button)(({ theme }) => ({
  ...theme.components.MuiSubMenuItem.buttonLeaf,
}));
const StyledLogoutButtonIcon = styled(InputIcon)(({ theme }) => ({}));

const NavbarContent = () => {
  const dispatch = useDispatch();
  const authCtx = useAuth();
  const menus = useSelector(selectGvMenus);

  return (
    <StyledContent className="h-full p-3 space-y-2 w-60 bg-gray-200 text-gray-800">
      <StyledProfile className="flex items-center content-space divide-y divide-white">
        <StyledLogo alt="Logo" src="/images/logos/large__nobg.png" />
      </StyledProfile>
      <StyledMainMenu menus={menus} />
      <StyledLoginPanel>
        <StyledDivider />
        <StyledNavigation>
          <StyledLogoutButton
            color="inherit"
            onClick={() => {
              authCtx.logout();
            }}
          >
            <StyledLogoutButtonIcon />
            Sign out
          </StyledLogoutButton>
        </StyledNavigation>
      </StyledLoginPanel>
    </StyledContent>
  );
};

export default NavbarContent;
