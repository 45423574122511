const StyledComponent = {
  styleOverrides: {
    root: {},
    notchedOutline: {
      borderColor: 'rgba(0,0,0,0.15)',
    },
  },
};

export default StyledComponent;
