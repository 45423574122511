/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, MuiAlert } from '@gvlab/react-theme/mui';
import {
  isFunction,
} from '@gvlab/react-lib/utils';
import { closeSnackbar } from './store/snackbarSlice';

const DialogSnackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(({ gv }) => gv.snackbar);

  const { autoHideDuration, elevation, severity, message } = snackbar.options;

  if (snackbar.state === false) return null;

  const handleClose = () =>{
    if (isFunction(closeSnackbar)) dispatch(closeSnackbar());
  }

  return (
    <Snackbar
      {...snackbar.options}
      autoHideDuration={autoHideDuration || 1500}
      onClose={handleClose}
      open
    >
      <MuiAlert
        elevation={elevation}
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default DialogSnackbar;
