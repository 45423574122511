/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { isFunction, safeSetValue } from '@gvlab/react-lib/utils';

const dialogDefaultContent = {
  button1Caption: 'OK',
  button2Caption: 'Cancel',
  dividers: false,
  fullScreen: false,
  message: 'Message Here',
  title: 'title here',
  onClose: () =>{},
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    state: false,
    options: dialogDefaultContent,
  },
  reducers: {
    openDialog: (state, action) => {
      state.state = true;
      state.options = safeSetValue(state.options, action.payload);
    },
    closeDialog: (state, action) => {
      state.state = false;
      state.options = safeSetValue(state.options, action.payload);

      if (isFunction(state.options.onClose)) {
        state.options.onClose();
      }
    },
    dialogMessage: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload,
        type: 'DialogMessage',
      };
    },
    dialogCritical: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload,
        type: 'DialogCritical',
      };
    },
  },
});

export const { openDialog, closeDialog, dialogMessage, dialogCritical } = dialogSlice.actions;

export const selectDialog = () => ({gv}) => gv.dialog;
export const selectDialogOptions = () => ({gv}) => gv.dialog.options;

export default dialogSlice.reducer;
