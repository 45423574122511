/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import FormActions from './FormActions';

const FormBaseDrawer = props => {
  const { fullSize, onClose, title } = useSelector(({ app }) => ({
    fullSize: app.form.props.options.drawer.fullSize,
    onClose: app.form.props.options.onClose,
    title: app.form.props.options.title,
  }));

  return (
    <Drawer
      actions={<FormActions />}
      fullSize={fullSize}
      onClose={onClose}
      open
      title={title}
    >
      {props.children}
    </Drawer>
  );
};

export default FormBaseDrawer;
