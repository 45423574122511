import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { clientType } from 'config/environment';

const initialState = {
  state: 'idle',
  success: false,
  errors: false,
  detail: null,
};

export const forgotPassword = createAsyncThunk(
  'recover/forgotPassword',
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .post('/user/v1/user/recover', {
          email,
          factorType: 'EMAIL',
          userType: clientType,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyPassword = createAsyncThunk(
  'recover/verifyPassword',
  async ({ email, password, passcode }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .post('/user/v1//user/recovery-verify', {
          email,
          password,
          passcode,
          userType: clientType,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const init = createAsyncThunk('recover/init', async (_, { dispatch }) => 'done');

export const verify = createAsyncThunk('recover/verify', async (_, { dispatch }) => 'done');

// create slice for auth
const authSlice = createSlice({
  name: 'recover',
  initialState,
  reducers: {},
  extraReducers: {
    [forgotPassword.pending]: (state, action) => {
      return { ...initialState, state: 'pending', detail: action.meta.arg };
    },
    [forgotPassword.fulfilled]: (state, action) => {
      return { ...state, state: 'otp', success: true };
    },
    [forgotPassword.rejected]: (state, action) => {
      return { ...initialState, state: 'error', errors: action.payload };
    },
    [verifyPassword.pending]: (state, action) => {
      return { ...state, state: 'otp-pending' };
    },
    [verifyPassword.fulfilled]: (state, action) => {
      return { ...initialState, state: 'success', success: true, detail: action.payload };
    },
    [verifyPassword.rejected]: (state, action) => {
      return { ...state, state: 'otp-error', errors: action.payload };
    },
    [verify.fulfilled]: (state, action) => {
      return { ...initialState, state: 'otp', success: true };
    },
    [init.fulfilled]: (state) => {
      return { ...initialState };
    },
  },
});

export default authSlice.reducer;

export const selectAuthen = ({ app }) => app.auth.recover;
