import { safeSetValue } from '@gvlab/react-lib/utils';
import { setGlobalState, getGlobalState } from './GlobalState';

function EventHelper() {
  const getCustomEvent = () => {
    return getGlobalState('onEvent');
  };

  const addCustomEvent = (event) => {
    if (!event || !event.name || !event.method) return false;

    const onEvent = getGlobalState('onEvent');

    if (onEvent.find((item) => item.name === event.name) !== undefined) return false;

    setGlobalState('onEvent', (prevState) => {
      return safeSetValue(prevState, event);
    });
    return true;
  };

  const callCustomEvent = (eventName) => {
    const onEvent = getGlobalState('onEvent');

    if (!onEvent || onEvent.length === 0) return false;
    const eventFunc = onEvent.filter((event) => event.name === eventName);

    if (typeof eventFunc.func === 'function') return false;

    return eventFunc.func();
  };

  return {
    addCustomEvent,
    callCustomEvent,
    getCustomEvent,
  };
}

export default EventHelper;
