import PropTypes from 'prop-types';


const Menu = (props) => {
  const { children, ...rest } = props;

  return <nav className="h-full space-y-8 text-sm p-2" {...rest}>{children}</nav>;
};

Menu.propTypes = {
  children: PropTypes.node,
};

Menu.defaultProps = {
  variant: 'contained',
  shape: 'square',
};

export default Menu;
