const getDomain = () => {
  let baseDomain = 'https://beta.io.houzmoni.com';

  switch (process.env.NODE_ENV) {
    case 'production':
      baseDomain = 'https://io.houzmoni.com/';
      break;
    case 'staging':
      baseDomain = 'http://gateway.staging.scrumhr.com/';
      break;
    default:
      baseDomain = 'https://io.houzmoni.com/';
      break;
  }
};

const baseDomain = 'https://io.houzmoni.com';
const clientType = 'pro';
const serviceId = '13ae735d-6ef2-4a31-bca9-25a7496e772e';

export { baseDomain, clientType, serviceId };
