/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import { numberUtils } from '@gvlab/react-lib/utils';

import { clearDetail, selectWalletState, selectWallet } from 'store/Dashboard/walletSlice';
import { selectUser } from 'store/Dashboard/userSlice';
import { selectWalletBalance } from 'store/Dashboard/walletBalanceSlice';
import { FormField } from 'components/Common/FormField';
import { FormBase } from 'components/Common/FormBase';

import { ActionBar } from './ActionsBar';

const Withdraw = () => {
  const dispatch = useDispatch();
  const walletState = useSelector(selectWalletState);
  const walletBalance = useSelector(selectWalletBalance);
  const wallet = useSelector(selectWallet);
  const user = useSelector(selectUser);

  if (!walletState || !user) return null;

  if (walletState.state === 'INIT') return null;

  const values = {
    amount: 0,
    bank_name: '',
    bank_account: '',
  };

  return (
    <Drawer fullSize={false} onClose={() => dispatch(clearDetail())} open title="Withdraw Funds">
      <FormBase values={values}>
        <div className="flex flex-col w-full h-full items-center	content-center	justify-center	">
          <div className="flex flex-col max-w-md p-6 space-y-4 divide-y sm:w-96 sm:p-10 divide-gray-300 bg-gray-50 text-gray-800">
            <ul className="flex flex-col pt-4 space-y-2">
              <li className="flex items-start justify-between">
                <h2 className="text-xl font-semibold uppercase">Account detail</h2>
              </li>
              <li className="flex items-start justify-between">
                <span>Name</span>
                <h3 className="text-lg ">{user.detail.fullName}</h3>
              </li>
              <li className="flex items-start justify-between">
                <span>Id</span>
                <span className="uppercase">{user.detail.peoplesId}</span>
              </li>
            </ul>
            <div className="pt-4 space-y-2">
              <div>
                <div className="flex justify-between bold">
                  <span>Credit Balance</span>
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <span>Balance</span>
                  <span>{numberUtils.currencyFormat(walletBalance.balance, 0, ' ')}</span>
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <span>Available</span>
                  <span>{numberUtils.currencyFormat(walletBalance.available, 0, ' ')}</span>
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <span>Last Update at</span>
                  <span>{moment(walletBalance.updateAt).format('DD MMM YYYY')}</span>
                </div>
              </div>
              <hr className="border-gray-300" />
              <div className="flex flex-col">
                <span>Withdraw amount</span>
                <span>
                  <FormField
                    id="amount"
                    type="number"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={walletState.errors}
                    min="1000"
                    step="100"
                    valueAsNumber="true"
                  />
                </span>
              </div>
            </div>
            <div className="pt-4 space-y-2">
              <div className="flex justify-between">
                <span>Recipient Bank Information</span>
              </div>
              <div className="flex flex-col">
                <span>Bank name</span>
                <span>
                  <FormField
                    id="bank_name"
                    type="text"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={walletState.errors}
                  />
                </span>
              </div>
              <div className="flex flex-col">
                <span>Bank Account</span>
                <span>
                  <FormField
                    id="bank_account"
                    type="text"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={walletState.errors}
                  />
                </span>
              </div>
            </div>
            <ActionBar />
            {walletState.errors && walletState.errors?.message && (
              <div className="alert alert-error">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>{walletState.errors.message}</span>
              </div>
            )}
          </div>
        </div>
      </FormBase>
    </Drawer>
  );
};

export default Withdraw;
