/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { init as initTokens } from '@gvlab/react-session';
import { init as initUser } from './authUserSlice';
import { init as initMenus } from './authMenusSlice';

export const Logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    dispatch(initTokens());
    dispatch(initUser())
    dispatch(initMenus());
  },
);

// create slice auth
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    state: 'idle',
    error: null,
  },
  reducers: {
  },
  extraReducers: {
    [Logout.pending]: (state) => {
      return { ...state, state: 'pending' };
    },
    [Logout.fulfilled]: (state) => {
      return { ...state, state: 'fulfilled' };
    },
    [Logout.rejected]: (state, action) => {
      return { ...state, state: 'rejected', error: action.payload };
    },

  },
});

export default authSlice.reducer;
