/* eslint-disable camelcase */
// wallet slice is the state of the wallet component
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// viewDetail action to view task detail from API and update state in store
export const viewDetail = createAsyncThunk(
  'walletDetail/viewDetail',
  async ({ walletsId }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .get(`/user/v1/wallets/${walletsId}?userType=pro`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log('viewDetail error', error);
          // set task detail state to error
          return rejectWithValue(error);
        });
    } catch (error) {
      console.log('viewDetail error', error);
      // set task detail state to error
      return rejectWithValue(error);
    }
  }
);

// clearDetail action to clear task detail from store
export const clearDetail = createAsyncThunk('walletDetail/clearDetail', (payload) => true);

// update wallet detail slice initial state
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// create wallet detail slice reducer and actions
const walletDetailSlice = createSlice({
  name: 'walletDetail',
  initialState,
  reducers: {},
  extraReducers: {
    [clearDetail.fulfilled]: (state, action) => {
      state.detail = {};
      state.state = 'INIT';
      state.success = false;
      state.errors = [];
    },

    [viewDetail.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    [viewDetail.fulfilled]: (state, action) => {
      state.errors = [];
      state.success = true;
      state.state = 'DONE';
      state.detail = action.payload;
    },
    [viewDetail.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
    },
  },
});

export const selectWalletDetailState = ({ app }) => app.dashboard.walletDetail;
export const selectWalletDetail = ({ app }) => app.dashboard.walletDetail.detail;

export default walletDetailSlice.reducer;
