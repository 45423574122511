import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@gvlab/react-theme/styles';
import { Grid } from '@gvlab/react-theme/mui';

/**
 * Grid Container encapsulation
 */
const GridContainer = (props) => {
  const useStyles = makeStyles({
    grid: {
      marginRight: '-15px',
      marginLeft: '-15px',
      width: 'auto',
    },
  });

  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={className || classes.grid}>
      {children}
    </Grid>
  );
};

GridContainer.defaultProps = {
  className: '',
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default GridContainer;
