/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import { Avatar, Divider, BottomNavigation, BottomNavigationAction } from '@gvlab/react-theme/mui';
import { useNavigate } from '@gvlab/react-router';
import {
  Person as PersonIcon,
  Input as InputIcon,
  Settings as SettingsIcon,
} from '@gvlab/react-icons';
import CustomDrawer from '@gvlab/react-ui/components/CustomDrawer';
import { useGlobalContext } from '@gvlab/react-lib/Global';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2, 0),
    // backgroundColor: theme.palette.paper.main,
    height: '100%',
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    padding: '12px',
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h1': {
      color: 'black',
    },
  },
  details: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  avatar: {
    border: `2px solid ${theme.palette.white}`,
    height: 120,
    width: 120,
  },
  logo: {
    color: 'black',
    filter: 'invert(1)',
    height: 32,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    padding: theme.spacing(2),
    '& overline': {
      color: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: 256,
    borderRadius: 0,
    backgroundColor: theme.palette.paper.main,
  },
  toolButton: {
    textAlign: 'center',
    height: '60px',
  },
}));

const ProfileBar = () => {
  const { authCtx, openProfile, onProfileBarClose } = useGlobalContext();
  const classes = useStyles();
  const navigate = useNavigate();
  const userInfo = authCtx.getAccountInfo();

  return (
    <CustomDrawer
      actions={() => ''}
      fullSize={false}
      onClose={onProfileBarClose}
      open={openProfile}
      title="Profile"
    >
      <div className={classes.content}>
        <div className={classes.profile}>
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={userInfo?.attributes?.avatar ?? '/images/avatars/default.png'}
          />
          <div className={classes.details}>
            <Typography component="h3" gutterBottom>
              {userInfo?.attributes?.name ?? userInfo?.name}
            </Typography>
            <Typography component="h2" gutterBottom variant="overline">
              Tech Lead
            </Typography>
            {userInfo && (
              <Typography className={classes.name} variant="h4">
                {userInfo?.attributes?.email}
              </Typography>
            )}
          </div>
        </div>
        <Divider className={classes.divider} />
        <BottomNavigation showLabels className={classes.toolButton}>
          <BottomNavigationAction
            label="Profile"
            icon={
              <PersonIcon
                onClick={() => {
                  navigate('/profile', { replace: true });
                }}
              />
            }
          />
          {userInfo?.role === 'superAdmin' && (
            <BottomNavigationAction
              label="Dashboard"
              icon={
                <SettingsIcon
                  onClick={() => {
                    navigate('/dashboard/overview', { replace: true });
                  }}
                />
              }
            />
          )}
          <BottomNavigationAction
            label="Exit"
            icon={<InputIcon />}
            onClick={() => {
              authCtx.logout();
            }}
          />
        </BottomNavigation>
      </div>
    </CustomDrawer>
  );
};

ProfileBar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ProfileBar;
