/* eslint-disable import/no-extraneous-dependencies */
// import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@gvlab/react-theme/mui';
// import useTimeout from '@gvlab/react-lib/hooks/useTimeout';

const Loading = (props) => {
  // const [showLoading, setShowLoading] = useState(!props.delay);

  // useTimeout(() => {
  //   setShowLoading(true);
  // }, props.delay);

  // if (!showLoading) {
  //   return null;
  // }

  return (
    <div className="flex flex-1 flex-col items-center justify-center p-12">
      <LinearProgress className="w-xs max-w-full" color="secondary" />
    </div>
  );
};

Loading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

Loading.defaultProps = {
  delay: false,
};

export default Loading;
