import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@gvlab/react-theme/mui';

const CustomDialog = (props) => {
  const {
    button1Caption,
    button2Caption,
    dividers,
    fullScreen,
    open,
    onButton1Click,
    onButton2Click,
    onClose,
    message,
    title,
  } = props;

  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      onClose={() => {
        onClose && onClose();
      }}
      open={open}
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={dividers}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={() => {
            onButton1Click && onButton1Click();
          }}
        >
          {button1Caption}
        </Button>
        <Button
          autoFocus
          color="primary"
          onClick={() => {
            onButton2Click && onButton2Click();
          }}
        >
          {button2Caption}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  button1Caption: 'OK',
  button2Caption: 'Cancel',
  dividers: false,
  fullScreen: false,
  message: 'Message Here',
  open: false,
  title: 'title here',
};

CustomDialog.propTypes = {
  button1Caption: PropTypes.string,
  button2Caption: PropTypes.string,
  dividers: PropTypes.bool,
  fullScreen: PropTypes.bool,
  message: PropTypes.string,
  open: PropTypes.bool,
  onButton1Click: PropTypes.func,
  onButton2Click: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
};
export default CustomDialog;
