/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';
import typography from '../typography';

const StyledComponent = {
  styleOverrides: {
    root: {
      ...typography.body3,
      borderBottom: `1px solid ${palette.divider}`,
    },
    head: {
      color: palette.secondary.contrastText,
    },
    body: {
      color: palette.secondary.contrastText,
    },
  },
};

export default StyledComponent;
