/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

const StyledComponent = {
  styleOverrides: {
    root: {
      color: palette.icon,
      '&:hover': {
        backgroundColor: palette.colors.grey[100],
      },
      '&$selected': {
        backgroundColor: palette.colors.grey[50],
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.colors.grey[100],
        },
      },
      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
  },
};

export default StyledComponent;
