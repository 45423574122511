/* eslint-disable import/no-extraneous-dependencies */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';

const CardContentBase = styled('div')(({ theme }) => ({
}));

const CardContent = (props) => {
  const { children, className } = props;

  return (
    <CardContentBase className={clsx('card-body', className)}>
      {children}
    </CardContentBase>
  );
};

CardContent.propTypes = {
  className: PropTypes.string,
};

export default CardContent;
