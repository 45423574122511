/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';
import { isFunction } from '@gvlab/react-lib/utils';

import { ActionPanel } from '../components';

const FormActions = () => {
  const { actions } = useSelector(({ app }) => ({
    actions: app.form.props.options?.actions,
  }));

  const RenderFormActions = isFunction(actions) ? actions : ActionPanel;

  return <RenderFormActions buttonAddCaption="Add" buttonDeleteCaption="" buttonOKCaption="Save" />;
};

export default FormActions;
