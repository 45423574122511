import { createSlice } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// get summary data from api and set to store
export const getSummary = () => async (dispatch) => {
  return Requestly()
    .request({
      method: 'get',
      url: `/admin/v1/services/summary`,
    })
    .then((response) => {
      return dispatch(setSuccess(response));
    })
    .catch((errors) => {
      return dispatch(setError(errors));
    });
};

// get detail data from api and set to store
export const getDetail =
  ({ serviceId }) =>
  async (dispatch) => {
    return Requestly()
      .request({
        method: 'get',
        url: `/admin/v1/services/${serviceId}/detail`,
      })
      .then((response) => {
        return dispatch(setSuccess(response));
      })
      .catch((errors) => {
        return dispatch(setError(errors));
      });
  };

// initial state for dashboard slice
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// dashboard slice to handle dashboard state and actions
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // set detail data to store
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    // set state to pending
    setPending: (state, action) => {
      state.state = 'PENDING';
    },
    // set state to done and set success to true
    setSuccess: (state, action) => {
      state.state = 'DONE';
      state.success = true;
      state.errors = [];
      state.detail = action.payload;
    },
    // set state to error and set success to false
    setError: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

// export actions and selector
export const { setPending, setDetail, setSuccess, setError } = dashboardSlice.actions;
export const selectDashboard = ({ app }) => app.dashboard.dashboard;

export default dashboardSlice.reducer;
