import { Redirect } from 'react-router-dom';

import authRoutes from 'Routes/Auth';
import dashboardRoutes from 'Routes/Dashboard';
import PublicRoutes from 'Routes/Public';

import { ErrorRoutes } from '@gvlab/react-layout';

const routesObjects = [
  ...PublicRoutes,
  ...authRoutes,
  ...dashboardRoutes,
  ErrorRoutes,
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/quotation" />,
  },
  {
    path: '*',
    component: () => <Redirect to="/errors/error-404" />,
  },
];

export default routesObjects;
