/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Input from '@gvlab/react-ui/components/ChipSelect';
import { useUtilsContext } from '@gvlab/react-lib/utils/UtilsProvider';

const SelectInput = (props) => {
  const { mode, errors, onChange, onCheckDisabled, onFormItemGetValue } = useSelector(
    ({ app }) => ({
      mode: app.form.props.state,
      errors: app.form.props.errors,
      onChange: app.form.props.options.onChange,
      onCheckDisabled: app.form.props.options.onCheckDisabled,
      onFormItemGetValue: app.form.props.options.onFormItemGetValue,
    }),
  );

  const { field } = props;
  const [controlValues, setValues] = useState([]);
  const { debug } = useUtilsContext();

  useEffect(() => {
    setValues((prev) => {
      const dataValue = onFormItemGetValue(props);
      if (!dataValue) return prev;
      return dataValue.split(',').map((ele) => {
        return {
          label: ele,
          value: ele,
        };
      });
    });
  }, [props]);

  const handleCheckDisabled = () => {
    return onCheckDisabled({ dataSource: field.dataSource, field });
  };

  return (
    <Input
      isMulti
      disabled={handleCheckDisabled()}
      onChange={({ ...rest }) => {
        if (field?.debug) debug(rest);
        setValues(() => rest.value);
        const statusValue = rest.map((val) => {
          return val.value;
        });
        if (onChange) onChange(field, statusValue.toString(), field.dataSource);
      }}
      options={field?.options}
      textFieldProps={{
        label: field?.title,
        InputLabelProps: {
          shrink: true,
        },
        variant: 'outlined',
      }}
      value={controlValues}
    />
  );
};

SelectInput.propTypes = {
  children: PropTypes.node,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default React.memo(SelectInput);
