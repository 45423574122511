/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectGvUser } from './store';

import useAuth from './useAuth';
import { useDialogContext } from '../Dialog';

// auth guard component for protected routes (check user role)
const AuthGuard = ({ roles = [], children }) => {
  const { dialogCritical } = useDialogContext();
  const user = useSelector(selectGvUser);
  const authCtx = useAuth();

  // set user roles
  const setRoles = () => {
    // check user role
    const role = user?.role || user?.userType;
    if (!role || !roles.includes(role)) {
      // redirect to login page
      authCtx.redirect('/auth/login');
      return true;
    }

    return false;
  };

  // check session expire
  useEffect(() => {
    let mounted = true;
    const checkSessionExpire = async () => {
      try {
        if (mounted) {
          // restore token from cookie or local storage
          await authCtx.getToken();

          // get user details from server if user is logged in
          await authCtx.getServiceDetails();

          // check user role
          setRoles();
        }
      } catch (err) {
        // show dialog critical and redirect to login page
        dialogCritical({ onClose: () => authCtx.redirect('/auth/login') });
      }
    };

    checkSessionExpire();

    // clean up
    return () => {
      mounted = false;
    };
  }, []);

  // render children if user is logged in 
  if (user?.id) return children;

  // return null if user is not logged in
  return null;
};

export default AuthGuard;
