import palette from '../palette';
import typography from '../typography';

const StyledComponent = {
  styleOverrides: {
    root: {
      ...typography.body3,
      '&$active': {
        color: palette.secondary.contrastText,
      },
    },
    head: {
      color: palette.secondary.contrastText,
    },
  },
};

export default StyledComponent;
