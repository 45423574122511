// extract payload
export const payload = (_token) => _token.split('.');

// convert the base64url string to a base64 string
export const base64 = (base64Url) => {
  // determine the padding characters required for the base64 string
  const padding = "=".repeat((4 - (base64Url.length % 4)) % 4);

  // replace the url safe characters with the standard base64 characters
  return base64Url.replace(/-/g, '+').replace(/_/g, '/') + padding;
}

// Decodes a base64 encoded string
const decodes = (_base64) => window.atob(_base64)

// Decode URI component (utf-8)
export const decodeURI = (_hexString) => decodeURIComponent(_hexString);

// Convert hex string to string (utf-8)
export const hexString = (_encoded) => {
  return _encoded
    .split('')
    .map((c) => {
      const hex = c.charCodeAt(0).toString(16);
      const hexChar = `00${hex}`;
      const hexCharTrim = hexChar.slice(-2);
      return `%${hexCharTrim}`;
    })
    .join('');
};

// Decode token and return the payload data (json)
export const decodeToken = (_token) => {
  try {
    // if the token is not a string
    // then is not a valid token and return null
    if (typeof _token !== "string") {
      return null;
    }

    const _payload = payload(_token);
    // if the token has more or less than 3 parts or is not a string
    // then is not a valid token and return null
    if (_payload.length !== 3) {
      return null;
    }

    // content, payload ( index 1 ) has the data stored and
    // data about the expiration time
    const content = _payload[1];

    // convert the base64url string to a base64 string
    const base64String = decodes(content);

    // decode base64
    // const decodeString = decodes(base64String);

    // parse base64 into json
    // const jsonPayload = decodeURIComponent(
    //   decodes(base64String)
    //       .split('')
    //       .map(c => `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`)
    //       .join('')
    // );

    // decode json
    const decoded = JSON.parse(base64String);

    return decoded;
  } catch (error) {
    // Return null if something goes wrong
    return null;
  }
};

// Check if the token is expired
export const isTokenExpired = (jwtToken) => {
  try {
    // if the token is not a string
    if (jwtToken === null || jwtToken === '') return true;
    // decode the token
    const decodedToken = decodeToken(jwtToken);
    // if the token is not valid
    if (!decodedToken || !decodedToken?.exp) return true;
    // get the expiration time
    const expirationDate = new Date(0);
    // set the expiration time to the decoded token expiration time in seconds
    expirationDate.setUTCSeconds(decodedToken.exp);

    // compare the expiration time and the current time
    return new Date().valueOf() > expirationDate.valueOf();
  } catch (e) {
    console.error(e)
    return true;
  }
};
