import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar } from '@gvlab/react-theme/mui';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import { isFunction } from '@gvlab/react-lib/utils';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const CardHeader = (props) => {
  const { action, avatar, className, position, title } = props;

  const classes = useStyles();

  const ActionsComponent = () => {
    if (!action) return null;
    if (isFunction(action)) return action();
    return <>{action}</>;
  };

  const AvatarComponent = () => {
    if (!avatar) return null;
    if (isFunction(avatar)) return avatar();
    return <>{avatar}</>;
  };

  return (
    <AppBar color="primary" position={position} className={clsx('', className)}>
      <div className="py-0 px-1 flex relative items-center min-h-[64px] justify-between">
        <div className="py-1"><AvatarComponent /></div>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        <div className='flex'>
          {props.children}
          <ActionsComponent />
        </div>
      </div>
    </AppBar>
  );
};

CardHeader.defaultProps = {
  position: 'static',
};

CardHeader.propTypes = {
  position: PropTypes.string,
};

export default CardHeader;
