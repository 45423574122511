/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import { GvRoutes as Routes } from '@gvlab/react-router';
import { GlobalProvider } from '@gvlab/react-lib/Global';

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));
const StyledCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
}));
const StyledContent = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  flex: '1 1 auto',
  backgroundColor: theme.palette.background.default,
}));

const Dashboard = (props) => {
  const { route, ...rest } = props;

  const value = {};

  return (
    <GlobalProvider value={value}>
      <StyledContainer>
        <StyledCard>
          <StyledContent>
            <Routes paths={route.routes} extra={{ ...rest }} />
          </StyledContent>
        </StyledCard>
      </StyledContainer>
    </GlobalProvider>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
