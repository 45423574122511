/* eslint-disable import/prefer-default-export */
import { useFormContext } from 'react-hook-form';
import { TextField } from '@gvlab/react-theme/mui';

export const FormField = ({ id, value, errors, formState, type, readOnly, ...rest }) => {
  const { register } = useFormContext();

  const hasError = () => {
    if (errors?.path === id) {
      return errors.message;
    }
    return '';
  };

  const errorMessage = hasError();

  if (type === 'number') {
    return (
      <TextField
        size="small"
        className="w-full"
        variant="standard"
        required
        type="number"
        readOnly={readOnly}
        {...rest}
        {...register(id, rest)}
        error={!!errorMessage}
        helperText={errorMessage}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
    );
  }
  return (
    <TextField
      size="small"
      className="w-full"
      variant="standard"
      required
      type={type || 'text'}
      readOnly={readOnly}
      {...rest}
      {...register(id, rest)}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};
