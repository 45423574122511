/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';

import { selectQuotationState, clearDetail } from 'store/Dashboard/quotationDraftSlice';
import { defaultValue } from 'components/Quotations/Model';

import Form from './Form/Form';

const QuotationDraft = (props) => {
  const dispatch = useDispatch();
  const { detail: quotation, ...quoteState } = useSelector(selectQuotationState);

  if (quoteState.state !== 'DRAFT') return false;

  return (
    <Drawer fullSize open onClose={() => dispatch(clearDetail())} title="Quotation Draft">
      <Form defaults={defaultValue} values={quotation || defaultValue} />
    </Drawer>
  );
};

export default QuotationDraft;
