/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import Cookies from "js-cookie";
import { safeSetValue } from "@gvlab/react-lib/utils";

// hook to use cookie state
const useCookieState = (key, options = {}) => {
  const [value, setValue] = React.useState(Cookies.get(key));

  // set cookie with value and options
  const setCookie = (_value, _options) => {
    Cookies.set(key, safeSetValue(value, _value), _options);
    setValue(safeSetValue(value, _value));
  };

  // remove cookie with key
  const removeCookie = () => { Cookies.remove(key); };


  return [value, setCookie];
}

export default useCookieState;