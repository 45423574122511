/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import { numberUtils } from '@gvlab/react-lib/utils';

import {
  clearDetail,
  selectPaymentDetail,
  selectPaymentDetailState,
} from 'store/Dashboard/paymentDetailSlice';
import { show } from 'store/lightBoxSlice';

const PaymentDetail = (props) => {
  const dispatch = useDispatch();
  const paymentDetailState = useSelector(selectPaymentDetailState);
  const paymentDetail = useSelector(selectPaymentDetail);

  if (!paymentDetailState) return null;

  if (paymentDetailState.state !== 'DONE') return null;

  const extras = {
    bank: paymentDetail.params?.bank || '',
    location: paymentDetail.params.location || '',
    reference: paymentDetail.params.reference || '',
    date: paymentDetail.params.date || '',
    time: paymentDetail.params.tim || '',
    amount: paymentDetail.params.amount || 0,
    account: paymentDetail.params.account || '',
    receipt: paymentDetail.params.receipt || '',
  };

  if (paymentDetail.params) {
    paymentDetail.params.forEach((param) => {
      extras[param.namespace] = param.value;
    });
  }

  return (
    <Drawer fullSize={false} onClose={() => dispatch(clearDetail())} open title="Reload Credit">
      <div className="flex flex-col w-full h-full items-center	content-center	justify-center	">
        <div className="flex flex-col max-w-md p-6 space-y-4 divide-y sm:w-96 sm:p-10 divide-gray-300 bg-gray-50 text-gray-800">
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <h2 className="text-xl font-semibold uppercase">Account detail</h2>
            </li>
            <li className="flex items-start justify-between">
              <span>Name</span>
              <h3 className="text-lg ">{paymentDetail.profile.fullName}</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Id</span>
              <span className="uppercase">{paymentDetail.profile.id}</span>
            </li>
          </ul>
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <h3 className="text-l font-semibold uppercase">Bill detail</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Id</span>
              <span className="uppercase font-semibold">{paymentDetail.id}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Status</span>
              <span className="uppercase">{paymentDetail.status}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Amount</span>
              <span>{numberUtils.currencyFormat(paymentDetail.totalAmount, 0, ' ')}</span>
            </li>
          </ul>
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <h3 className="text-l font-semibold uppercase">Reference</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Bank</span>
              <span>{extras?.bank}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Location</span>
              <span>{extras?.location}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Reference</span>
              <span>{extras?.reference}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Date / Time</span>
              <span>
                {extras.date} {extras.time}
              </span>
            </li>
            {paymentDetail.receiptUrl && (
              <li className="flex items-start justify-between">
                <span>Receipt</span>
                <span
                  className="uppercase font-semibold link"
                  onClick={(event) => dispatch(show(paymentDetail.receiptUrl))}
                >
                  {extras.receipt}
                </span>
              </li>
            )}
          </ul>
          {extras?.reason && (
            <div className="alert alert-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <div>
                <h3 className="font-bold uppercase">{extras?.reason}</h3>
                <span>{extras?.description}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default PaymentDetail;
