import { useDispatch } from 'react-redux';
import { closeDialog, dialogMessage, dialogCritical } from './store/dialogSlice';
import {
  closeSnackbar,
  snackbarError,
  snackbarInfo,
  snackbarSuccess,
  snackbarWarning,
} from './store/snackbarSlice';

import DialogMessage from './DialogMessage';
import DialogCritical from './DialogCritical';
import Snackbar from './Snackbar';

import { CookiesNotification } from '../CookiesNotification';

export const useDialogContext = () => {
  const dispatch = useDispatch();
  return {
    clear: () => {
      dispatch(closeDialog());
      dispatch(closeSnackbar());
    },
    closeDialog: (patch) => dispatch(closeDialog(patch)),
    dialogMessage: (patch) => dispatch(dialogMessage(patch)),
    dialogCritical: (patch) => dispatch(dialogCritical(patch)),

    closeSnackbar: (patch) => dispatch(closeSnackbar(patch)),
    snackbarError: (patch) => dispatch(snackbarError(patch)),
    snackbarInfo: (patch) => dispatch(snackbarInfo(patch)),
    snackbarSuccess: (patch) => dispatch(snackbarSuccess(patch)),
    snackbarWarning: (patch) => dispatch(snackbarWarning(patch)),
  };
};

export const DialogProvider = () => {
  return (
    <>
      <DialogCritical />
      <DialogMessage />
      <Snackbar />
      <CookiesNotification />
    </>
  );
};
