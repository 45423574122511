/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as validator from '@gvlab/react-lib/utils/validator';
import { isPromise, resolve, reject } from '@gvlab/react-lib/utils/promises';
import { isFunction } from '@gvlab/react-lib/utils';
import { useUtilsContext } from '@gvlab/react-lib/utils/UtilsProvider';
import { useMount, useLatest, useSetState } from '@gvlab/react-lib/hooks';
import {
  useReduxDataset as useDataset,
  useModel,
} from '../../hooks';

import DataFields from './DataFields';

// Base Model for all models
const BaseModel = ({
  name: tableName,
  attributes,
  afterCreate,
  afterFetch,
  afterUpdate,
  afterDelete,
  beforeCreate,
  beforeFetch,
  beforeUpdate,
  beforeList,
  beforeDelete,
  formatParams,
  ...rest
}) => {
  const [fields, setFields] = useSetState({});
  const [errors, setErrors] = useSetState([]);
  const { uniqueKey } = useUtilsContext();
  const apiClient = useModel();
  const latestError = useLatest(errors);
  const latestFields = useLatest(fields);
  const dataset = useDataset(tableName);

  function loadFields() {
    attributes.forEach((ref) => {
      setFields({ [ref.key]: DataFields({ tableName, ref, uniqKey: uniqueKey() }) });
    });
  }

  useMount(() => {
    loadFields();
    // create the data storage
    dataset.initDataStore();
  }, []);

  // useWhyDidYouUpdate('BaseModel', latestError)

  // preEvent was a mock function
  function preEvent({ method, url, parent, request, event }) {
    if (isFunction(event)) {
      return event({ method, url, parent, request });
    }

    return handleFormatParams(method, url, request);
  }

  // onBeforeCreate was a mock function
  function onBeforeCreate({ method = 'post', url = '', parent, request }) {
    return preEvent({ method, url, parent, request, event: beforeCreate });
  }

  // onBeforeDelete was a mock function
  function onBeforeDelete({ method = 'delete', url =  '', parent, request }) {
    return preEvent({ method, url, parent, request, event: beforeDelete });
  }

  // onBeforeFetch was a mock function
  function onBeforeFetch({ method = 'get', url = '', parent, request }) {
    return preEvent({ method, url, parent, request, event: beforeFetch });
  }

  // onBeforeList was a mock function
  function onBeforeList({ method = 'get', url = '', parent, request }) {
    return preEvent({ method, url, parent, request, event: beforeList });
  }

  // onBeforeUpdate was a mock function
  function onBeforeUpdate({ method = 'put', url = '', parent, request }) {
    return preEvent({ method, url, parent, request, event: beforeUpdate });
  }
  
  // postEvent was a mock function
  function postEvent({ method, url, parent, request, response, event }) {
    if (isFunction(event)) {
      return event({ method, url, parent, request, response });
    }

    return response;
  }
  
  function onAfterUpdate({ parent, response, request }) {
    return postEvent({ method: 'update', parent, request, response, event: afterUpdate });
  }

  function onAfterCreate({ parent, response, request }) {
    return postEvent({ method: 'create', parent, request, response, event: afterCreate });
  }

  function onAfterFetch({ parent, response, request }) {
    return postEvent({ method: 'fetch', parent, request, response, event: afterFetch });
  }

  function onAfterDelete({ parent, response, request }) {
    return postEvent({ method: 'delete', parent, request, response, event: afterDelete });
  }

  function isFieldExist(fieldName) {
    if (!latestFields || !latestFields.current) {
      console.error(`BaseModel expected fields empty`);
      return false;
    }
    if (latestFields.current?.[fieldName]) {
      return true;
    }

    const systemDefine = ['page', 'limit'];
    if (systemDefine.includes(fieldName) === true) {
      return true;
    }

    return false;
  }

  function isFieldReadOnly(fieldName) {
    if (!isFieldExist(fieldName)) {
      return false;
    }
    if (latestFields.current[fieldName]?.isReadOnly()) {
      return true;
    }

    return false;
  }

  function handleFormatParams(method, url, request) {
    if (isFunction(formatParams)) {
      return formatParams({ method, url, request });
    }

    const formattedParams = {};

    if (request) {
      Object.keys(request).forEach((key) => {
        if (isFieldExist(key) && !isFieldReadOnly(key)) {
          formattedParams[key] = request[key];
        }
      });
    }

    return {
      method,
      url,
      params: formattedParams,
    };
  }

  // construct data for insert, update, delete
  function constructData(res) {
    const currentData = res?.data || res?.attributes || res;
    currentData.id = res?.Id || res?.id || currentData.id || false;
    return {
      ...currentData,
    };
  }

  function onInsertCheck(res) {
    return constructData(res);
  }

  function onUpdateCheck(prev, res) {
    const currentData = constructData(res);
    return prev.id === currentData.id ? currentData : prev;
  }

  function onDeleteCheck(prev, res) {
    const currentData = constructData(res);
    return prev.id !== currentData.id;
  }

  // fetch record
  const fetchRecord = async ({ parent, client, request, method: methodRequest, url: urlRequest }) => {
    try {
      const { method, params, url } = onBeforeFetch({ parent: instance, request, method: methodRequest, url: urlRequest });

      if (!url) return reject(new Error('Fail to get url'));

      const page = params?.page || 1;
      const size = params?.limit || 10;
      const requestMethod = method || 'get';

      let paramStr = `page=${page}&limit=${size}`;
      if (params) {
        Object.entries(params).forEach((value, key) => {
          if (key !== 'page' && key !== 'limit') {
            paramStr += `&${key}=${value}`;
          }
        });
      }

      // if page 1 reset storege 
      if (page === 1) {
        dataset.initDataStore();
      }

      if (url.indexOf('?') === -1) {
        paramStr = `?${paramStr}`;
      } else {
        paramStr = `&${paramStr}`;
      }
      const requestURL = `${url}${paramStr}`;
      return dataset
        .read({ method: requestMethod, url: requestURL })
        .then((response) => {
          if (response?.error && response?.payload?.error?.status >= 400) {
            throw response.payload.error
          }
          return onAfterFetch({ parent: instance, response });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return reject(error);
    }
  };

  const open = useCallback(() => {
    return fetchRecord({ parent: instance, client: apiClient });
  }, []);

  const filter = useCallback((filters) => {
    return fetchRecord({ parent: instance, client: apiClient, request: filters });
  }, []);

  const create = useCallback((request) => {
    const { method, params, url } = onBeforeCreate({ parent: instance, request });

    if (!url) return reject(new Error('Fail to get url'));
    if (!params) return reject(new Error('Fail to get params'));

    return dataset
      .create({ method, url, params, cbCheck: onInsertCheck})
      .then((response) => {
        return onAfterCreate({
          parent: instance,
          response,
          request: {
            params,
            url,
          },
        });
      })
      .catch((error) => {
        return reject(error);
      });
  }, []);

  const update = useCallback((request) => {
    const { method, params, url } = onBeforeUpdate({ parent: instance, request });

    if (!url) return reject(new Error('Fail to get url'));

    return dataset
      .update({ method, url, params})
      .then((response) => {
        return onAfterUpdate({
          parent: instance,
          response,
          request: {
            params,
            url,
          },
        });
      })
      .then((response) => {
        return onUpdateCheck(response);
      })
      .catch((error) => {
        return reject(error);
      });
  }, []);

  const remove = useCallback((request) => {
    const { method, params, url } = onBeforeDelete({ parent: instance, request });

    if (!url) return reject(new Error('Fail to get url'));

    return dataset
      .remove({ method, url, params, cbCheck: onDeleteCheck})
      .then((response) => {
        // case insensitive
        const oldValue = params;
        oldValue.Id = oldValue?.Id ?? oldValue?.id;
        return oldValue;
      })
      .catch((error) => {
        return reject(error);
      });
  }, []);

  const initError = useCallback(() => {
    setErrors([]);
  }, []);

  const isError = useCallback(() => {
    return errors.length > 0;
  }, [errors]);

  const addError = useCallback((error) => {
    setErrors((state) => [...state, error]);
  }, []);

  const instance = {
    addError,
    afterCreate: onAfterCreate,
    afterDelete: onAfterDelete,
    afterFetch: onAfterFetch,
    afterUpdate: onAfterUpdate,
    apiClient,

    beforeCreate: onBeforeCreate,
    beforeDelete: onBeforeDelete,
    beforeFetch: onBeforeFetch,
    beforeList: onBeforeList,
    beforeUpdate: onBeforeUpdate,

    create,
    datasets: dataset.dataStore,
    errors: { ...latestError },

    fetchRecord,
    fields: latestFields.current,
    filter,
    formatParams: handleFormatParams,

    getDataset: () => dataset,
    getRecordCount: () => dataset?.dataStore?.meta?.total || 0,

    inArray: () => dataset?.dataStore?.data || [],
    initError,
    isError,
    isPromise,

    open,
    reject,
    remove,
    resolve,
    setDataStore: dataset.setDataStore,
    update,
    validator,

    ...rest,
  };

  return {
    ...instance,
  };
};

BaseModel.propTypes = {
  getChanges: PropTypes.func,
  getValues: PropTypes.func,
  getValue: PropTypes.func,
  onBeforeCreate: PropTypes.func,
  onBeforeDelete: PropTypes.func,
  onBeforeFilter: PropTypes.func,
  onBeforeUpdate: PropTypes.func,
};

export default BaseModel;
