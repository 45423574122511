/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

const StyledComponent = {
  styleOverrides: {
    root: {
      backgroundColor: palette.secondary.main,
      color: palette.icon,
    },
  },
};

export default StyledComponent;
