/* eslint-disable import/prefer-default-export */
import {
  RoundBoxInfo,
  RoundBoxSuccess,
  RoundBoxError,
  RoundBoxWarning,
  RoundBoxBasic,
} from '@gvlab/react-ui/v2';

const DocumentStatus = ({ status }) => {
  if (status === 'confirm') {
    return <RoundBoxWarning label="pending" className="uppercase px-1" />;
  }
  if (status === 'cancel') {
    return <RoundBoxError label="canceled" className="uppercase px-1" />;
  }
  if (status === 'accept') {
    return <RoundBoxInfo label="accepted" className="uppercase px-1" />;
  }
  if (status === 'reject') {
    return <RoundBoxError label="rejected" className="uppercase px-1" />;
  }
  if (status === 'done') {
    return <RoundBoxSuccess label="done" className="uppercase px-1" />;
  }
  if (status === 'draft') {
    return <RoundBoxSuccess label="draft" className="uppercase px-1" />;
  }

  return <RoundBoxBasic label="draft" className="uppercase px-1" />;
};

export default DocumentStatus;
