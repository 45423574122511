/* eslint-disable import/no-extraneous-dependencies */
import { safeSetValue, isFunction, isObject } from './common';
import * as promises from './promises';
import fetchHelper from './fetchHelper';

// global variable
let token;
let host = '';

// get token
export const getToken = () => token;

// get host
export const getHost = () => host;

// set token and host
export const setToken = (value) => {
  token = value;
};

export const setHost = (value) => {
  host = value;
};

// requestly to handle request to api 
const Requestly = (options = {}) => {
  const apiClient = fetchHelper(options);

  // set loading and last error state
  let loading = true;
  let lastError = null;
  const noResolveJson = options?.noResolveJson || false;

  // default headers for request
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'cross-origin': true,
  };

  // default options for request
  options.method = options?.method || 'GET';
  options.body = options?.body || null;
  options.headers = options.headers || defaultHeaders;
  options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';
  options.headers['cross-origin'] = options.headers['cross-origin'] || true;
  options.headers['Cache-Control'] = options.headers['Cache-Control'] || 'no-cache';
  options.headers.Accept = options.headers.Accept || 'application/json';


  // set loading state
  const setLoading = (patch) => {
    loading = safeSetValue(loading, patch);
  };

  // set last error state
  const setLastError = (patch) => {
    lastError = safeSetValue(lastError, patch);
  };

  // deserialize response
  const deserialize = (res, schema = {}) => {
    if (!res) {
      return promises.raise(new Error(''));
    }

    if (res.error) {
      if (isObject(res.error)) {
        return promises.raise(res);
      }

      return promises.raise({
        error: {
          status: String(res.status || 400),
          title: res.error,
          message: res.error,
        },
      });
    }

    if (res.errors) {
      const error = res.errors.find((e) => {
        return e.status !== '422';
      });
      return promises.reject(
        error
          ? {
              error,
            }
          : {
              errors: res.errors,
            },
      );
    }

    if (!res.data) {
      return promises.resolve(res);
    }

    let { data } = res;
    const { meta } = res;
    const { included } = res;

    if (!Array.isArray(data) && !data?.attributes) {
      return promises.resolve(data);
    }

    if (!Array.isArray(data)) {
      data = [data];
    }

    if (included) {
      data = data.concat(included);
    }

    data.forEach((rec) => {
      if (!rec.relationships) {
        return;
      }

      Object.keys(rec.relationships).forEach((key) => {
        const rel = rec.relationships[key].data;
        if (!rel) return;

        if (Array.isArray(rel)) {
          rec.attributes[key] = rel
            .map((r) => {
              return data.find((d) => {
                return d.type === r.type && d.id === r.id;
              });
            })
            .filter(Boolean)
            .map((r) => {
              const child = r;
              if (child && child.attributes) {
                child.attributes.id = child.id;
              }
              return child.attributes;
            });
        } else {
          const child = data.find((r) => {
            return r.type === rel.type && r.id === rel.id;
          });
          // set attributes of child to parent
          if (child && child.attributes) {
            child.attributes.id = child.id;
          }

          rec.attributes[key] = child ? child.attributes : null;
        }
      });
    });

    if (Array.isArray(res.data)) {
      data = data.reduce((acc, rec) => {
        if (rec.attributes && rec.id) {
          rec.attributes.id = rec.id;
        }
        return res.data.find((r) => {
          return r.id === rec.id && r.type === rec.type;
        })
          ? acc.concat(rec.attributes)
          : acc;
      }, []);
    } else {
      const theObj = data.find((r) => {
        return r.id === res.data.id;
      });
      data = theObj.attributes;
      data.id = theObj && theObj.id;
    }

    return Promise.resolve({
      data,
      meta,
    });
  };

  const headerInfo = (record) => {
    const { headers: _headers, ...rest } = options;
    let headers = _headers;

    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (record) {
      return {
        headers,
        ...rest,
        data: record,
        //     cancelToken: new apiClient.CancelToken((c) => {
        //       // An executor function receives a cancel function as a parameter
        //       apiClient.cancel = c;
        //     }),
      };
    }
    return {
      headers,
      ...rest,
    };
  };

  const cancel = () => {
    return apiClient.cancel && apiClient.cancel();
  };

  const getErrorMessage = (error) => {
    if (error?.data && error?.data?.code && error?.data?.title) {
      return error.data.title;
    }
    if (error?.data?.errors) {
      return error?.data?.errors;
    }
    if (error?.errors && error?.errors[0]?.title) {
      return error?.errors[0]?.title;
    }
    if (error?.errors && error?.errors[0]?.detail) {
      return error?.errors[0]?.detail;
    }
    return error;
  };

  const postValidate = async (response) => {
    if (noResolveJson) {
      return response;
    }
    
    if (
      response?.data?.status &&
      response.data.status !== 200 &&
      response?.data?.code &&
      response.data.code !== 200
    ) {
      return processError(response);
    }

    return deserialize(response);
  };

  const processError = (error) => {
    cancel();
    const errorMessage = getErrorMessage(error);
    setLastError(errorMessage);
    return promises.reject(error);
  };

  const get = (url, cbPostValidate, cbError) => {
    return apiClient.get({
      url: `${host}${url}`,
      options: {
        ...headerInfo(),
        method: 'get',
      },
    }).then((response) => {
      if (cbPostValidate && isFunction(cbPostValidate)) {
        return cbPostValidate(response);
      }

      return postValidate(response);
    }).catch((error) => {
      if (cbError) {
        return cbError(error);
      }
      return processError(error);
    });
  };

  const post = (url, params, cbValidator, cbPostValidate, cbError) => {
    // if function pass in validator, this method will cross check before execute method.
    if (isFunction(cbPostValidate)) {
      const resValidate = cbValidator(params);
      if (resValidate) return resValidate;
    }
    return apiClient
      .post({
        url: `${host}${url}`,
        body: params,
        options: {
          ...headerInfo(),
          method: 'post',
        },
      })
      .then((response) => {
        if (cbPostValidate) {
          return cbPostValidate(response);
        }
        return postValidate(response);
      })
      .catch((error) => {
        if (cbError) {
          return cbError(error);
        }
        return processError(error);
      });
  };

  // upload file with header
  const upload = (url, params, headers, cbValidator, cbPostValidate, cbError) => {
    // if function pass in validator, this method will cross check before execute method.
    if (isFunction(cbValidator)) {
      const resValidate = cbValidator(params);
      if (resValidate) return resValidate;
    }
    return apiClient
      .post({
        url: `${host}${url}`,
        body: params,
        options: {
          ...headerInfo(headers),
          method: 'post',
        },
      })
      .then((response) => {
        if (cbPostValidate && isFunction(cbPostValidate)) {
          return cbPostValidate(response);
        }
        return postValidate(response);
      })
      .catch((error) => {
        if (cbError) {
          return cbError(error);
        }
        return processError(error);
      });
  };

  // uplaoadFile with header and record data with axios
  const uploadFile = (url, params, headers, cbValidator, cbPostValidate, cbError) => {
    // if function pass in validator, this method will cross check before execute method.
    if (isFunction(cbValidator)) {
      const resValidate = cbValidator(params);
      if (resValidate) return resValidate;
    }

    return apiClient
      .upload({
        url: `${host}${url}`,
        body: params,
        options: {
          ...headerInfo(headers),
          method: 'post',
        },
      })
      .then((response) => {
        if (cbPostValidate && isFunction(cbPostValidate)) {
          return cbPostValidate(response);
        }
        return postValidate(response);
      })
      .catch((error) => {
        if (cbError) {
          return cbError(error);
        }
        return processError(error);
      });
  };

  const put = (url, params, cbValidator, cbPostValidate, cbError) => {
    // if function pass in validator, this method will cross check before execute method.
    if (isFunction(cbValidator)) {
      const resValidate = cbValidator(params);
      if (resValidate) return resValidate;
    }
    return apiClient
      .put({
        url: `${host}${url}`,
        body: params,
        options: {
          ...headerInfo(),
          method: 'put',
        },
      })
      .then((response) => {
        if (cbPostValidate && isFunction(cbPostValidate)) {
          return cbPostValidate(response);
        }
        return postValidate(response);
      })
      .catch((error) => {
        if (cbError) {
          return cbError(error);
        }
        return processError(error);
      });
  };

  const remove = (url, params, cbValidator, cbPostValidate, cbError) => {
    if (cbValidator && isFunction(cbValidator)) {
      const resValidate = cbValidator(params);
      if (resValidate) return resValidate;
    }
    return apiClient
      .delete({
        url: `${host}${url}`,
        options: {
          ...headerInfo(params),
          method: 'delete',
        },
      })
      .then((response) => {
        if (cbPostValidate && isFunction(cbPostValidate)) {
          return cbPostValidate(response);
        }
        return postValidate(response);
      })
      .catch((error) => {
        if (cbError) {
          return cbError(error);
        }
        return processError(error);
      });
  };

  const request = ({ method, url, params, headers, cbValidator, cbPostValidate, cbError }) => {
    const _method = method.toLowerCase();

    if (_method === 'delete') {
      return remove(url, params, cbValidator, cbPostValidate, cbError);
    }
    if (_method === 'put') {
      return put(url, params, cbValidator, cbPostValidate, cbError);
    }
    if (_method === 'post') {
      return post(url, params, cbValidator, cbPostValidate, cbError);
    }
    if (_method === 'upload') {
      return uploadFile(url, params, headers, cbValidator, cbPostValidate, cbError);
    }
    return get(url, cbPostValidate, cbError);
  };

  return {
    cancel,
    request,
    get,
    put,
    post,
    remove,
    upload,
    uploadFile,
    lastError,
    loading,
    setLastError,
    setLoading,
  };
};

export default Requestly;
