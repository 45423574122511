import clsx from 'clsx';

const Placeholder = ({className, clearValue, children, cx, innerProps, isFocused, isDisabled, setValue, selectProps, selectOption, isRtl, isMulti, hasValue, getValue, getStyles, ...rest}) => {

  return (
    <div ctrl-name="placeholder" className={clsx("text-gray-400 absolute left-1 font-1 m-0", className)}>
      {children}
    </div>
  );
}

export default Placeholder;