/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { isFunction, safeSetValue } from '@gvlab/react-lib/utils';

const snackbarDefaultContent = {
  autoHideDuration: 3000,
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  elevation: 6,
  message: 'Message Here',
  severity: 'info',
  onClose: () =>{},
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    state: false,
    options: snackbarDefaultContent,
  },
  reducers: {
    openSnackbar: (state, action) => {
      state.state = true;
      state.options = safeSetValue(state.options, action.payload);
    },
    closeSnackbar: (state, action) => {
      state.state = false;
      state.options = safeSetValue(state.options, action.payload);

      if (isFunction(state.options.onClose)) {
        state.options.onClose();
      }
    },
    snackbarError: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload,
        severity: 'error',
      };
    },
    snackbarInfo: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload,
        severity: 'info',
      };
    },
    snackbarSuccess: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload,
        severity: 'success',
      };
    },
    snackbarWarning: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        ...action.payload,
        severity: 'warning',
      };
    },
  },
});

export const {
  openSnackbar,
  closeSnackbar,
  snackbarError,
  snackbarInfo,
  snackbarSuccess,
  snackbarWarning,
} = snackbarSlice.actions;

export const selectSnackbar = () => ({gv}) => gv.snackbar;
export const selectSnackbarOptions = () => ({gv}) => gv.snackbar.options;

export default snackbarSlice.reducer;
