/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import { List } from '@gvlab/react-theme/mui';
import * as icons from '@gvlab/react-icons';

import { useResolvedPath, matchPath } from '@gvlab/react-router';
import SubMenuItem from './SubMenuItem';

// Rendering MenuItem block
/*
  {
    title: 'General',
    pages: [
      {
        title: 'Overview',
        href: '/dashboard/overview',
        icon: icons['Home'],
        label: green[500], Mock
      },
      ...
      {
        title: 'Inventory',
        href: '/Inventory',
        icon: icons['Inventory'],
        children: [
          {
            title: 'Children block'
            ...
          }
          ...
        ]
      },
      ...
    ],
  },
 * */
const MenuItemBlock = ({ pages, ...rest }) => {
  return (
    <List>
      {pages.reduce((items, page) => {
        return RenderChildRoutes({ items, page, ...rest });
      }, [])}
    </List>
  );
};

MenuItemBlock.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const RenderChildRoutes = (props) => {
  const { router, items, page, depth } = props;
  const resolved = useResolvedPath(page.href);
  const match = matchPath(page.href, {path: resolved, exact: true}) !== null;
  const iconObject = page.icon ? icons[page.icon] : '';

  if (page?.children) {
    items.push(
      <SubMenuItem
        depth={depth}
        icon={iconObject}
        key={page.title}
        label={page.label}
        open={match}
        title={page.title}
      >
        <MenuItemBlock depth={depth + 1} pages={page.children} router={router} />
      </SubMenuItem>
    );
  } else {
    items.push(
      <SubMenuItem
        depth={depth}
        href={page.href}
        icon={iconObject}
        key={page.title}
        label={page.label}
        title={page.title}
        active={match}
      />
    );
  }

  return items;
};


export default MenuItemBlock;
