/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import { FormBase, FormDetails, FormReduxStorage } from './helpers';

const CustomForm = (props) => (
  <FormReduxStorage {...props}>
    <FormBase>
      <FormDetails />
    </FormBase>
  </FormReduxStorage>
);

export default CustomForm;
