/* eslint-disable import/no-extraneous-dependencies */
import crossFetch from 'cross-fetch';
import { isFunction } from '../common';

const crossFetcher = (options) => {

  const fetcher = crossFetch;

  const handleByJson = async (error) => {
    try {
      const err = await error.json();
      const status = error?.status || error?.response?.status || 500;
      const code = status || '';
      return {
        message: err,
        status,
        code,
      };
    } catch (err) {
      const status = error?.status || error?.response?.status || 500;

      return {
        err: error?.statusText || error?.status || '',
        status,
        code: status || '',
      };
    }
  };

  const handleError = async (error) => {
    try {
      if (typeof error.json === 'function') {
        if (error?.status >= 400) {
          return await handleByJson(error);
        }
      }
      return error;
    } catch (e) {
      console.dir(e);
      return e;
    }
  };

  const request = async ({url, params}) => {
    return new Promise((resolve, reject) => {
      fetcher(url, params)
        .then((result) => {
          if (!result.ok && result?.status !== 200) throw result;
          if (options?.noResolveJson) return result;

          if (isFunction(result.json)) {
            return result.json();
          }

          return result?.data || result;
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((error) => {
          return reject(handleError(error));
        });
    });
  };

  return {
    request,
  }
};

export default crossFetcher;
