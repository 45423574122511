/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/extensions */
/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import { makeStyles } from '@gvlab/react-theme/styles';

import clsx from 'clsx';
import { BackupRounded as BackupRoundedIcon } from '@gvlab/react-icons';

import Dropzone from '../../components/react-dropzone-uploader';
import * as DefaultInputComponent from './InputComponent';
import * as DefaultLayoutComponent from './LayoutComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  image: {
    width: 130,
  },
  files: {
    marginTop: theme.spacing(3),
  },
}));

let toastMessage = '';

const toast = (innerHTML) => {
  toastMessage = innerHTML;
  setTimeout(() => {
    toastMessage = '';
  }, 3000);
};

const SpaceUploader = (props) => {
  const { InputComponent, LayoutComponent, onChangeStatus, onFileUpload, uploadParams, disabled, canCancel, multiple, autoload, ...rest } =
    props;
  const classes = useStyles();

  const uploadFileDefault = (params) => {
    const { file } = params;
    const axios = require('axios');
    const FormData = require('form-data');

    const data = new FormData();
    data.append('file', file);

    const config = {
      ...uploadParams,
      data,
    };

    axios(config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const handleUploadFile = async (params) => {

    const uploadFile = onFileUpload || uploadFileDefault;

    uploadFile(params);
  };

  const onChangeDefault = ({ meta, remove }, status) => {
    if (status === 'headers_received') {
      toast(`${meta.name} uploaded!`);
      remove();
    } else if (status === 'aborted') {
      toast(`${meta.name}, upload failed...`);
    }
  };

  function renderLayoutComponent(params) {
    return (<DefaultLayoutComponent {...params} layout={LayoutComponent} />)
  };

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    if (LayoutComponent) {
      return (
        <LayoutComponent
          dropzoneProps={dropzoneProps}
          extra={maxFiles}
          files={files}
          input={input}
          maxFiles={maxFiles}
          previews={previews}
          submitButton={submitButton}
        />
      );
    }

    return <div {...dropzoneProps}>{files.length < maxFiles && input}</div>;
  };

  const renderInputComponent = (params) => {
    return (<DefaultInputComponent {...params} input={InputComponent} />)
  };

  const Input = (params) => {
    const { accept, onFiles, getFilesFromEvent } = params;

    if (InputComponent) {
      return <InputComponent {...params} />;
    }

    return (
      <label 
        className={clsx(
          'inline-flex justify-center p-2 text-gray-500 rounded-lg',
          'cursor-pointer', 
          'hover:text-gray-900 hover:bg-gray-100',
          'dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600',
        )}>
        <BackupRoundedIcon fontSize="small" />
        <input
          accept={accept}
          multiple
          onChange={async (e) => {
            const {target} = e;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            target.value = null;
          }}
          style={{ display: 'none' }}
          type="file"
        />
        <span className="sr-only">Upload image</span>
      </label>
    );
  };

  return (
    <div className={classes.root} disabled={disabled}>
      <Dropzone
        LayoutComponent={Layout}
        InputComponent={Input}
        canCancel={canCancel}
        getUploadParams={(params) => {
          if (!autoload) {
            return params;
          }

          const { file, meta } = params;
          handleUploadFile({ file, meta });

          return {};
        }}
        multiple={multiple}
        onChangeStatus={onChangeStatus}
        {...rest}
      />
      {toastMessage && (
      <div className="toast toast-end">
        <div className="alert alert-info">
          <div>
            <span>{toastMessage}</span>
          </div>
        </div>
      </div>)}
    </div>
  );
};

SpaceUploader.defaultProps = {
  uploadParams: {
    method: 'post',
    url: 'http://gateway.scrumhr.com/spaces/v1/upload',
    headers: {
      uid: '5d6bb7a68e9909360ba05928',
      'Content-Type': 'application/json',
    },
  },
  canCancel: false,
  multiple: false,
  autoload: true,
};

SpaceUploader.propTypes = {
  autoload: PropTypes.bool,
  canCancel: PropTypes.bool,
  multiple: PropTypes.bool,
  InputComponent: PropTypes.func,
  LayoutComponent: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onFileUpload: PropTypes.func,
  uploadParams: PropTypes.object,
};

export default SpaceUploader;
