/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';
import { Delete as DeleteIcon } from '@gvlab/react-icons';
import { isFunction } from '@gvlab/react-lib/utils';
import { CancelButton, ConfirmButton } from '../styles';

const ActionPanel = (props) => {
  const { mode, actionsAllow, onCreate, onDelete, onUpdate } = useSelector(({ app }) => ({
    mode: app.form.props.state,
    actionsAllow: app.form.props.options.actionsAllow,
    onCreate: app.form.props.options.onCreate,
    onUpdate: app.form.props.options.onUpdate,
    onDelete: app.form.props.options.onDelete,
  }));
  const { buttonAddCaption, buttonDeleteCaption, buttonOKCaption } = props;

  if (mode === 'add') {
    if (actionsAllow.canAdd && isFunction(onCreate)) {
      return (
        <ConfirmButton onClick={onCreate} variant="contained" size="small">
          {buttonAddCaption}
        </ConfirmButton>
      );
    }
    return null;
  }

  return (
    <>
      {actionsAllow.canDelete && isFunction(onDelete) && (
        <CancelButton onClick={onDelete} variant="contained" size="small">
          <DeleteIcon />
          {buttonDeleteCaption}
        </CancelButton>
      )}
      {mode === 'edit' && actionsAllow.canEdit && isFunction(onUpdate) && (
        <ConfirmButton onClick={onUpdate} variant="contained" size="small">
          {buttonOKCaption}
        </ConfirmButton>
      )}
    </>
  );
};

export default ActionPanel;
