import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// initial state of order detail
const initialState = {
  state: 'PENDING',
  success: false,
  errors: false,
  detail: null,
};

// get order detail from API and return response data to reducer
// to update state in store for order detail page to use it to display data in UI components (e.g. OrderDetail.js)
export const viewDetail = createAsyncThunk(
  'order/viewDetail',
  async ({ orderId }, { dispatch, getState, rejectWithValue }) => {
    try {
      // set order detail state to pending
      return await Requestly()
        .get(`/user/v1/orders/${orderId}?userType=pro&&showchild=true`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// clear media detail state
export const clearDetail = createAsyncThunk('order/clearDetail', async (payload, { dispatch }) => {
  return true;
});

// order detail slice reducer
// to update state in store
// for order detail page to use it to display data in UI components (e.g. OrderDetail.js)
const orderSlice = createSlice({
  name: 'order',
  initialState,
  extraReducers: {
    // set order view state to done when view order detail is fulfilled
    [viewDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: action.payload,
      };
    },
    [viewDetail.pending]: (state, action) => {
      return {
        ...initialState,
        state: 'PENDING',
      };
    },
    [viewDetail.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        errors: action.payload,
      };
    },
    [clearDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

// export order detail slice reducer
export default orderSlice.reducer;

// export order detail selector
export const selectOrderState = ({ app }) => app.dashboard.order;
export const selectOrder = ({ app }) => app.dashboard.order.detail;
