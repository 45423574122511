/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';

import { Drawer } from '@gvlab/react-theme/mui';
import { closeNavBarMobile } from 'Layout/Dashboard/store/layoutSlice';

import NavBarContent from './NavBarContent';

const NavBar = () => {
  const dispatch = useDispatch();
  const navBarMobile = useSelector(({ app }) => app.layout.dashboard.options.navBarMobile);

  return (
    <div className="h-full hidden md:flex basis-64">
      <NavBarContent />
      <Drawer
        anchor="left"
        onClose={() => dispatch(closeNavBarMobile())}
        open={navBarMobile}
        variant="temporary"
      >
        <NavBarContent />
      </Drawer>
    </div>
  );
};

export default NavBar;
