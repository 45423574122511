/* eslint-disable import/no-anonymous-default-export */
export default (dataSource, path, newValue) => {
  let { ...dataObject } = dataSource;
  const dataPath = path.split('.');

  while (dataPath.length > 1) {
    dataObject = dataObject[dataPath.shift()];
  }

  if (dataObject) {
    dataObject[dataPath.shift()] = newValue;
  } else {
    dataObject = dataSource;
  }

  return dataObject;
};
