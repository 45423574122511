import { createSlice } from '@reduxjs/toolkit';

const initial = {
  state: false,
  error: false,
  detail: false,
};

const authUserSlice = createSlice({
  name: 'user',
  initialState: initial,
  reducers: {
    init:  () => {
      return {
        ...initial,
      }
    },
    setUserData: (state, action) => {
      return {
        ...initial,
        state: 'DONE',
        detail: action.payload,
      }
    },
    setError: (state, action) => {
      return {
        ...initial,
        state: 'ERROR',
        error: action.payload,
      }
    },
  },
});

export const { init, setUserData, setError } = authUserSlice.actions;

export default authUserSlice.reducer;
