/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/prefer-default-export */
import { Button } from '@gvlab/react-ui/v2';
import { FormField } from 'components/Common/FormField';

export const ListDetail = ({ task, formState, groupId, onAdd, onRemove, onUpdate, onViewTask }) => {
  const taskIdx = task?.idx ?? groupId * -1;

  return (
    <div className="flex flex-col justify-between w-full">
      <div className="p-4">
        <div className="w-full mb-1">
          <div className="mt-1 flex rounded-md shadow-sm">
            <FormField
              label="Subject"
              type="text"
              name={`items[${taskIdx}].subject`}
              id={`items[${taskIdx}].subject`}
              className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              formState={formState}
            />
          </div>
        </div>
        <div className="w-full mb-1">
          <div className="mt-1">
            <FormField
              label="Job Description"
              name={`items[${taskIdx}].description`}
              id={`items[${taskIdx}].description`}
              multiline
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              formState={formState}
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">Brief description for your job / task.</p>
        </div>
        <div className="w-full mb-1 text-gray-500">
          <label htmlFor="price" className="block text-sm font-medium">
            Total price
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <FormField
              type="number"
              name={`items[${taskIdx}].amount`}
              id={`items[${taskIdx}].amount`}
              className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-right indent-2"
              formState={formState}
            />
            <span className="flex items-center px-3 pointer-events-none sm:text-sm bg-gray-300">
              Credit
            </span>
          </div>
        </div>
      </div>
      <div className="p-4 flex flex-rows gap-x-1 justify-end	">
        <Button
          color="info"
          onClick={(event) => {
            event.preventDefault();
            if (taskIdx < 0) onAdd();
            if (taskIdx >= 0) onUpdate(taskIdx);
          }}
        >
          Save
        </Button>
        {taskIdx >= 0 && (
          <Button
            color="error"
            onClick={(event) => {
              event.preventDefault();
              if (onRemove) onRemove(taskIdx);
            }}
          >
            Remove
          </Button>
        )}
      </div>
    </div>
  );
};
