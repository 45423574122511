/* eslint-disable import/no-named-as-default-member */
import { combineReducers } from '@reduxjs/toolkit';

import recoverSlice from './recoverSlice';
import loginSlice from './loginSlice';
import verifySlice from './verifySlice';

const AuthReducers = combineReducers({
  recover: recoverSlice,
  login: loginSlice,
  verify: verifySlice,
});

export default AuthReducers;
