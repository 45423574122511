/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
    },
  },
};
