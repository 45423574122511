/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@gvlab/react-theme/styles';

const LineBase = styled('div')(({ theme }) => ({
}));

const Line =() => {
  return (
    <LineBase className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className={`border-t border-gray-200 `} />
      </div>
    </LineBase>
  )
};

export default Line;