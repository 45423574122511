/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import { numberUtils } from '@gvlab/react-lib/utils';

import {
  clearDetail,
  selectWithdrawDetail,
  selectWithdrawDetailState,
} from 'store/Dashboard/withdrawDetailSlice';

const WithdrawDetail = (props) => {
  const dispatch = useDispatch();
  const withdrawDetailState = useSelector(selectWithdrawDetailState);
  const withdrawDetail = useSelector(selectWithdrawDetail);

  if (!withdrawDetailState) return null;

  if (withdrawDetailState.state !== 'DONE') return null;

  const extras = {
    bank: withdrawDetail.params?.bank || '',
    amount: withdrawDetail.params?.amount || 0,
    account: withdrawDetail.params?.account || '',
  };

  if (withdrawDetail.params) {
    withdrawDetail.params.forEach((param) => {
      extras[param.namespace] = param.value;
    });
  }

  return (
    <Drawer fullSize={false} onClose={() => dispatch(clearDetail())} open title="Withdraw Credit">
      <div className="flex flex-col w-full h-full items-center	content-center	justify-center	">
        <div className="flex flex-col max-w-md p-6 space-y-4 divide-y sm:w-96 sm:p-10 divide-gray-300 bg-gray-50 text-gray-800">
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <h3 className="text-l font-semibold uppercase">Account detail</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Name</span>
              <h3 className="text-lg ">{withdrawDetail.profile.fullName}</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Id</span>
              <span className="uppercase">{withdrawDetail.profile.id}</span>
            </li>
          </ul>
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <h3 className="text-l font-semibold uppercase">Bill detail</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Id</span>
              <span className="uppercase font-semibold">{withdrawDetail.id}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Status</span>
              <span className="uppercase">{withdrawDetail.status}</span>
            </li>
            <li className="flex items-start justify-between">
              <span>Amount</span>
              <span>{numberUtils.currencyFormat(withdrawDetail.totalAmount, 0, ' ')}</span>
            </li>
          </ul>
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <span className="uppercase">Bank</span>
              <span className="uppercase bold">{extras?.bank}</span>
            </li>
            <li className="flex items-start justify-between">
              <span className="uppercase bold">Account</span>
              <span>{extras?.account}</span>
            </li>
          </ul>
          {extras?.reason && (
            <div className="alert alert-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <div>
                <h3 className="font-bold uppercase">{extras?.reason}</h3>
                <span>{extras?.description}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default WithdrawDetail;
