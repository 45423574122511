/* eslint-disable import/no-anonymous-default-export */

const StyledComponent = {
  styleOverrides: {
    // root: {
    //   color: palette.tertiary.contrastText,
    //   backgroundColor: palette.tertiary.main,
    // },
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    '& .MuiTableCell-paddingCheckbox': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
};

export default StyledComponent;
