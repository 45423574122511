/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Typography, styled } from '@gvlab/react-theme/styles';
import { Paper, Link, Button } from '@gvlab/react-theme/mui';
import { safeSetValue } from '@gvlab/react-lib/utils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 420,
  position: 'fixed',
  bottom: 0,
  right: 0,
  margin: theme.spacing(3),
  outline: 'none',
  zIndex: 2000,
}));

const StyledMedia = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  height: 180,
  textAlign: 'center',
  '& > img': {
    height: '100%',
    width: 'auto',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 2, 2, 1),
}));

const CookiesNotification = () => {
  let open = false;

  const setOpen = (patch) => {
    open = safeSetValue(open, patch);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <StyledPaper elevation={3}>
      <StyledMedia>
        <img alt="Cookies" src="/images/undraw_cookie_love_ulvn.svg" />
      </StyledMedia>
      <StyledContent>
        <Typography variant="body1">
          We use Cookies to ensure that we give you the best experience on our website. Read our{' '}
          <Link component="a" href="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          .
        </Typography>
      </StyledContent>
      <StyledActions>
        <Button color="primary" onClick={handleClose} variant="contained">
          I Agree
        </Button>
      </StyledActions>
    </StyledPaper>
  );
};

export default CookiesNotification;
