/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@gvlab/react-ui/v2';
import { sendInvoice } from 'store/Dashboard/quotationSlice';
import { selectOrder } from 'store/Dashboard/orderSlice';
import { exportPdf } from 'store/Dashboard/invoiceExportSlice';
import { viewDetail as viewChatsDetail } from 'store/Dashboard/chatsSlice';

const ActionBar = (props) => {
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);

  // if invoice is paid, show confirm and sent button
  if (['invoice', 'paid', 'done', 'payment', 'payment-reject'].includes(orderDetail.status)) {
    return (
      <div className="bg-gray-50 px-4 py-4 mt-4 flex gap-x-1 justify-end">
        <Button
          onClick={(event) => {
            dispatch(exportPdf({ invoicesId: orderDetail.invoicesId }));
          }}
        >
          Print invoice
        </Button>
        <Button
          color="base"
          onClick={() => {
            dispatch(viewChatsDetail({ ordersId: orderDetail.id }));
          }}
        >
          Chat with customer
        </Button>
      </div>
    );
  }

  if (orderDetail.status === 'done') return null;
  if (orderDetail.status === 'payment') return null;
  if (orderDetail.status === 'payment-reject') return null;

  return (
    <div className="bg-gray-50 px-4 py-4 mt-4 flex gap-x-1 justify-end">
      <Button
        onClick={(event) => {
          dispatch(sendInvoice({ ordersId: orderDetail.id }));
        }}
      >
        Confirm and sent
      </Button>
    </div>
  );
};

export default ActionBar;
