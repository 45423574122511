/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { styled } from '@gvlab/react-theme/styles';
import { ButtonBase } from '@gvlab/react-theme/mui';

export const MenuButtonBase = ({className, ...rest}) => (
  <ButtonBase
    className={`panel-default flex flex-row items-center	justify-start	text-sm font-normal	${className}`}
    {...rest}
  />
);

export const MenuButton = styled(MenuButtonBase)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  '& .icon': {
    width: '18px'
  },
  '.active': {
    ...theme.components.MuiSubMenuItem.active,
    backgroundColor: '#536d89',
    mixBlendMode: 'difference',
  },
}));

export const MenuButtonActive = styled(MenuButtonBase)(({ theme }) => ({
  ...theme.components.MuiSubMenuItem.active,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor: '#536d89',
  mixBlendMode: 'difference',
}));