/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import Menu from './Menu';
import MenuItem from './MenuItem';

const MainMenu = ({ className, menus, ...rest }) => {
  if (!menus || menus.length === 0) return null;

  return (
    <Menu {...rest}>
      {menus.map((list, key) => (
        <MenuItem component="div" key={key} pages={list?.pages} title={list.title} />
      ))}
    </Menu>
  );
};

MainMenu.propTypes = {
  className: PropTypes.string,
  menus: PropTypes.array.isRequired,
};

export default MainMenu;
