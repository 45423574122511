/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      padding: '16px 24px',
    },
  },
};
