/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { dialogCritical } from '@gvlab/react-create-app-gv/src/Dialog/store/dialogSlice';
import { show, close } from 'store/loadingSlice';

// query quotation detail from API and return response data to reducer to update state in store
const queryDetail = ({ quotationId }) => {
  return Requestly()
    .request({
      method: 'get',
      url: `/user/v1/quotations/${quotationId}?showchild=true&userType=pro`,
    })
    .then((response) => {
      return response.data;
    })
    .then((response) => {
      const { contexts, orders, projectsId, propertyId, profile, property, project, ...rest } =
        response;
      const resp = {
        ...rest,
        orders: response?.orders,
        items: response.items,
        project_duedate: response.dueAt ? response.dueAt.substr(0, 10) : '',
        project_subject: response.subject,
        project_description: response.description,
        project_amount: response.totalAmount,
      };
      resp.items = response.items;
      if (response.items) {
        resp.items = response.items.map((task, idx) => ({ ...task, idx }));
      }

      if (resp.items) {
        const phaseList = [];
        let totalAmount = 0;
        resp.items.forEach((task) => {
          let found = false;
          totalAmount += Number(task.amount);
          phaseList.forEach((phaseItem) => {
            if (phaseItem.groupId === task.groupId) {
              phaseItem.items.push(task.idx);
              phaseItem.total += task.amount * 1;
              found = true;
            }
          });
          if (found === false) {
            const orderItem = response?.orders.filter((order) => order.groupId === task.groupId);
            phaseList.push({
              groupId: task.groupId,
              total: task.amount,
              items: [task.idx],
              order: orderItem,
            });
          }
        });

        resp.phases = phaseList;
        resp.project_amount = totalAmount;
      }

      contexts.forEach((ctx) => {
        resp[ctx?.namespace] = ctx.value;
      });

      if (response.status !== 'draft') {
        if (profile) {
          Object.keys(profile).forEach((key) => {
            resp[`owner_${key.toLowerCase()}`] = profile[key];
          });
        }
        if (property) {
          Object.keys(property).forEach((key) => {
            if (key === 'propertyType') resp.property_type = property[key];
            if (key === 'zipCode') resp.property_postcode = property[key];
            resp[`property_${key.toLowerCase()}`] = property[key];
          });
        }
        if (project) {
          Object.keys(project).forEach((key) => {
            resp[`project_${key.toLowerCase()}`] = project[key];
          });
        }
      }

      resp.manager = response.manager;
      return resp;
    });
};

// view quotation detail thunk action creator to get quotation detail from API
// and open dialog to view quotation detail
export const viewDetail = createAsyncThunk(
  'quotationDetail/viewDetail',
  async ({ quotationId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(show({ message: 'Loading...' }));
      // query quotation detail from API
      const response = await queryDetail({ quotationId });

      dispatch(close());
      // if response is null, show critical dialog
      if (!response) {
        return rejectWithValue('empty response');
      }

      return response;
    } catch (error) {
      dispatch(close());
      dispatch(dialogCritical());
      return rejectWithValue(error);
    }
  }
);

// initial state of quotation detail
const initialState = {
  state: 'PENDING',
  success: false,
  errors: false,
  detail: null,
};

// quotation detail slice reducer to update state in store
const quotationDetailSlice = createSlice({
  name: 'quotationDetail',
  initialState,
  reducers: {
    clearDetail: (state, action) => {
      state.state = initialState.state;
      state.detail = initialState.detail;
      state.success = initialState.success;
      state.errors = initialState.errors;
    },
  },
  extraReducers: {
    [viewDetail.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    [viewDetail.fulfilled]: (state, action) => {
      state.state = 'DONE';
      state.success = true;
      state.errors = false;
      state.detail = action.payload;
    },
    [viewDetail.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
      state.detail = initialState.detail;
    },
  },
});

// export quotation detail slice actions
export const { clearDetail } = quotationDetailSlice.actions;
export const selectQuotationState = ({ app }) => app.dashboard.quotationDetail;

export default quotationDetailSlice.reducer;
