/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { renderComponent } from '@gvlab/react-lib/utils';
import CardHeader from '../CardHeader';
import Progress from '../../components/Progress';
import LHSPanel from './LHSPanel';
import useStyles from './styled'
import Container from './Container';

const Drawer = (props) => {
  const {
    anchor,
    actions,
    avatar,
    children,
    border,
    onClose,
    title,
    fullSize,
    variant,
    progress,
    ...rest
  } = props;

  const classes = useStyles();

  // sContent
  let contentPadding = 'p-4';
  // no padding
  contentPadding = (fullSize) ? 'p-16 pt-2' : contentPadding;
  // full size
  contentPadding = (border === 'none') ? 'p-0' : contentPadding;

  return (
    <Container {...props} className="transition-opacity ease-out duration-200 overflow-none flex ">
      <CardHeader avatar={avatar || <LHSPanel { ...rest} onClose={onClose} />} title={title} className="print:hidden"/>
      {progress && <Progress />}
      <div className={clsx('content', contentPadding, 'h-full w-auto overflow-y-scroll')}>
        {actions && (
        <div className="bg-gray-50 px-4 py-4">
          {renderComponent(actions)}
        </div>)}
        {children}
      </div>
    </Container>
  );
};

Drawer.defaultProps = {
  border: 'normal',
  fullSize: true,
  anchor: 'right',
  variant: 'drawer',
  progress: false,
};

Drawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
  avatar: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.node,
  border: PropTypes.any,
  fullSize: PropTypes.bool,
  progress: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  variant: PropTypes.oneOf(['panel', 'drawer']),
};

export default Drawer;
