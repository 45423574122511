/* eslint-disable import/no-anonymous-default-export */
import * as icons from '@gvlab/react-icons';

export default [
  {
    title: 'General',
    pages: [
      {
        title: 'Overview',
        href: '/dashboard/overview',
        icon: icons.Home,
        // label: () => <Label color={colors.green[500]}>Mock</Label>
      },
      {
        title: 'Maintainer',
        href: '/dashboard/admin',
        icon: icons.SupervisorAccount,
      },
    ],
  },
  {
    title: 'USER SECTION',
    pages: [
      {
        title: 'Organizations',
        href: '/dashboard/organizations',
        icon: icons.Person,
      },

      {
        title: 'User',
        href: '/dashboard/user',
        icon: icons.Person,
      },
    ],
  },
  {
    title: 'Settings',
    pages: [
      {
        title: 'Change Log',
        href: '/dashboard/changelog',
        icon: icons.CalendarToday,
      },
    ],
  },
];
