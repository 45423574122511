import ReactDOM from 'react-dom';
import { BaseApp, reportWebVitals } from '@gvlab/react-create-app-gv';
import routesObjects from 'Routes';

import './assets/scss/index.css';
import './assets/build/styled.css';

import packageJson from '../package.json';

ReactDOM.render(
  <BaseApp
    copyright={`2023 ~ Copyright by ${packageJson?.author}. \n ${packageJson?.displayName} - Version ${packageJson?.version} (${process.env.NODE_ENV})`}
    displayName={`${packageJson?.displayName}`}
    routes={routesObjects}
  />,
  document.getElementById('root')
);

reportWebVitals(/* console.log */);

// serviceWorker.unregister();
