import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Header = (props) => {
  const { className, onNew, title, subTitle, buttonText, buttonLink, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <GridContainer alignItems="flex-end" justifyContent="space-between" spacing={3}>
        <GridItem>
          {subTitle && (
            <Typography component="h1" variant="h3">
              {subTitle}
            </Typography>
          )}
          <Typography component="h2" gutterBottom variant="overline">
            {title || 'Management'}
          </Typography>
        </GridItem>
        <GridItem>{/* <ActionButton /> */}</GridItem>
      </GridContainer>
    </div>
  );
};

Header.propTypes = {
  buttonLink: PropTypes.any,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  onNew: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

export default Header;
