/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.secondary.contrastText,
      borderBottom: `1px solid ${palette.divider}`,
      '&:hover': {
        backgroundColor: palette.secondary.dark,
      },
      '&:last-child': {
        border: 0,
      },
    },
  },
};
