/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Link as RouterLink } from '@gvlab/react-router';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@gvlab/react-theme/mui';
import {
  AccountCircle,
  Menu as MenuIcon,
  Input as InputIcon,
  Notifications as NotificationsIcon,
} from '@gvlab/react-icons';

import { NotificationsPopover } from '@gvlab/react-ui/components';
import { useAuth } from '@gvlab/react-create-app-gv';
import { selectGvUser } from '@gvlab/react-create-app-gv/stores';

import {
  openNotification,
  closeNotification,
  openNavBarMobile,
  openProfileBar,
  selectNotifications,
  getNotifications,
} from 'Layout/Dashboard/store/layoutSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    zIndex: 2,
  },
  flexGrow: {
    flexGrow: 1,
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logo: {
    height: '36px',
  },
}));

const TopBar = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { className, title, ...rest } = props;

  const userInfo = useSelector(selectGvUser);
  const notifications = useSelector(selectNotifications);

  const classes = useStyles();
  const notificationsRef = useRef(null);

  const open = Boolean(anchorEl);

  const authCtx = useAuth();

  useEffect(() => {
    let mounted = true;

    dispatch(getNotifications());

    return () => {
      mounted = false;
    };
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="secondary">
      <Toolbar>
        <div className="sm:block md:hidden">
          <IconButton color="inherit" onClick={() => dispatch(openNavBarMobile())}>
            <MenuIcon />
          </IconButton>
        </div>
        <RouterLink to="/">
          <img alt="Logo" className={classes.logo} src="/images/logos/large__nobg.png" />
        </RouterLink>
        <Typography className={classes.name} variant="h6">
          &nbsp;&nbsp;{title}
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden mdUp>
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={() => dispatch(openNotification())}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.list.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Hidden>
        {userInfo && (
          <div>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="account of current user"
              color="inherit"
              onClick={() => dispatch(openProfileBar())}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="menu-appbar"
              keepMounted
              onClose={handleClose}
              open={open}
            >
              <MenuItem onClick={() => dispatch(openProfileBar())}>Profile</MenuItem>
              <MenuItem
                onClick={() => {
                  authCtx.logout();
                }}
              >
                <ListItemIcon>
                  <InputIcon fontSize="small" />
                </ListItemIcon>
                <Typography noWrap variant="inherit">
                  Sign Out
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications.list}
        onClose={() => dispatch(closeNotification())}
        open={notifications.state}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default TopBar;
