/* eslint-disable import/no-extraneous-dependencies */
import objectByString from '@gvlab/react-lib/utils/objectByString';
import { numberUtils, dateUtils } from '@gvlab/react-lib/utils';
import * as standardUtils from '@gvlab/react-lib/utils';

/*
  {
    title: 'ID',
    component: 'Input', Input / Password / Checkbox / DBSelect / MultiSelect / Select / Radio / Date / Datetime / Time / List / Media / Number / Custom > Function
    dataIndex: 'id',
    key: 'id',
    width: 100,
    hide: true,
    readOnly: true,
    |
    or
    |
    readonly: true,

  }

  Listing
  ----------
  {
    title: 'Platform',
    component: 'Select',
    options: [
      {
        value: ' ',
        label: 'Please select the platform'
      },
      {
        value: 'mbb',
        label: 'MBB2U',
      },
      {
        value: 'tnb',
        label: 'TNB',
      },
      {
        value: 'm1',
        label: 'M1',
      },
    ],
    dataIndex: 'attributes.platform',
    key: 'platform',
  }, 
*/
const DataField = ({ tableName, ref, uniqKey }) => {
  const isReadOnly = () => {
    return ref.readonly || ref.readonly || false;
  };

  const getOptions = () =>
    ref?.options.map((option, uniqueId) => {
      return {
        ...option,
        key: `${uniqKey}-${uniqueId}`,
      };
    });

  const getDisplayValue = (value) => {
    const selected = ref?.options.filter((option) => option.value === value);
    return selected?.label;
  };
  const getKey = (dataset) => objectByString(dataset, ref.key);

  const getValue = (dataset) => {
    const objVal = objectByString(dataset, ref.dataIndex);

    if (!objVal) return ref?.default || '';

    if (!ref.cell) {
      return objVal.toString();
    }

    if (standardUtils.isFunction(ref.cell)) {
      return ref.cell(dataset);
    }

    if (ref.cell === 'currency') {
      return numberUtils.currencyFormat(objVal);
    }

    if (ref.cell === 'renderDate') {
      return numberUtils.formatDate(objVal, 'YYYY-MM-DD');
    }

    if (ref.cell === 'renderTime') {
      return dateUtils.formatDate(objVal, 'HH:MM:SS');
    }

    if (ref.cell === 'renderDateTime') {
      return dateUtils.formatDate(objVal, 'YYYY-MM-DD HH:MM:SS');
    }

    if (ref.cell === 'renderISODateTime') {
      return dateUtils.renderDateTime(objVal);
    }

    if (ref.cell === 'renderISODate') {
      return dateUtils.renderDate(objVal);
    }

    if (ref.cell === 'renderISOTime') {
      return dateUtils.renderTime(objVal);
    }

    if (ref.cell === 'list' && ref?.options) {
      const selected = ref.options.find((e) => {
        return e.value === objVal;
      });

      return selected?.label || selected?.value;
    }

    return ref.cell.toString();
  };

  const getAlignment = () => {
    if (ref.cell === 'currency') {
      return ref?.align || 'right';
    }
    return ref?.align || 'left';
  };
  return {
    ...ref,
    isReadOnly,
    getOptions,
    getDisplayValue,
    getKey,
    getValue,
    getAlignment,
  };
};

export default DataField;
