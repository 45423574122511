/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpaceUploader from '@gvlab/react-ui/v2/SpaceUploader';

import { sendMessage } from 'store/Dashboard/chatSlice';
import { reloadDetail, selectedChatsState } from 'store/Dashboard/chatsSlice';
import { selectOrder } from 'store/Dashboard/orderSlice';
import {
  uploadMedia,
  selectMediaDetail,
  selectMedia,
  clearDetail as clearMedia,
} from 'store/Dashboard/mediaSlice';

const ChatActions = (props) => {
  const dispatch = useDispatch();
  const orderDetail = useSelector(selectOrder);
  const mediaState = useSelector(selectMedia);
  const mediaDetail = useSelector(selectMediaDetail);
  const chatsState = useSelector(selectedChatsState);

  const [messageText, setMessageText] = useState('');

  function handlerSendMessage() {
    dispatch(sendMessage({ messageText, ordersId: orderDetail.id }));
  }

  function onInputChange(ev) {
    setMessageText(ev.target.value);
  }

  function onMessageSubmit(ev) {
    ev.preventDefault();
    if (messageText === '') {
      return;
    }
    let media = null;
    if (mediaDetail?.id) {
      media = [mediaDetail.id];
    }

    dispatch(
      sendMessage({
        messageText,
        ordersId: orderDetail.id,
        media,
      })
    ).then(() => {
      setMessageText('');
      dispatch(clearMedia());
      dispatch(reloadDetail({ ordersId: orderDetail.id }));
    });
  }

  const handleUploadFile = (params) => {
    const { file } = params;
    // upload media get file size
    console.log(file.size);

    dispatch(
      uploadMedia({
        media: file,
      })
    );
  };

  if (orderDetail.status === 'done') return null;

  return (
    <div className="border-t-1 bottom-0 right-0 left-0 p-0 justify-self-end">
      {mediaDetail?.url && (
        <div className="card w-full h-auto bg-base-100 shadow-xl mb-4">
          <figure>
            <img src={mediaDetail?.url} alt="attachment" className="rounded-xl" />
          </figure>
          <button
            className="btn btn-circle absolute -top-12 -right-1"
            onClick={() => dispatch(clearMedia())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}
      <div htmlFor="chat" className="sr-only">
        Your message
      </div>
      <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
        {!mediaDetail?.url && (
          <SpaceUploader onFileUpload={handleUploadFile} accept="image/*,audio/*,video/*" />
        )}
        <textarea
          id="chat"
          rows="1"
          className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your message..."
          onChange={onInputChange}
          value={messageText}
        />
        <button
          type="button"
          className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
          onClick={onMessageSubmit}
        >
          <svg
            aria-hidden="true"
            className="w-6 h-6 rotate-90"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
          <span className="sr-only">Send message</span>
        </button>
      </div>
    </div>
  );
};

export default ChatActions;
