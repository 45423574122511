/* eslint-disable import/prefer-default-export */
import { InputLabel } from '@gvlab/react-theme/mui';
import { BlockFieldsetLR } from 'components/Common/BlockFieldsetLR';

import { FormField } from 'components/Common/FormField';

export const Project = ({ formState = 'add' }) => (
  <BlockFieldsetLR
    title="Project Information"
    subtitle="Project Description"
    className="p-6 bg-white"
  >
    <div className="col-span-6">
      <InputLabel htmlFor="project-name" className="block text-sm font-medium text-gray-700">
        Project Name
      </InputLabel>
      <FormField
        type="text"
        name="project_subject"
        id="project_subject"
        autoComplete="project_subject"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>

    <div className="col-span-6">
      <InputLabel htmlFor="project_description" className="block text-sm font-medium text-gray-700">
        Description of Work
      </InputLabel>
      <FormField
        type="text"
        name="project_description"
        id="project_description"
        autoComplete="project_description"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        multiline
        minRows={3}
        formState={formState}
      />
    </div>

    <div className="col-span-6 sm:col-span-3">
      <InputLabel htmlFor="first-name" className="block text-sm font-medium text-gray-700">
        Budget
      </InputLabel>
      <FormField
        type="text"
        name="project_budget"
        id="project_budget"
        autoComplete="project_budget"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>

    <div className="col-span-6 sm:col-span-3">
      <InputLabel htmlFor="last-name" className="block text-sm font-medium text-gray-700">
        Total Value
      </InputLabel>
      <FormField
        type="text"
        name="project_amount"
        id="project_amount"
        autoComplete="project_amount"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        readOnly
      />
    </div>

    <div className="col-span-6 sm:col-span-4">
      <InputLabel htmlFor="email-address" className="block text-sm font-medium text-gray-700">
        Due at
      </InputLabel>
      <FormField
        type="date"
        name="project_duedate"
        id="project_duedate"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
  </BlockFieldsetLR>
);
