/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
import { useSelector, useDispatch } from 'react-redux';
import { close } from 'store/lightBoxSlice';

const LightBox = (props) => {
  const dispatch = useDispatch();
  const { lightbox } = useSelector(({ app }) => app);

  if (!lightbox) return null;
  if (lightbox?.state !== 'show') return null;

  return (
    <div
      id="modal"
      className="fixed top-0 left-0 w-screen h-screen bg-black/70 flex justify-center items-center z-[9999]"
    >
      <a
        className="fixed top-6 right-8 text-white text-5xl font-bold"
        href="javascript:void(0)"
        onClick={() => dispatch(close())}
      >
        &times;
      </a>
      <img
        id="modal-img"
        className="max-w-[800px] max-h-[600px] object-cover"
        src={lightbox?.detail}
      />
    </div>
  );
};

export default LightBox;
