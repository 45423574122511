/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { Link as RouterLink } from '@gvlab/react-router';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import { ListItem, Button, Collapse } from '@gvlab/react-theme/mui';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@gvlab/react-icons';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ...theme.components.MuiSubMenuItem.itemLeaf,
  fontSize: '1rem',
  '& .icon': {
    width: '24px'
  },
}));
const StyledListButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'normal',
  '& .icon': {
    width: '24px'
  },
  '.active': {
    ...theme.components.MuiSubMenuItem.active,
    backgroundColor: '#536d89',
    mixBlendMode: 'difference',
  },
}));
const StyledListActiveButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'normal',
  ...theme.components.MuiSubMenuItem.active,
  backgroundColor: '#536d89',
  mixBlendMode: 'difference',
  '& .icon': {
    width: '24px'
  },
}));
const StyledTitle = styled('p')(({ theme }) => ({
  marginLeft: theme.spacing(1), 
}));
const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.components.MuiSubMenuItem.label,
}));
const SubMenuItem = (props) => {
  const {
    active,
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  if (active) {
    if (!!children) {
      return (
        <StyledListItem {...rest} disableGutters>
          <StyledListActiveButton 
            onClick={handleToggle}
            style={style}
          >
            {Icon && <Icon className="icon" />}
            {title}
            {open ? (
              <ExpandLessIcon className="expandIcon" color="inherit" />
            ) : (
              <ExpandMoreIcon className="expandIcon" color="inherit" />
            )}
          </StyledListActiveButton>
          <Collapse in={open}>{children}</Collapse>
        </StyledListItem>
      );
    } else {
      return (
        <StyledListItem {...rest} disableGutters>
          <StyledListActiveButton
            depth={`depth-${depth}`}
            component={CustomRouterLink}
            style={style}
            to={href}
          >
            {Icon && <Icon className="icon" />}
            {title && <StyledTitle>{title}</StyledTitle>}
            {Label && (
              <StyledLabel>
                <Label />
              </StyledLabel>
            )}
          </StyledListActiveButton>
        </StyledListItem>
      );
    }
  }

  if (!!children) {
    return (
      <StyledListItem {...rest} disableGutters>
        <StyledListButton 
          onClick={handleToggle}
          style={style}
        >
          {Icon && <Icon className="icon" />}
          {title}
          {open ? (
            <ExpandLessIcon className="expandIcon" color="inherit" />
          ) : (
            <ExpandMoreIcon className="expandIcon" color="inherit" />
          )}
        </StyledListButton>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  } else {
    return (
      <StyledListItem {...rest} disableGutters>
        <StyledListButton
          // activeClassName="active"
          depth={`depth-${depth}`}
          component={CustomRouterLink}
          style={style}
          to={href}
        >
          {Icon && <Icon className="icon" />}
          {title && <StyledTitle>{title}</StyledTitle>}
          {Label && (
            <StyledLabel>
              <Label />
            </StyledLabel>
          )}
        </StyledListButton>
      </StyledListItem>
    );
  }
};

SubMenuItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

SubMenuItem.defaultProps = {
  active: false,
  depth: 0,
  open: false,
};

export default SubMenuItem;
