/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { ErrorLayout } from './Layout';

export const routes = {
  path: '/errors',
  component: ErrorLayout,
  routes: [
    {
      path: '/errors/error-401',
      exact: true,
      component: lazy(() => import('./Error401')),
    },
    {
      path: '/errors/error-404',
      exact: true,
      component: lazy(() => import('./Error404')),
    },
    {
      path: '/errors/error-500',
      exact: true,
      component: lazy(() => import('./Error500')),
    },
    {
      component: () => <Redirect to="/errors/error-404" />,
    },
  ],
};
