import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// initial state of chat detail
const initialState = {
  state: 'close',
  detail: null,
};

export const show = createAsyncThunk('loading/show', async (payload, thunkAPI) => true);
export const close = createAsyncThunk('loading/close', async (payload, thunkAPI) => true);

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  extraReducers: {
    [show.fulfilled]: (state, action) => {
      return {
        ...state,
        state: 'open',
        detail: action.payload,
      };
    },
    [close.fulfilled]: (state, action) => {
      return {
        ...state,
        state: 'close',
        detail: null,
      };
    },
  },
});

export default loadingSlice.reducer;
