/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { safeSetValue } from '@gvlab/react-lib/utils';

const initialField = {
  changeValue: false,
  dataType: 'text',
  disabled: false,
  readonly: false,
  required: false,
  rows: 1,
};

const initialOptions = {
  actionsAllow: {
    canAdd: true,
    canView: true,
    canEdit: true,
    canDelete: true,
  },
  actions: false,
  fields: false,
  columns: 1,
  columnCount: 12,
  dataSource: false,
  drawerFullSize: false,
  mode: false,

  onCreate: false,
  onCancel: false,
  onCheckDisabled: false,
  onClose: false,
  onDelete: false,
  onUpdate: false,

  onGetValue: false,
  onChange: false,
  onError: false,

  form: {
    variant: 'drawer',
    component: false,
  },
};

const initialState = {
  state: false,
  errors: false,
  fieldsDef: [],
  changes: {},
  options: initialOptions,
};

export const loopListChanges = (state, _list, _value) => {
  const itemValue = [];
  // loop config
  _list.forEach((_item) => {
    let isChange = false;
    if (state.values[_item.dataIndex]) {
      itemValue.push({
        name: _item.dataIndex,
        value: state.values[_item.dataIndex],
      });
      isChange = true;
    }

    // loop existing value
    if (isChange === false && _value) {
      _value
        .find((oldItem) => {
          return _item.dataIndex === oldItem.name;
        })
        .forEach((oldItem) => {
          itemValue.push(oldItem);
        });
    }
  });

  return itemValue;
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    initial: (state, action) => {
      state.state = action.payload.state;
      state.options = safeSetValue(state.options, action.payload.options);
      state.options.columnCount = 12 / state.options.columns;
      // copy of data source
      state.errors = false;

      if (state.options.fields) {
        // id fields define
        let _fieldsDef = {};

        state.options.fields.forEach((_field) => {
          const _value = state.options.dataSource[_field.key];
          _fieldsDef = {
            ..._fieldsDef,
            [_field.key]: {
              ...initialField,
              ..._field,
              value: _value,
            },
          };
        });

        state.fieldsDef = _fieldsDef;
      }
    },
    setActionAllow: (state, action) => {
      state.options.actionsAllow = safeSetValue(state.options.actionsAllow, action.payload);
    },
    setMode: (state, action) => {
      state.state = safeSetValue(state.state, action.payload);
    },
    setErrors: (state, action) => {
      state.errors = safeSetValue(state.errors, action.payload);
    },
    clearChange: (state, action) => {
      state.changes = initialState.changes;
    },
    updateChange: (state, action) => {
      const { field: _field, value: _value } = action.payload;

      const prevFieldDef = {
        ...state.changes,
        [_field.key]: _value,
      };
      state.changes = prevFieldDef;
    },
    updateValue: (state, action) => {
      const { field: _field, value: _value } = action.payload;

      const prevFieldDef = {
        ...state.fieldsDef,
      };

      Object.keys(prevFieldDef)
        .filter((ObjectId) => {
          return ObjectId === _field.key;
        })
        .forEach((ObjectId) => {
          if (prevFieldDef[ObjectId].key === _field.key) {
            const changeValue = _field?.changeValue || _value;
            prevFieldDef[ObjectId] = {
              ...prevFieldDef[ObjectId],
              value: changeValue,
              changeValue,
            };
          }
        });
      state.fieldsDef = prevFieldDef;
    },
  },
});

export const { actions } = formSlice;

export default formSlice.reducer;
