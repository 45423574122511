/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      margin: '2px',
    },
    sizeSmall: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    sizeMedium: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    clickable: {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText,
      '&:hover': {
        color: palette.colors.blueGrey[50],
        backgroundColor: palette.colors.blueGrey[900],
      },
    },
    deletable: {
      '&:focus': {
        backgroundColor: palette.colors.blueGrey[100],
      },
    },
  },
};
