import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import { numberUtils } from '@gvlab/react-lib/utils';
import { Button } from '@gvlab/react-ui/v2';
import {
  Document,
  DocumentHeader,
  DocumentSection,
  DocumentStatus,
  ContentBlock,
  ContentTable,
  TableHeader,
  TableTitleCell,
  TableContentCell,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from 'components/Common/Doc/v1';
import { viewDetail as viewTaskDetail } from 'store/Dashboard/taskSlice';
import { clearDetail, selectOrder, selectOrderState } from 'store/Dashboard/orderSlice';

import { GetStatusDescription } from './Common';
import ActionBar from './ActionBar';

const Invoice = (props) => {
  const dispatch = useDispatch();
  // get task detail from store
  const orderDetail = useSelector(selectOrder);
  const orderState = useSelector(selectOrderState);

  if (orderState.state !== 'DONE') return false;

  // if order detail is not ready, return false
  if (!orderDetail) return false;

  return (
    <Drawer
      fullSize
      onClose={() => dispatch(clearDetail())}
      open
      title={`Invoice - Phase#${orderState?.detail?.id}`}
    >
      <div className={clsx('flex items-stretch', 'flex-col')}>
        <ActionBar />
        <Document>
          <DocumentStatus>{GetStatusDescription(orderDetail.status)}</DocumentStatus>
          <DocumentHeader logo={orderDetail.manager?.companyLogo} title="Invoice" />
          <DocumentSection>
            <Typography size="text-2xl" type="h4">
              {orderDetail.manager?.companyName}
            </Typography>
            <div>
              <Typography size="text-sm">Invoice #{orderDetail?.invoicesId}</Typography>
              <Typography size="text-sm">Ref #{orderDetail.id}</Typography>
            </div>
          </DocumentSection>
          <DocumentSection>
            <p className="text-base">{orderDetail.manager?.address || 'Address here'}</p>
            <Typography size="text-xs">
              Company No. {orderDetail.manager?.companyRoc} <br />
              Tax No. <br />
            </Typography>
            <Typography size="text-xs" align="right">
              Email: {orderDetail.manager?.email} <br />
              Tel: {orderDetail.manager?.phone}
            </Typography>
          </DocumentSection>
          <div className="flex flex-row justify-between items-baseline mt-4 text-black bg-slate-400 px-2 py-4">
            <Typography size="text-sm font-semibold">Date of issue</Typography>
            <Typography size="text-sm">
              {moment(orderDetail.createAt).format('DD MMM YYYY')}
            </Typography>
          </div>
          <DocumentSection>
            <ContentBlock title="Billed to">
              Houzmoni Sdn. Bhd. <br />
            </ContentBlock>
            <ContentBlock title="House Owner">
              {orderDetail.profile.fullName} <br />
              {orderDetail.property.line1} {orderDetail.property.line2} <br />
              {orderDetail.property.postcode} <br />
              {orderDetail.property.city} {orderDetail.property.country} <br />
            </ContentBlock>
          </DocumentSection>
          <DocumentSection>
            <ContentTable>
              <TableHeader>
                <TableTitleCell label="Description" />
                <TableTitleCell />
                <TableTitleCell />
                <TableTitleCell align="right" label="Amount" />
              </TableHeader>
              <TableBody>
                {orderDetail.items.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <span>{product.description}</span>
                    </TableCell>
                    <TableCell />
                    <TableCell align="right" className="uppercase">
                      <Button
                        variant="link"
                        onClick={() => {
                          dispatch(viewTaskDetail({ taskId: product.id }));
                        }}
                      >
                        {product.status}
                      </Button>
                    </TableCell>
                    <TableContentCell>
                      {numberUtils.currencyFormat(product.amount, 0, ' ')}
                    </TableContentCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableTitleCell align="right" label="Sub Total" />
                  <TableContentCell>
                    {numberUtils.currencyFormat(
                      orderDetail?.subtotal || orderDetail.totalAmount,
                      0,
                      ' '
                    )}
                  </TableContentCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableTitleCell align="right" label="Fee" />
                  <TableContentCell>
                    {numberUtils.currencyFormat(orderDetail?.taxes || 0, 0, ' ')}
                  </TableContentCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableTitleCell align="right" label="Total" />
                  <TableContentCell>
                    {numberUtils.currencyFormat(orderDetail.totalAmount, 0, ' ')}
                  </TableContentCell>
                </TableRow>
              </TableBody>
            </ContentTable>
          </DocumentSection>
          {/* <DocumentFooter>
            <Typography size="text-base font-semibold">Notes</Typography>
            <Typography size="text-xs">
              Please make sure you have the right bank registration number as I had issues before
              and make sure you guys cover transfer expenses.
            </Typography>
          </DocumentFooter> */}
        </Document>
      </div>
    </Drawer>
  );
};

export default Invoice;
