/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux';
import {
  isFunction,
} from '@gvlab/react-lib/utils';
import { closeDialog } from './store/dialogSlice';
import { CardSessionExpired } from '../CardSessionExpired';
import Dialog from './Dialog';

const DialogCritical = () => {
  const dispatch = useDispatch();
  const dialog = useSelector(({ gv }) => gv.dialog);

  const handleClose = () => {
    if (closeDialog && isFunction(closeDialog)) dispatch(closeDialog());
  };

  if (!dialog.state || dialog.options.type !== 'DialogCritical') return null;

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={dialog.state}>
      <CardSessionExpired onClose={handleClose} />
    </Dialog>
  );
};

export default DialogCritical;
