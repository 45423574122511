/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Alert component with icon, title, and description
const Alert = forwardRef((props, ref) => {
  const {
    icon,
    variant,
    color,
    severity,
    onClick,
  } = props;

  const defaultIconMapping = {
    info: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>,
    success: <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>,
    warning: <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>,
    error: <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>,
  };

  return (
    <div className={clsx("alert", `alert-${severity}`, "shadow-lg")} ref={ref}>
      <div>
        {/* {icon || defaultIconMapping[severity]} */}
        <div>{props.children}</div>
      </div>
      <div className="flex-none">
        {onClick && (
        <button className="btn btn-circle btn-outline" onClick={onClick}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
        </button>)}
      </div>
    </div>
  );
});
Alert.displayName = 'Alert';

Alert.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  icon: PropTypes.node,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

export default Alert;
