import { RoundBoxCustom } from './Box';

export const RoundBox = ({ className, children, label, ...rest }) => {
  return (
    <RoundBoxCustom className={`outline-default ${className}`}>
      {label} {children}
    </RoundBoxCustom>
  );
};

export const RoundBoxBasic = ({ className, children, label, ...rest }) => {
  return (
    <RoundBoxCustom className={`outline-basic ${className}`}>
      {label} {children}
    </RoundBoxCustom>
  );
};

export const RoundBoxInfo = ({ className, children, label, ...rest }) => {
  return (
    <RoundBoxCustom className={`outline-info ${className}`}>
      {label} {children}
    </RoundBoxCustom>
  );
};

export const RoundBoxSuccess = ({ className, children, label, ...rest }) => {
  return (
    <RoundBoxCustom className={`outline-success ${className}`}>
      {label} {children}
    </RoundBoxCustom>
  );
};

export const RoundBoxError = ({ className, children, label, ...rest }) => {
  return (
    <RoundBoxCustom className={`outline-error ${className}`}>
      {label} {children}
    </RoundBoxCustom>
  );
};

export const RoundBoxWarning = ({ className, children, label, ...rest }) => {
  return (
    <RoundBoxCustom className={`outline-warning ${className}`}>
      {label} {children}
    </RoundBoxCustom>
  );
};
