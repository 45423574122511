/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import * as reactRouter from 'react-router-dom';

// Render routes with react-router-dom v5
function RenderRoutes({ paths: routes, extra, switchs, ...rest }) {
  const extraProps = extra || {};
  const switchProps = switchs || {};

  // react-router-dom v5
  const GenerateRouteObjectV5 = (_routes) => {
    return _routes
      ? React.createElement(
          reactRouter.Switch,
          switchProps,
          _routes.map((route, i) => {
            console.log(route);
            return React.createElement(reactRouter.Route, {
              key: route.key || i,
              path: route.path,
              exact: route.exact,
              strict: route.strict,
              render: (props) => {
                return route.render
                  ? route.render({ ...props, ...extraProps, route })
                  : React.createElement(route.component, {
                      ...props,
                      ...extraProps,
                      route,
                    });
              },
            });
          }),
        )
      : null;
  };

  return GenerateRouteObjectV5(routes);
}

// Render routes with react-router-dom v4
function renderRoutes2(routes, extra, switchs) {
  const extraProps = extra || {};
  const switchProps = switchs || {};

  return routes
    ? React.createElement(
        reactRouter.Switch,
        switchProps,
        routes.map((route, i) => {
          return React.createElement(reactRouter.Route, {
            key: route.key || i,
            path: route.path,
            exact: route.exact,
            strict: route.strict,
            render: function render(props) {
              return route.render
                ? route.render({ ...props, ...extraProps, route })
                : React.createElement(route.component, {
                    ...props,
                    ...extraProps,
                    route,
                  });
            },
          });
        }),
      )
    : null;
}

// HOC for render routes
const Routes = ({ paths, extra, switchs }) => {
  return <Suspense fallback={<>Loading</>}>{renderRoutes2(paths, extra, switchs)}</Suspense>;
};

Routes.propTypes = {
  paths: PropTypes.array,
  extra: PropTypes.object,
  switchs: PropTypes.object,
};

Routes.defaultProps = {
  paths: [],
  extra: {},
  switchs: {},
};

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
const RouteWithSubRoutes = (route) => {
  return (
    <reactRouter.Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
};

// render sub-routes with react-router-dom v5
const RenderRoutesWithSubRoutes = ({ routes }) => {
  return (
    <reactRouter.Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </reactRouter.Switch>
  );
};

export { RenderRoutes, Routes, RouteWithSubRoutes, RenderRoutesWithSubRoutes };
