import { createSlice } from '@reduxjs/toolkit';

const authMenusSlice = createSlice({
  name: 'auth',
  initialState: {
    state: false,
    error: false,
    menus: [],
  },
  reducers: {
    init: (state, action) => {
      state.state = false;
      state.menus = [];
    },
    setMenu: (state, action) => {
      state.state = 'DONE';
      state.menus = action.payload;
    },
  },
});

export const { init, setMenu } = authMenusSlice.actions;

export default authMenusSlice.reducer;
