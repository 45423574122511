import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { dateUtils, numberUtils } from '@gvlab/react-lib/utils';
import { Button } from '@gvlab/react-ui/v2';
import Drawer from '@gvlab/react-ui/v2/Drawer';

import {
  Document,
  DocumentHeader,
  DocumentSection,
  DocumentStatus,
  DocumentFooter,
  ContentBlock,
  ContentTable,
  TableTitleCell,
  TableContentCell,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from 'components/Common/Doc/v1';
import { selectQuotationState, clearDetail } from 'store/Dashboard/quotationDetailSlice';
import { viewDetail as viewOrderDetail } from 'store/Dashboard/orderSlice';

import { GetStatusDescription } from './Common';

const QuotationDetail = (props) => {
  const dispatch = useDispatch();
  const { detail: quote, ...quoteState } = useSelector(selectQuotationState);

  if (quoteState.state !== 'DONE') return false;
  if (!quote) return false;

  if (quote.status === 'draft') return false;

  let subtotal = 0;

  return (
    <Drawer fullSize open onClose={() => dispatch(clearDetail())} title="View Quotation">
      <Document>
        <DocumentStatus>{GetStatusDescription(quote.status)}</DocumentStatus>
        <DocumentHeader logo={quote.manager?.companyLogo} title="Quotation" subtitle="xxxx" />
        <DocumentSection key="section-vendor">
          <Typography size="text-2xl" type="h4">
            {quote.manager?.companyName}
          </Typography>
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <span>Quotation #</span>
              <span className="uppercase">
                {quote.id} {quote.id.split('-').pop()}
              </span>
            </li>
            <li className="flex items-start justify-between">
              <span>Ref #</span>
              <span className="uppercase">{quote.ref || ''}</span>
            </li>
          </ul>
        </DocumentSection>
        <DocumentSection key="section-company">
          <p className="text-base">{quote.manager?.address || 'Address here'}</p>
          <Typography size="text-xs">
            Company No. {quote.manager?.companyRoc} <br />
            Tax No. <br />
          </Typography>
          <Typography size="text-xs" align="right">
            Email: {quote.manager?.email} <br />
            Tel: {quote.manager?.phone}
          </Typography>
        </DocumentSection>
        <div className="flex flex-row justify-between items-baseline mt-4 text-black bg-slate-400 px-2 py-4">
          <Typography size="text-sm font-semibold">Due date</Typography>
          <Typography size="text-sm">{moment(dateUtils.today()).format('DD MMM YYYY')}</Typography>
          <Typography size="text-sm font-semibold">Date of issue</Typography>
          <Typography size="text-sm">{moment(quote.sentAt).format('DD MMM YYYY')}</Typography>
        </div>
        <DocumentSection key="section-customer">
          <ContentBlock title="Customer">
            {quote.owner_fullname} <br />
            {quote.property_line1} {quote.property_line2} <br />
            {quote.property_postcode} <br />
            {quote.property_city} {quote.property_country} <br />
          </ContentBlock>
        </DocumentSection>
        <DocumentSection key="section-content">
          <ContentTable>
            {quote.phases.map((phase) => (
              <TableBody key={`phase-description-${phase.groupId}`}>
                <TableRow>
                  <TableTitleCell
                    label={
                      <>
                        {`Phase # ${phase.groupId} `} <br />
                        Description
                      </>
                    }
                  />
                  <TableTitleCell />
                  <TableTitleCell align="right" label="Amount" />
                </TableRow>
                {phase.items.map((itemIdx) => {
                  subtotal += quote.items[itemIdx].amount;
                  return (
                    <TableRow key={`phase-lines-${quote.items[itemIdx].idx}`}>
                      <TableCell>{quote.items[itemIdx].subject}</TableCell>
                      <TableCell />
                      <TableContentCell>
                        {numberUtils.currencyFormat(quote.items[itemIdx].amount)}
                      </TableContentCell>
                    </TableRow>
                  );
                })}
                <TableRow key={`phase-subtotal-${phase.groupId}`}>
                  <TableTitleCell />
                  <TableTitleCell align="right" label="Sub Total" />
                  <TableContentCell>{numberUtils.currencyFormat(phase.total)}</TableContentCell>
                </TableRow>
                {phase?.order && phase?.order.length > 0 && (
                  <TableRow key={`phase-summary-${phase.groupId}`}>
                    <TableContentCell />
                    <TableTitleCell>
                      <Typography size="text-base font-semibold " align="right">
                        Status
                      </Typography>
                      <Typography size="text-xs uppercase" align="right">
                        {phase.order[0].status}
                      </Typography>
                    </TableTitleCell>
                    <TableTitleCell align="right">
                      <Typography size="text-base font-semibold" align="right">
                        Invoice
                      </Typography>
                      <Button
                        variant="link"
                        onClick={() => {
                          const order = phase.order[0];
                          dispatch(viewOrderDetail({ d: phase.groupId, orderId: order.id }));
                        }}
                        size="sm"
                      >
                        <Typography size="text-xs" align="right">
                          {phase.order[0].invoicesId || 'PENDING'}
                        </Typography>
                      </Button>
                    </TableTitleCell>
                  </TableRow>
                )}
              </TableBody>
            ))}
            <TableBody>
              <TableRow>
                <TableTitleCell />
                <TableTitleCell align="right" label="Grand Total" />
                <TableContentCell>{numberUtils.currencyFormat(subtotal, ' ')}</TableContentCell>
              </TableRow>
            </TableBody>
          </ContentTable>
        </DocumentSection>
        <DocumentFooter>
          {/* <Typography size="text-base font-semibold">Notes</Typography> */}
          {/* <Typography size="text-xs">
            Please make sure you have the right bank registration number as I had issues before and
            make sure you guys cover transfer expenses.
          </Typography> */}
        </DocumentFooter>
      </Document>
    </Drawer>
  );
};

export default QuotationDetail;
