/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  defaultProps: {
    titleTypographyProps: {
      variant: 'h6',
    },
    subheaderTypographyProps: {
      variant: 'body2',
    },
  },
  styleOverrides: {
    root: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
      padding: '16px',
      '& .MuiCardHeader-root': {
        fontSize: '14px',
      },
      '& .MuiCardHeader-action': {
        margin: '0',
        '& .MuiIconButton-root': {
          padding: '8px',
        },
      },
    },
  },
};
