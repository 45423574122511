import MuiButton from './MuiButton';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiChip from './MuiChip';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiFilledInput from './MuiFilledInput';
import MuiListItem from './MuiListItem';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiTableHead from './MuiTableHead';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTablePagination from './MuiTablePagination';
import MuiToggleButton from './MuiToggleButton';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTypography from './MuiTypography';
import MuiAppBar from './MuiAppBar';
// import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiSubMenuItem from './MuiSubMenuItem';

import MuiTabs from './MuiTabs';
import MuiStepper from './MuiStepper';
import MuiStepperLabel from './MuiStepperLabel';
import MuiButtonBase from './MuiButtonBase';
import MuiCssBaseline from './MuiCssBaseline';

const StyledComponent = {
  MuiCssBaseline,
  MuiButtonBase,
  MuiAppBar,
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
  MuiFilledInput,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItem,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiTableHead,
  MuiTableCell,
  MuiTableRow,
  MuiTableSortLabel,
  MuiTablePagination,
  MuiToggleButton,
  MuiTypography,
  // MuiMenu,
  MuiMenuItem,
  MuiSubMenuItem,
  MuiTabs,
  MuiStepper,
  MuiStepperLabel,
  // TextField: {
  //   fullWidth: true,
  //   margin: 'dense',
  //   variant: 'filled', //outlined, filled
  // },
};

export default StyledComponent;
