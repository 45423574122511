/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Delete as DeleteIcon } from '@gvlab/react-icons';
import { Button } from '@gvlab/react-ui/v2';
import { updateDetail, create } from 'store/Dashboard/quotationDraftSlice';

export const ActionBar = (props) => {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  if (getValues('id') === undefined) {
    return (
      <div className="bg-gray-50 px-4 py-4 mt-4 flex gap-x-1 justify-end">
        <Button
          onClick={(event) => {
            const request = getValues();
            return dispatch(
              create({
                request: {
                  ...request,
                  status: 'draft',
                },
              })
            );
          }}
        >
          Save as draft
        </Button>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 px-4 py-4 mt-4 flex gap-x-1 justify-end">
      <Button
        color="error"
        onClick={(event) => {
          const request = getValues();
          return dispatch(
            updateDetail({
              quotationId: request?.id,
              request: {
                ...request,
                status: 'cancel',
              },
            })
          );
        }}
      >
        <DeleteIcon />
        Delete
      </Button>
      <Button
        onClick={(event) => {
          const request = getValues();
          return dispatch(
            updateDetail({
              quotationId: request?.id,
              request: {
                ...request,
                status: 'draft',
              },
            })
          );
        }}
      >
        Save as draft
      </Button>
      <Button
        color="info"
        onClick={(event) => {
          const request = getValues();
          return dispatch(
            updateDetail({
              quotationId: request?.id,
              request: {
                ...request,
                status: 'confirm',
              },
            })
          );
        }}
      >
        Confirm and sent
      </Button>
    </div>
  );
};
