import { IconButton } from '@gvlab/react-theme/mui';
import { Close as CloseIcon } from '@gvlab/react-icons';
import { makeStyles } from '@gvlab/react-theme/styles';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    fontSize: '14px',
    color: 'white',
    marginRight: theme.spacing(1),
  },
}));

const LHSPanel = (props) => {
  const classes = useStyles();
  const {onClose, ...rest} = props;

  return (
    <IconButton className={classes.buttonIcon} onClick={onClose} {...rest}>
      <CloseIcon />
    </IconButton>
  );
};

export default LHSPanel;
