/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

const StyledComponent = {
  styleOverrides: {
    root: {
      borderRadius: 3,
      overflow: 'hidden',
    },
    colorPrimary: {
      backgroundColor: palette.colors.blueGrey[50],
    },
  },
};

export default StyledComponent;
