/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/display-name */
import { useState, forwardRef } from 'react';
import { Link as RouterLink } from '@gvlab/react-router';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import { ListItem, Collapse } from '@gvlab/react-theme/mui';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@gvlab/react-icons';
import { MenuButton, MenuButtonActive } from '../Button';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ...theme.components.MuiSubMenuItem.itemLeaf,
  fontSize: '1rem',
  '& .icon': {
    width: '18px'
  },
}));
const ListIcon = ({icon: Icon}) => (<Icon width="12" className="icon" />);


const StyledTitle = styled('p')(({ theme }) => ({
  marginLeft: theme.spacing(1), 
}));
const MenuLabel = (props) => (<span className="text=sm" {...props}/>);

const StyledLabel = styled(MenuLabel)(({ theme }) => ({
  ...theme.components.MuiSubMenuItem.label,
}));
const SubMenuItem = (props) => {
  const {
    active,
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

 

  const handleToggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  if (active) {
    if (children) {
      return (
        <StyledListItem className="bg-gray-100 text-gray-900 text-sm" {...rest} disableGutters>
          <MenuButtonActive 
            style={style}
            className="w-full flex items-center justify-start	"
            onClick={handleToggle}
          >
            {Icon && <ListIcon icon={Icon} />}
            {title}
            {open ? (
              <ExpandLessIcon className="expandIcon" color="inherit" />
            ) : (
              <ExpandMoreIcon className="expandIcon" color="inherit" />
            )}
          </MenuButtonActive>
          <Collapse in={open}>{children}</Collapse>
        </StyledListItem>
      );
    } 
      return (
        <StyledListItem className="bg-gray-100 text-gray-900 text-sm" {...rest} disableGutters>
          <MenuButtonActive
            className="w-full flex items-center justify-start	"
            depth={`depth-${depth}`}
            component={CustomRouterLink}
            style={style}
            to={href}
          >
            {Icon && <ListIcon icon={Icon} />}
            {title && <span className="ml-1">{title}</span>}
            {Label && (
              <StyledLabel>
                <Label />
              </StyledLabel>
            )}
          </MenuButtonActive>
        </StyledListItem>
      );
    
  }

  if (children) {
    return (
      <StyledListItem className="bg-gray-100 text-gray-900 text-sm" {...rest} disableGutters>
        <MenuButton 
          className="w-full flex items-center justify-start	"
          onClick={handleToggle}
          style={style}
        >
          {Icon && <ListIcon />}
          {title}
          {open ? (
            <ExpandLessIcon className="expandIcon" color="inherit" />
          ) : (
            <ExpandMoreIcon className="expandIcon" color="inherit" />
          )}
        </MenuButton>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem className="bg-gray-100 text-gray-900 text-sm" {...rest} disableGutters>
      <MenuButton
        className="w-full flex items-center justify-start	"
        // activeClassName="active"
        depth={`depth-${depth}`}
        component={CustomRouterLink}
        style={style}
        to={href}
      >
        {Icon && <ListIcon icon={Icon} />}
        {title && <span className="ml-1">{title}</span>}
        {Label && (
          <StyledLabel>
            <Label />
          </StyledLabel>
        )}
      </MenuButton>
    </StyledListItem>
  );
  
};

SubMenuItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

SubMenuItem.defaultProps = {
  active: false,
  depth: 0,
  open: false,
};

export default SubMenuItem;
