import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

export const getChat = createAsyncThunk(
  'houz/chat/getChat',
  async ({ ordersId, token, host }, { dispatch, getState }) => {
    const response = await Requestly().request({
      method: 'get',
      url: `/user/v1/orders/${ordersId}/comments?userType=pro`,
    });

    const data = await response.data;

    return data;
  }
);

export const sendMessage = createAsyncThunk(
  'houz/chat/sendMessage',
  async ({ messageText, ordersId, media }, { dispatch, getState }) => {
    const response = await Requestly().request({
      method: 'post',
      url: `/user/v1/orders/${ordersId}/comment?userType=pro`,
      params: {
        content: messageText,
        media,
      },
    });

    const data = await response.data;

    return data;
  }
);

// initial state of chat detail
const initialState = {
  state: 'PENDING',
  success: false,
  errors: false,
  detail: null,
};

const chatSlice = createSlice({
  name: 'houz/chat',
  initialState: [],
  extraReducers: {
    [getChat.fulfilled]: (state, action) => {
      return {
        ...initialState,
        detail: action.payload,
      };
    },
    [sendMessage.fulfilled]: (state, action) => {
      return {
        ...state,
        detail: action.payload,
      };
    },
  },
});

export const selectChat = ({ app }) => app.dashboard.chat;

export default chatSlice.reducer;
