/* eslint-disable import/prefer-default-export */

// GetStatusDescription Get status label
export const GetStatusDescription = (status) => {
  if (status === 'confirm') {
    return 'pending';
  }
  if (status === 'cancel') {
    return 'canceled';
  }
  if (status === 'accept') {
    return 'accepted';
  }
  if (status === 'reject') {
    return 'rejected';
  }
  if (status === 'done') {
    return 'done';
  }
  if (status === 'payment-reject') {
    return 'payment';
  }
  return status;
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};
