/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import SpaceUploader from '@gvlab/react-ui/v2/SpaceUploader';

import { uploadMedia } from 'store/Dashboard/mediaSlice';
import { clearDetail, selectPaymentState } from 'store/Dashboard/paymentSlice';
import { selectUser } from 'store/Dashboard/userSlice';
import { selectWalletBalance } from 'store/Dashboard/walletBalanceSlice';

import { FormField } from 'components/Common/FormField';
import { FormBase } from 'components/Common/FormBase';
import { ActionBar } from './ActionsBar';

const Reload = (props) => {
  const dispatch = useDispatch();
  const paymentState = useSelector(selectPaymentState);
  const walletBalance = useSelector(selectWalletBalance);
  const user = useSelector(selectUser);

  const handleUploadFile = (params) => {
    const { file } = params;

    dispatch(
      uploadMedia({
        media: file,
      })
    );
  };

  if (!paymentState || !user) return null;

  if (paymentState.state === 'INIT' || paymentState.state === 'DONE') return null;

  const values = {
    amount: 0,
    bank_name: '',
    location: '',
    date: '',
    time: '',
    receipt: '',
    reference: '',
  };

  return (
    <Drawer fullSize={false} onClose={() => dispatch(clearDetail())} open title="Load Funds">
      <FormBase values={values}>
        <div className="flex flex-col w-full h-full items-center	content-center	justify-center	">
          <div className="flex flex-col max-w-md p-6 space-y-4 divide-y sm:w-96 sm:p-10 divide-gray-300 bg-gray-50 text-gray-800">
            <ul className="flex flex-col pt-4 space-y-2">
              <li className="flex items-start justify-between">
                <h2 className="text-xl font-semibold uppercase">Account detail</h2>
              </li>
              <li className="flex items-start justify-between">
                <span>Name</span>
                <h3 className="text-lg ">{user.detail.fullName}</h3>
              </li>
              <li className="flex items-start justify-between">
                <span>Id</span>
                <span className="uppercase">{user.detail.peoplesId}</span>
              </li>
            </ul>
            <div className="pt-4 space-y-2">
              <div className="flex justify-between">
                <span>Pay By</span>
              </div>
              <div className="flex flex-col">
                <span>Amount</span>
                <span>
                  <FormField
                    id="amount"
                    type="number"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={paymentState.errors}
                  />
                </span>
              </div>
              <div className="flex flex-col">
                <span>Bank name</span>
                <span>
                  <FormField
                    id="bank_name"
                    type="text"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={paymentState.errors}
                  />
                </span>
              </div>
              <div className="flex flex-col">
                <span>Location</span>
                <span>
                  <FormField
                    id="location"
                    type="text"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={paymentState.errors}
                  />
                </span>
              </div>
              <div className="flex flex-col">
                <span>Reference</span>
                <span>
                  <FormField
                    id="reference"
                    type="text"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={paymentState.errors}
                  />
                </span>
              </div>

              <div className="flex flex-col">
                <span>Date / Time</span>
                <span>
                  <FormField
                    id="date"
                    type="date"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={paymentState.errors}
                  />
                  <FormField
                    id="time"
                    type="time"
                    placeholder=""
                    className="w-full p-3 rounded bg-gray-100"
                    errors={paymentState.errors}
                  />
                </span>
              </div>

              <div className="flex flex-col">
                <span>Receipt</span>
                <SpaceUploader onFileUpload={handleUploadFile} accept="image/*" />
              </div>
            </div>
            <ActionBar {...props} />
            {paymentState.errors && paymentState.errors?.message && (
              <div className="alert alert-error">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>{paymentState.errors.message}</span>
              </div>
            )}
          </div>
        </div>
      </FormBase>
    </Drawer>
  );
};

export default Reload;
