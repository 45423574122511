/* eslint-disable import/no-extraneous-dependencies */
import { makeStyles, darken } from '@gvlab/react-theme/styles';
import { Chip, Paper, MenuItem } from '@gvlab/react-theme/mui';
import clsx from 'clsx';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Placeholder from './components/Placeholder';
import SingleValue from './components/SingleValue';
import NoOptionsMessage from './components/NoOptionsMessage';
import TextFieldControl from './components/TextFieldControl';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .gv-chip-select__input': {
      color: theme.palette.text.primary,
    },
    '&.standard': {
      '& $placeholder': {},
      '& $valueContainer': {
        paddingTop: 4,
      },
    },
    '&.filled': {
      '& $placeholder': {
        left: 12,
      },
      '& $valueContainer': {
        paddingTop: 24,
        paddingLeft: 12,
      },
      '& $chip': {
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    '&.outlined': {
      '& $placeholder': {
        left: 12,
      },
      '& $valueContainer': {
        paddingLeft: 12,
        paddingTop: 12,
      },
    },
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    minHeight: 56,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    paddingBottom: 4,
    paddingTop: 12,
    minHeight: 40,
  },
  chip: {
    // margin: '4px 4px 4px 0'
    margin: theme.spacing(0, 1, 0, 0),
  },
  chipFocused: {
    backgroundColor: darken(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 0,
    fontSize: 16,
    margin: 0,
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(),
    bottom: 0,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 600 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const ValueContainer = (props) => {
  const classes = useStyles();

  return <div className={classes.valueContainer}>{props.children}</div>;
}

const MultiValue = (props) => {
  const classes = useStyles();
  const { isFocused } = props;

  return (
    <Chip
      clickable
      tabIndex={-1}
      label={props.children}
      className={clsx(
        classes.chip,
        {
          [classes.chipFocused]: isFocused,
        },
        props.data.class,
      )}
      // onDelete={event => {
      // 	props.removeProps.onClick();
      // 	props.removeProps.onMouseDown(event);
      // }}
      color="primary"
    />
  );
}

const Menu = (props) => {
  const classes = useStyles();
  const { innerProps } = props;

  return (
    <Paper square className={classes.paper} {...innerProps}>
      {props.children}
    </Paper>
  );
}

const customComponents = {
  TextFieldControl,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const FuseChipSelect = (props) => {
  const handleOnChange = (value) => {
    if (value === null) {
      value = [];
    }
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return props.variant === 'fixed' ? (
    <Select
      classNamePrefix="gv-chip-select"
      {...props}
      components={customComponents}
      onChange={handleOnChange}
    />
  ) : (
    <Creatable
      classNamePrefix="gv-chip-select"
      {...props}
      components={customComponents}
      onChange={handleOnChange}
    />
  );
}

export default FuseChipSelect;
