/* eslint-disable import/no-anonymous-default-export */

export default {
  styleOverrides: {
    root: {
      padding: '32px 24px',
      '&:last-child': {
        paddingBottom: '32px',
      },
    },
  },
};
