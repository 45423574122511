/* eslint-disable consistent-return */
import { isObject } from './common';

const objectByString = (dataSource, dataPath) => {
  dataPath = dataPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  dataPath = dataPath.replace(/^\./, ''); // strip a leading dot
  const a = dataPath.split('.');
  let dataObject = dataSource;
  for (let i = 0, n = a.length; i < n; i += 1) {
    const k = a[i];
    if (isObject(dataObject)) {
      if (k in dataObject) {
        dataObject = dataObject[k];
      }
    }
  }
  return dataObject;
};

export default objectByString;
