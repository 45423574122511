/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/named */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Routes } from '@gvlab/react-router';
import { styled, Typography, gradients } from '@gvlab/react-theme/styles';
import { Card, Divider } from '@gvlab/react-theme/mui';
import { useDialogContext } from '@gvlab/react-create-app-gv';
import { GridContainer, GridItem } from '@gvlab/react-ui/components';
import { selectGvDialog } from '@gvlab/react-create-app-gv/stores';

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundImage: gradients.secondary,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6, 2),
}));
const StyledCard = styled(Card)(({ theme }) => ({
  width: theme.breakpoints.values.md,
  maxWidth: '100%',
  display: 'flex',
  position: 'relative',
  '& > *': {
    flexGrow: 1,
    flexBasis: '50%',
    width: '50%',
  },
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
const StyledAvatar = styled('img')(({ theme }) => ({
  width: '15vh',
  height: 'auto',
  mixBlendMode: 'color-dodge',
  filter: 'invert(1)',
}));
const StyledGridContainer = styled(GridContainer)(({ theme }) => ({
  margin: 0,
}));
const StyledMedia = styled(GridItem)(({ theme }) => ({
  backgroundImage: gradients.primary,
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  alignItems: 'stretch',
  minHeight: '495px',
  '@media only screen and (max-width: 600px)': {
    display: 'none',
  },
}));

const Auth = ({ route, title, description }) => {
  const dialogState = useSelector(selectGvDialog);

  const dialog = useDialogContext();
  useEffect(() => {
    if (dialogState.state) dialog.clear();
  }, [dialogState]);

  return (
    <StyledContainer>
      <StyledCard>
        <StyledGridContainer>
          <StyledMedia xs={6}>
            <GridContainer spacing={0}>
              <GridItem xs={12}>
                <Typography variant="h2" paragraph>
                  {title}
                </Typography>
              </GridItem>
              <GridItem xs={12}>
                <Typography variant="subtitle2" paragraph>
                  {description}
                </Typography>
              </GridItem>
              <GridItem xs={12}>
                <StyledAvatar alt="logo" src="/images/logos/large.png" variant="rounded" />
              </GridItem>
            </GridContainer>
          </StyledMedia>
          <GridItem sm={6} xs={12}>
            <Routes paths={route.routes} />
          </GridItem>
        </StyledGridContainer>
      </StyledCard>
    </StyledContainer>
  );
};

Auth.propTypes = {
  route: PropTypes.object,
};

export default Auth;
