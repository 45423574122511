/* eslint-disable import/prefer-default-export */
import { InputLabel } from '@gvlab/react-theme/mui';
import { BlockFieldsetLR } from 'components/Common/BlockFieldsetLR';

import { FormField } from 'components/Common/FormField';

const propertyType = [
  'Flat',
  'Apartment',
  'Condominium',
  'Service Apartment',
  'Loft',
  'Duplex',
  'Penthouse',
  'Soho',
  'SoVo',
  'SoFo',
  'Shop House',
  'Shop Lot',
  'Terrace',
  'Superlink',
  'Townhouse',
];

const countries = ['Malaysia', 'Singapore', 'Brunei', 'Indonesia', 'Thailand'];

export const Property = ({ formState = 'add' }) => (
  <BlockFieldsetLR title="Site Of Work" subtitle="Property address" className="p-6 bg-white">
    <div className="col-span-6">
      <InputLabel htmlFor="property-name" className="block text-sm font-medium text-gray-700">
        Property Name
      </InputLabel>
      <FormField
        type="text"
        name="property_description"
        id="property_description"
        autoComplete="property_description"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
    <div className="col-span-6">
      <InputLabel className="block text-sm font-medium text-gray-700">Street address</InputLabel>
      <FormField
        type="text"
        name="property_line1"
        id="property_line1"
        autoComplete="property_line1"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
    <div className="col-span-6">
      <FormField
        type="text"
        name="property_line2"
        id="property_line2"
        autoComplete="property_line2"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
    <div className="col-span-6 sm:col-span-4">
      <InputLabel htmlFor="property-type" className="block text-sm font-medium text-gray-700">
        Property Type
      </InputLabel>
      <FormField
        id="property_type"
        select
        // eslint-disable-next-line react/jsx-sort-props
        SelectProps={{ native: true }}
        formState={formState}
      >
        {propertyType.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </FormField>
    </div>
    <div className="col-span-6 sm:col-span-3">
      <InputLabel htmlFor="property_country" className="block text-sm font-medium text-gray-700">
        Country
      </InputLabel>
      <FormField
        id="property_country"
        name="property_country"
        autoComplete="property_country"
        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        select
        SelectProps={{ native: true }}
        formState={formState}
      >
        {countries.map((option) => (
          <option key={option} value={option.toLowerCase()}>
            {option}
          </option>
        ))}
      </FormField>
    </div>
    <div className="col-span-6 sm:col-span-3">
      <InputLabel htmlFor="city" className="block text-sm font-medium text-gray-700">
        City
      </InputLabel>
      <FormField
        type="text"
        name="property_city"
        id="property_city"
        autoComplete="property_city"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
    <div className="col-span-6 sm:col-span-3">
      <InputLabel htmlFor="property_state" className="block text-sm font-medium text-gray-700">
        State / Province
      </InputLabel>
      <FormField
        type="text"
        name="property_state"
        id="property_state"
        autoComplete="property_state"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
    <div className="col-span-6 sm:col-span-3">
      <InputLabel htmlFor="property_postcode" className="block text-sm font-medium text-gray-700">
        ZIP / Postal code
      </InputLabel>
      <FormField
        type="text"
        name="property_postcode"
        id="property_postcode"
        autoComplete="property_postcode"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        formState={formState}
      />
    </div>
  </BlockFieldsetLR>
);
