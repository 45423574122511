/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';

import { Alert } from '@gvlab/react-theme/mui';
import { GridItem } from '@gvlab/react-ui/components';

const FormTopAlertMessage = () => {
  const { errors } = useSelector(({ app }) => ({
    errors: app.form.props.errors,
  }));

  const errorMessage = 'This is an error alert — check it out!';

  if (!errors) return null;

  return (
    <GridItem xs={12}>
      <Alert severity="error">{errorMessage}</Alert>
    </GridItem>
  );
};

export default FormTopAlertMessage;
