/* eslint-disable no-useless-catch */
/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux';
import {
  insertDataStore,
  updateDataStore,
  resetDataStore,
  setDataStore,

  setDataStoreState,

  create,
  read,
  update,
  remove,
} from '../store/datastoreSlice';

// Arrow func class
const useReduxDataset = (tableName) => {
  const dispatch = useDispatch();
  const { dataStore, data, meta, state } = useSelector(({ app }) => ({
    dataStore: app.datastore.collections?.[tableName],
    data: app.datastore.collections?.[tableName]?.data,
    meta: app.datastore.collections?.[tableName]?.meta,
    state: app.datastore.collections?.[tableName]?.state,
  }));

  return {
    dataStore,
    data: () => data,
    meta: () => meta,
    setDataStore: (value) => dispatch(setDataStore({ dataSource: tableName, value })),
    getDataStore: () => dataStore,

    initDataStore: () => dispatch(resetDataStore({ dataSource: tableName })),
    resetDataStore: () => dispatch(resetDataStore({ dataSource: tableName })),

    getDataStoreState: () => dataStore?.state || 'PENDING',
    setDataStoreState: (value) =>
      dispatch(setDataStoreState({ dataSource: tableName, state: value })),

    insertDataStore: (value) => dispatch(insertDataStore({ dataSource: tableName, value })),
    editDataStore: (value, cbCheck) =>
      dispatch(updateDataStore({ dataSource: tableName, value, cbCheck })),


    create: ({ method, url, params, cbCheck }) => dispatch(create({ dataSource: tableName, method, url, params, cbCheck })),
    read: ({ method, url, params, cbCheck }) => dispatch(read({ dataSource: tableName, method, url, params, cbCheck })),
    update: ({ method, url, params, cbCheck }) => dispatch(update({ dataSource: tableName, method, url, params, cbCheck })),
    remove: ({ method, url, params, cbCheck }) => dispatch(remove({ dataSource: tableName, method, url, params, cbCheck })),
  };
};

// Hook Method
const useReduxDatasetHook = (tableName) => {
  const dataset = useReduxDataset(tableName);

  return [...dataset];
};

export { useReduxDatasetHook, useReduxDataset };
