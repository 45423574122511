/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
// chat content component
import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parseISO from 'date-fns/parseISO';
import { useDispatch, useSelector } from 'react-redux';

import { selectedChats, selectedChatsState } from 'store/Dashboard/chatsSlice';
import { selectUser } from 'store/Dashboard/userSlice';

import { show } from 'store/lightBoxSlice';

const ChatContent = () => {
  const dispatch = useDispatch();
  const chatRef = useRef(null);
  const chats = useSelector(selectedChats);
  const user = useSelector(selectUser);
  const chatsState = useSelector(selectedChatsState);

  useEffect(() => {
    if (chats) {
      setTimeout(scrollToBottom);
    }
  }, [chats]);

  function scrollToBottom() {
    if (!chatRef.current) {
      return;
    }
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }

  return (
    <div className="flex flex-1 flex-col p-4 overflow-y-auto" ref={chatRef}>
      {/* <Scrollbars ref={chatRef} className="flex flex-1 flex-col overflow-y-auto"> */}
      {chats?.length > 0 && (
        <>
          {chats
            .slice(0)
            .reverse()
            .map((item, i) => {
              return (
                <div
                  className={clsx(
                    'chat text-black ',
                    item.createBy !== user.detail.id ? ' chat-start' : 'chat-end'
                  )}
                  key={item.id}
                >
                  <div
                    className={clsx(
                      'chat-bubble',
                      item.createBy !== user.detail.id
                        ? ' chat-bubble-information text-left'
                        : ' chat-bubble-success text-right'
                    )}
                  >
                    {item.media && (
                      <div
                        className="carousel-item relative"
                        onClick={(event) => dispatch(show(item.media))}
                      >
                        <div
                          className="card w-[400px] bg-base-100 shadow-xl"
                          onClick={(event) => dispatch(show(item.media))}
                        >
                          <figure>
                            <img src={item.media} alt="" />
                          </figure>
                        </div>
                        {/* <div className="absolute w-full bottom-0 right-5 text-right">1 / 2 </div> */}
                      </div>
                    )}
                    <br />
                    {item.contents}
                  </div>
                  {item.createAt && (
                    <div className="chat-footer opacity-50">
                      {formatDistanceToNow(parseISO(item.createAt), { addSuffix: true })}
                    </div>
                  )}
                </div>
              );
            })}
        </>
      )}
      {chatsState.state === 'RELOAD' && (
        <div className="chat text-black chat-end">
          <div className="chat-bubble chat-bubble-success">Sending ...</div>
        </div>
      )}
      {/* </Scrollbars> */}
    </div>
  );
};

export default ChatContent;
