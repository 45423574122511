/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@gvlab/react-theme/styles';
import Dropzone from '../react-dropzone-uploader';
import { BackupRounded as BackupRoundedIcon } from '@gvlab/react-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  dropZone: {
    color: theme.palette.paper.contrastText,
    backgroundColor: theme.palette.paper.main,
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.paper.dark,
      opacity: 0.5,
      cursor: 'pointer',
    },
    '.dzu-input': {
      height: '200px',
      backgroundColor: theme.palette.paper.dark,
    },
  },
  image: {
    width: 130,
  },
  files: {
    marginTop: theme.spacing(3),
  },
  hide: {
    display: 'none',
  },
  toast: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(2),
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.tertiary.contrastText,
    padding: theme.spacing(2, 10),
  },
}));

const SpaceUploader = (props) => {
  const { InputComponent, LayoutComponent, onChangeStatus, onFileUpload, uploadParams, ...rest } =
    props;
  const classes = useStyles();

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    if (LayoutComponent) {
      return (
        <LayoutComponent
          dropzoneProps={dropzoneProps}
          extra={maxFiles}
          files={files}
          input={input}
          maxFiles={maxFiles}
          previews={previews}
          submitButton={submitButton}
        />
      );
    }

    return <div {...dropzoneProps}>{files.length < maxFiles && input}</div>;
  };

  const renderInputComponent = (params) => {
    const { accept, onFiles, getFilesFromEvent } = params;

    if (InputComponent) {
      return <InputComponent {...params} />;
    }

    return (
      <label className={classes.dropZone}>
        <BackupRoundedIcon fontSize="large" />
        <input
          accept={accept}
          multiple
          onChange={async (e) => {
            const target = e.target;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            target.value = null;
          }}
          style={{ display: 'none' }}
          type="file"
        />
      </label>
    );
  };

  const handleUploadFile = async (params) => {
    const uploadFileDefault = (params) => {
      const { file } = params;
      const axios = require('axios');
      const FormData = require('form-data');

      const data = new FormData();
      data.append('file', file);

      var config = {
        ...uploadParams,
        data: data,
      };

      axios(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });
    };

    const uploadFile = onFileUpload || uploadFileDefault;

    uploadFile(params);
  };

  const handleChangeStatus = ({ meta, remove }, status) => {
    const onChangeDefault = ({ meta, remove }, status) => {
      if (status === 'headers_received') {
        toast(`${meta.name} uploaded!`);
        remove();
      } else if (status === 'aborted') {
        toast(`${meta.name}, upload failed...`);
      }
    };

    const changeStatus = onChangeStatus || onChangeDefault;

    changeStatus({ meta, remove }, status);
  };

  const toast = (innerHTML) => {
    const el = document.getElementById('gv-spaces-toast');
    el.innerHTML = innerHTML;
    el.className = classes.toast;
    setTimeout(() => {
      el.className = el.className.replace(classes.toast, classes.hide);
    }, 3000);
  };

  return (
    <div className={classes.root}>
      <Dropzone
        InputComponent={renderInputComponent}
        LayoutComponent={Layout}
        canCancel={false}
        getUploadParams={({ file, meta }) => {
          handleUploadFile({ file, meta });

          return {};
        }}
        multiple={false}
        onChangeStatus={handleChangeStatus}
        {...rest}
      />
      <div className={classes.hide} id="gv-spaces-toast">
        UPLOAD
      </div>
    </div>
  );
};

SpaceUploader.defaultProps = {
  uploadParams: {
    method: 'post',
    url: 'http://gateway.scrumhr.com/spaces/v1/upload',
    headers: {
      uid: '5d6bb7a68e9909360ba05928',
      'Content-Type': 'application/json',
    },
  },
};

SpaceUploader.propTypes = {
  InputComponent: PropTypes.func,
  LayoutComponent: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onFileUpload: PropTypes.func,
  uploadParams: PropTypes.object,
};

export default SpaceUploader;
