/* eslint-disable import/prefer-default-export */
export const BlockFieldsetTD = ({ title, subtitle, className, children }) => (
  <div className="flex flex-col">
    <div className="mb-4">
      <div className="px-0">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-600">{subtitle}</p>
      </div>
    </div>
    <div
      className={`md:col-span-3 md:mt-0 overflow-hidden shadow sm:rounded-md grid grid-cols-6 gap-3 ${className}`}
    >
      {children}
    </div>
  </div>
);
