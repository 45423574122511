import AuthApi from './AuthApi';
import ProApi from './ProApi';
import UserApi from './UserApi';

// AuthClient  -Auth Api auto client
const AuthClient = (options) => {
  const version = '0';
  const clientType = options?.clientType || 'admin';

  // Create - Construct authClient
  const Create = () => {
    if (clientType === 'user') {
      return UserApi(options);
    }
    if (clientType === 'pro') {
      return ProApi(options);
    } 
    if (clientType === 'pro-user') {
      return ProApi(options);
    } 
    return AuthApi(options);
  }

  return {
    version,
    clientType,
    Create,
  };
};

export default AuthClient;
