import clsx from 'clsx';

const SingleValue = ({className, children, ...rest}) => {

  return (
    <span className={clsx("text-primary-content font-1", className)} {...rest}>
      {children}
    </span>
  );
}

export default SingleValue;