/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import { Drawer, Paper, Hidden } from '@gvlab/react-theme/mui';
import { useLocation } from '@gvlab/react-router';
import { useGlobalContext } from '@gvlab/react-lib/Global';
import NavBarContent from './NavBarContent';

const StyledRoot = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose } = useGlobalContext();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location]);

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <StyledRoot {...props}>
            <NavBarContent />
          </StyledRoot>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Paper {...props} elevation={0} square>
          <NavBarContent />
        </Paper>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
