import { combineReducers } from '@reduxjs/toolkit';
import dialogStore from '../src/Dialog/store';
import auth from '../src/Auth/store';
import setting from './settingSlice';

const gvReducers = combineReducers({
  ...dialogStore,
  auth,
  setting,
});

export { 
  selectGvAuth, 
  selectGvUserState, 
  selectGvUser, 
  selectGvError,
  selectGvMenus,
  selectGvMenusState,
  selectGvState,
  selectGvTokens,
  selectGvAuthToken,
  selectGvAuthRefreshToken,
} from '../src/Auth/store';

export const selectGvDialog = ({ gv }) => gv.dialog;

export default gvReducers;
