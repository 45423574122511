/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import { useDialogContext, Loading } from '@gvlab/react-create-app-gv';
import {
  dateUtils,
  updateObjectByString,
  isFunction,
  isObject,
  isUndef,
} from '@gvlab/react-lib/utils';
import { actions as FormEventActions } from '../store/formEventSlice';
import { actions as FormActions } from '../store/formSlice';

const FormReduxStorage = (props) => {
  const {
    children,
    toolbarPosition,

    actions,
    canAdd,
    canEdit,
    canDelete,
    fields,
    columns,
    datasource,
    drawerFullSize,
    formComponent,
    formInitial,
    mode,
    onCreate,
    onCancel,
    onCheckDisabled,
    onClose,
    onDelete,
    onUpdate,
    values: FormValues,
    variant: formVariant,
    title,
    getChanges,
    getValues,
    getValue,
  } = props;
  const { setActionAllow, setMode, initial } = FormActions;
  const { setErrors, updateValue, clearChange } = FormEventActions;
  const dispatch = useDispatch();
  const { formProps, fieldsDef, changeValues } = useSelector(({ app }) => ({
    formProps: app.form,
    fieldsDef: app.form.props.fieldsDef,
    changeValues: app.form.events.changes,
  }));
  const formProp = {
    mode: 'onChange',
  };

  if (datasource) {
    formProp.defaultValues = datasource
  }
  const formMethods = useForm(formProp);
  const { register, getValues: formGetValues, control: formControl } = formMethods;
  const formItemsMethods = useFieldArray({
    control: formControl,
    name: 'items',
  });
  const { fields: formItemsFields, append: formItemsAppend, remove: formItemsRemove } = formItemsMethods;

  const dialog = useDialogContext();

  const handleGetValues = () => {
    if (isFunction(getValues)) {
      return getValues({ datasource, changes: changeValues });
    }
    return formGetValues();
  }

  const refreshChanges = () => {
    const _changes = handleGetValues();

    if (isObject(_changes)) {
      // refresh changes
      Object.keys(_changes)
        .filter((key) => fieldsDef[key]?.readonly === false && formProps.mode !== 'add')
        .filter((key) => fieldsDef[key].value !== _changes[key])
        .forEach((key) => {
          dispatch(
            updateValue({
              field: fieldsDef[key],
              value: _changes[key],
            }),
          );
        });
    }
  };

  const getFormChanges = () => {
    const _changes = handleGetValues();

    if (isFunction(getChanges)) {
      return getChanges({ datasource, fields: fieldsDef, changes: _changes });
    }
    // refresh changes
    refreshChanges();
    let newValue = {};
    const _fieldsDef = {
      ...fieldsDef,
    };

    if (_fieldsDef.length || isUndef(_fieldsDef.length)) {
      fields.forEach((field) => {
        _fieldsDef[field.key] = field;
      });
    }

    Object.keys(_fieldsDef).forEach((key) => {
      const fieldItem = _fieldsDef[key];
      newValue = {
        ...newValue,
        [fieldItem.key]: fieldItem.changeValue || _changes?.[key] || fieldItem.value,
      };
    });

    return newValue;
  };

  const handleFormItemGetValue = ({ field }) => {
    try {
      if (isFunction(getValue)) {
        return getValue(datasource, field);
      }
      const _formChange = handleGetValues();
      const defaultValue = _formChange?.[field.key] || field.value;

      // const defaultValue = field?.listDataIndex
      //   ? listUtils.listGetValue(dataSource, field)
      //   : responseUtils.getValue(dataSource, field);

      if (field?.dataType === 'unix-date') {
        return dateUtils.formatDate(defaultValue) || '2006-01-02';
      }
      if (field?.dataType === 'unix-datetime') {
        return dateUtils.formatDateTime(defaultValue) || '2006-01-02 15:04:05';
      }
      if (field?.component === 'Number') {
        return defaultValue || 0;
      }
      return defaultValue;
    } catch (error) {
      return '';
    }
  };

  // handleErrorCallback to handle while error
  const handleErrorCallback = (Response) => {
    // trigger dialog
    dialog.snackbarError({
      message: Response?.message?.detail || 'This is an error, check it out!',
      variant: 'error',
      open: true,
    });

    // set errors
    if (Response.errors) {
      dispatch(setErrors(Response.errors));
    } else {
      dispatch(setErrors(Response));
    }
  };

  // handleOnDelete handling on delete record
  const handleOnDelete = () => {
    dialog.dialogMessage({
      open: true,
      onButton2Click: () => {
        const _changes = handleGetValues();
        Object.keys(_changes).forEach((key) => {
          updateObjectByString(formProps.values, key, _changes[key]);
        });
        if (isFunction(onDelete)) onDelete(formProps.values, handleErrorCallback);
      },
      message: 'This will permanently delete the record.',
      title: 'Delete Record',
    });
  };

  //  handleOnEdit handling on edit record
  const handleOnSave = () => {
    if (isFunction(onUpdate)) onUpdate(getFormChanges(), handleErrorCallback);
  };

  // handleOnNew handling on new record
  const handleOnNew = () => {
    if (isFunction(onCreate)) onCreate(getFormChanges(), handleErrorCallback);
  };

  // handleOnClose handling on new record
  const handleOnClose = () => {
    if (isFunction(onClose)) onClose();
  };

  const handleOnCheckDisabled = (_props) => {
    if (isFunction(onCheckDisabled)) return onCheckDisabled(_props);
    return _props.field.readonly || _props.field?.disabled;
  };

  React.useEffect(() => {
    dispatch(
      initial({
        options: {
          actions,
          actionsAllow: {
            canAdd: canAdd === true || false,
            canEdit: canEdit === true || true,
            canDelete: canDelete === true || false,
          },
          columns,
          customContainer: formComponent,
          datasource,

          fields,
          onCancel,
          onClose: handleOnClose,
          onError: handleErrorCallback,

          onCreate: handleOnNew,
          onDelete: handleOnDelete,
          onUpdate: handleOnSave,
          onFormItemGetValue: handleFormItemGetValue,

          onCheckDisabled: handleOnCheckDisabled,

          title,
          variant: formVariant,

          formRegister: register,
          toolbar: {
            position: toolbarPosition,
          },
          drawer: {
            fullSize: drawerFullSize || false,
          },
          state: mode || 'read',
        },
        state: mode,
      }),
    );
  }, [dispatch]);

  if (!dispatch || !fieldsDef || fieldsDef?.length === 0) return <Loading />;

  // FormProvider - ReactFormHook
  return <FormProvider {...formMethods} formItems={formItemsMethods} >{props.children}</FormProvider>;
};

FormReduxStorage.defaultProps = {
  toolbarPosition: 'none',
  columns: 1,
  value: {},
  variant: 'panel',
};

FormReduxStorage.propTypes = {
  toolbarPosition: PropTypes.string,

  actions: PropTypes.func,

  canAdd: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  canEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  canDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  columns: PropTypes.number,

  datasource: PropTypes.any.isRequired,
  formInitial: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),

  mode: PropTypes.string,

  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  onCheckDisabled: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,

  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  value: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['panel', 'drawer']),

  getChanges: PropTypes.func,
  getValues: PropTypes.func,
  getValue: PropTypes.func,
};

export default FormReduxStorage;
