import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

export const getDetail = createAsyncThunk(
  'order/getDetail',
  async ({ orderId }, { dispatch, rejectWithValue }) => {
    return Requestly()
      .request({
        method: 'get',
        url: `/user/v1/orders/${orderId}?showchild=true&userType=pro`,
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        const { contexts, orders, projectsId, propertyId, profile, property, project, ...rest } =
          response;
        const resp = {
          ...rest,
          orders: response?.orders,
          items: response.items,
          project_duedate: response.dueAt ? response.dueAt.substr(0, 10) : '',
          project_subject: response.subject,
          project_description: response.description,
          project_amount: response.totalAmount,
        };
        resp.items = response.items;
        if (response.items) {
          resp.items = response.items.map((task, idx) => ({ ...task, idx }));
        }

        if (profile) {
          Object.keys(profile).forEach((key) => {
            resp[`owner_${key.toLowerCase()}`] = profile[key];
          });
        }
        if (property) {
          Object.keys(property).forEach((key) => {
            if (key === 'propertyType') resp.property_type = property[key];
            if (key === 'zipCode') resp.property_postcode = property[key];
            resp[`property_${key.toLowerCase()}`] = property[key];
          });
        }
        if (project) {
          Object.keys(project).forEach((key) => {
            resp[`project_${key.toLowerCase()}`] = project[key];
          });
        }

        resp.manager = response.manager;
        return resp;
      })
      .catch((errors) => {
        return rejectWithValue(errors);
      });
  }
);

const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers: {
    [getDetail.pending]: (state, action) => {
      return {
        ...state,
        state: 'PENDING',
      };
    },
    [getDetail.fulfilled]: (state, action) => {
      return {
        ...state,
        state: 'DONE',
        detail: action.payload,
      };
    },
    [getDetail.rejected]: (state, action) => {
      return {
        ...state,
        state: 'ERROR',
        errors: action.payload,
      };
    },
  },
});

export const { setPending, setDetail, setSuccess, setError } = invoiceSlice.actions;

export default invoiceSlice.reducer;
