/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Loading } from '@gvlab/react-create-app-gv';
import { dateUtils } from '@gvlab/react-lib/utils';
import { StyledTextField } from '../styles';

const BaseField = (props) => {
  const { errors, dataSource, onCheckDisabled, onFormItemGetValue, formRegister } = useSelector(
    ({ app }) => ({
      actionsAllow: app.form.props.options.actionsAllow,
      columnCount: app.form.props.options.columnCount,
      dataSource: app.form.props.options.dataSource,
      errors: app.form.props.errors,
      onCheckDisabled: app.form.props.options.onCheckDisabled,
      onFormItemGetValue: app.form.props.options.onFormItemGetValue,
      formRegister: app.form.props.options.formRegister,
    }),
  );
  const dispatch = useDispatch();

  const { field, value, onChange, fieldProps, allowEntry, ...rest } = props;
  // const [error, setError] = React.useState();

  // const getLastError = (_errors) => {
  //   const errorMessage = responseUtils.getErrorMessage(errors, field);
  //   return errorMessage || '';
  // };

  // React.useMemo(() => {
  //   setError(getLastError(errors));
  // }, [errors]);

  const handleCheckDisabled = () => {
    return onCheckDisabled({
      dataSource,
      field,
    });
  };

  const handleOnChange = (e) => {
    e.persist();

    let changeValue = e.target.value;
    switch (field.dataType) {
      case 'unix-date':
        changeValue = dateUtils.dateToUnix(e.target.value);
        break;
      case 'unix-datetime':
        changeValue = dateUtils.dateTimeToUnix(e.target.value);
        break;
      default:
        changeValue = e.target.value;
    }

    // setChanges(changeValue);
    // dispatch(updateChange({ field, value: changeValue }));

    // console.log(changeValue);
  };

  if (!field && !!formRegister) return <Loading />;

  const key = field?.key;

  return (
    <StyledTextField
      field={field}
      fullWidth
      key={key}
      margin="dense"
      {...formRegister(field.key, fieldProps)}
      {...rest}
      label={field?.title}
    />
  );
};

BaseField.propTypes = {
  field: PropTypes.object,
};

export default BaseField;
