/* eslint-disable import/no-extraneous-dependencies */
import { TextField } from '@gvlab/react-theme/mui';
import clsx from 'clsx';

const InputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
}

const TextFieldControl = ({className, children, ...rest}) => {

  return (
    <TextField
      fullWidth
      className={clsx(className.root, rest.selectProps.textFieldProps.variant)}
      required={rest.selectProps.required}
      error={rest.selectProps.error}
      InputProps={{
        InputComponent,
        inputProps: {
          className: className.input,
          inputRef: rest.innerRef,
          children,
          ...rest.innerProps,
        },
      }}
      {...rest.selectProps.textFieldProps}
    />
  );
}

export default TextFieldControl;