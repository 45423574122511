/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux';
import { Typography, styled } from '@gvlab/react-theme/styles';
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@gvlab/react-theme/mui';
import {
  isFunction,
} from '@gvlab/react-lib/utils';
import { closeDialog } from './store/dialogSlice';
import Dialog from './Dialog';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: `${theme.zIndex.modal + 1}!important`,
  color: theme.palette.tertiary.contrastText,
}));

const defaultConfig = {
  button2Caption: 'OK',
  button1Caption: 'Cancel',
  dividers: false,
  fullScreen: false,
  message: 'Message Here',
  title: 'title here',
};

const DialogMessage = () => {
  const dispatch = useDispatch();
  const dialog = useSelector(({ gv }) => gv.dialog);

  const config = {
    ...defaultConfig,
    ...dialog.options,
  };
  const {
    button1Caption,
    button2Caption,
    dividers,
    fullScreen,
    onButton1Click,
    onButton2Click,
    message,
    title,
  } = config;

  const handleClose = () => {
    if (closeDialog && isFunction(closeDialog)) dispatch(closeDialog());
  };

  const handleButton1Click = () => {
    if (onButton1Click && isFunction(onButton1Click)) onButton1Click();
    handleClose();
  };

  const handleButton2Click = () => {
    if (onButton2Click && isFunction(onButton2Click)) onButton2Click();
    handleClose();
  };

  if (!dialog.state || dialog.options.type !== 'DialogMessage') return null;

  return (
    <StyledDialog
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      open
    >
      <DialogTitle disableTypography id="responsive-dialog-title">
        <Typography color="error" variant="h6">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={dividers}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={handleButton1Click}>
          {button1Caption}
        </Button>
        <Button
          autoFocus
          color="primary"
          onClick={handleButton2Click}
        >
          {button2Caption}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DialogMessage;
