/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from '@reduxjs/toolkit';
import appReducers from 'store/index';
import gvReducers from '../../stores';

const createReducer = (asyncReducers) => (state, action) => {
  const _combinedReducer = combineReducers({
    gv: gvReducers,
    app: appReducers,
    ...asyncReducers,
  });

  return _combinedReducer(state, action);
};

export default createReducer;
