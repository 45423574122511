/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import { Routes } from '@gvlab/react-router';
import { GlobalProvider } from '@gvlab/react-lib/Global';

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
}));

export const ErrorLayout = ({ route }) => {
  return (
    <GlobalProvider>
      <StyledContainer>{route.routes && <Routes paths={route.routes} />}</StyledContainer>
    </GlobalProvider>
  );
};

ErrorLayout.propTypes = {
  route: PropTypes.object,
};
