import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@gvlab/react-theme/styles';
import objectByString from '@gvlab/react-lib/utils/objectByString';
import GridViewItem from './GridViewItem';
import GridContainer from '@gvlab/react-ui/components/Grid/GridContainer';
import GridItem from '@gvlab/react-ui/components/Grid/GridItem';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    backgroundColor: theme.palette.background.default,
    marginRight: '0',
    marginLeft: '0',
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  tabcontainer: {
    borderRadius: '0',
    // margin: '0 -24px',
  },
}));

const CustomGridView = (props) => {
  const { columns, className, dataSource, onView, rowKey, ...rest } = props;

  const classes = useStyles();

  // const [selectedRows, setSelectedRows] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [fields, setFields] = useState(columns);

  // useEffect(() => {
  //   let mounted = true;

  //   setFields(columns.filter(item => (!item.hide || item.hide !== true)));

  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  // const handleSelectAll = event => {
  //   const rowSelected = event.target.checked
  //     ? dataSource.map(dataRow => objectByString(dataRow, rowKey))
  //     : [];

  //   setSelectedRows(rowSelected);
  // };

  // const handleChangePage = (event, page) => {
  //   setPage(page);
  // };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(event.target.value);
  // };

  const handleOnView = (datasource) => {
    onView && onView(datasource);
  };

  const renderTableRows = () => {
    if (dataSource.length === 0) return null;

    return dataSource
      .filter((dataRow) => {
        return dataRow !== undefined;
      })
      .map((dataRow) => {
        dataRow.attributes['id'] = dataRow?.id;
        return (
          <GridItem key={objectByString(dataRow, rowKey)} md={3} sm={4} xs={12}>
            <GridViewItem dataset={dataRow.attributes} onView={handleOnView} />
          </GridItem>
        );
      });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/* <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {dataSource.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(dataSource.length / rowsPerPage)}
      </Typography> */}
      <GridContainer className={classes.content} justifyContent="center" spacing={2}>
        {renderTableRows()}
      </GridContainer>
    </div>
  );
};

CustomGridView.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  multiSelect: PropTypes.bool,
  rowKey: PropTypes.string.isRequired,
};

CustomGridView.defaultProps = {
  columns: [],
  dataSource: [],
  multiSelect: false,
  rowKey: 'id',
};

export default CustomGridView;
