/* eslint-disable import/no-extraneous-dependencies */
import { makeStyles, colors } from '@gvlab/react-theme/styles';
import { CameraAlt as CameraAltIcon } from '@gvlab/react-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.white,
    borderRadius: '50vh',
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.blueGrey[900],
      cursor: 'pointer',
    },
    '& .dzu-input': {
      height: '200px',
      display: 'none',
    },
    '& span': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const InputComponent = (props) => {
  const { accept, onFiles, getFilesFromEvent } = props;
  const classes = useStyles();

  return (
    <label className={classes.root} htmlFor="media-id">
      <CameraAltIcon />
      <input
        id="media-id"
        accept={accept}
        multiple
        onChange={async (e) => {
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
        }}
        style={{ display: 'none' }}
        type="file"
      />
      <span>UPLOAD</span>
    </label>
  );
};

export default InputComponent;
