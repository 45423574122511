/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import * as React from 'react';
import { Loading } from '..';

const Suspense = (props) => {
  return (
    <React.Suspense fallback={<Loading {...props.loadingProps} />}>
      {' '}
      {props.children}
    </React.Suspense>
  );
};

Suspense.propTypes = {
  loadingProps: PropTypes.object,
};

Suspense.defaultProps = {
  loadingProps: {
    delay: 0,
  },
};

export default Suspense;
