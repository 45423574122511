/* eslint-disable import/no-extraneous-dependencies */
//* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormBaseDrawer from './FormBaseDrawer';

const FormBase = props => {
  const { variant } = useSelector(({ app }) => ({
    variant: app.form.props.options.variant
  }));


  if (variant === 'drawer') return <FormBaseDrawer>{props.children}</FormBaseDrawer>;

  return props.children;
};

FormBase.defaultProps = {
  variant: 'panel',
};

FormBase.propTypes = {
  variant: PropTypes.oneOf(['panel', 'drawer']),
};

export default FormBase;
