import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// initial state of task detail
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// action to view task detail
// this action will be used in TaskDetail.js
export const viewDetail = createAsyncThunk('task/viewDetail', async ({ taskId }, { dispatch }) => {
  try {
    // set task detail state to pending
    dispatch(setPending());
    return await Requestly()
      .get(`/user/v1/tasks/${taskId}?userType=pro`)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch(setDetail(response));
        return response;
      })
      .catch((error) => {
        console.log('viewDetail error', error);
        // set task detail state to error
        dispatch(setError(error));
        return false;
      });
  } catch (error) {
    console.log('viewDetail error', error);
    // set task detail state to error
    dispatch(setError(error));
    return false;
  }
});

// start task thunk action creator to start task from API
// and return response data to reducer to update state in store
// for task detail page to use it to display data in UI components (e.g. TaskDetail.js)
export const startTask = createAsyncThunk('task/startTask', async ({ taskId }, { dispatch }) => {
  try {
    // set task detail state to pending
    dispatch(setPending());
    await Requestly()
      .post(`/user/v1/tasks/${taskId}/start?userType=pro`, {})
      .then((response) => {
        dispatch(setSuccess());
        return response;
      })
      .catch((error) => {
        dispatch(setError(error));
      });
  } catch (error) {
    console.log('startTask error', error);
    // set task detail state to error
    dispatch(setError(error));
  }
});

// complete task thunk action creator to complete task from API
// and return response data to reducer to update state in store
// for task detail page to use it to display data in UI components (e.g. TaskDetail.js)
export const completeTask = createAsyncThunk(
  'task/completeTask',
  async ({ taskId }, { dispatch }) => {
    try {
      // set task detail state to pending
      dispatch(setPending());
      await Requestly()
        .post(`/user/v1/tasks/${taskId}/ready?userType=pro`, {})
        .then((response) => {
          dispatch(setSuccess());
          return response;
        })
        .catch((error) => {
          dispatch(setError(error));
        });
    } catch (error) {
      console.log('completeTask error', error);
      // set task detail state to error
      dispatch(setError(error));
    }
  }
);

// claim task thunk action creator to claim task from API
// and return response data to reducer to update state in store
// for task detail page to use it to display data in UI components (e.g. TaskDetail.js)
export const claimTask = createAsyncThunk('task/claimTask', async ({ taskId }, { dispatch }) => {
  try {
    // set task detail state to pending
    dispatch(setPending());
    await Requestly()
      .post(`/user/v1/tasks/${taskId}/claim?userType=pro`, {})
      .then((response) => {
        dispatch(setSuccess());
        return response;
      })
      .catch((error) => {
        dispatch(setError(error));
      });
  } catch (error) {
    console.log('claimTask error', error);
    // set task detail state to error
    dispatch(setError(error));
  }
});

// clear task detail thunk action creator to clear task detail from store
// for task detail page to use it to display data in UI components (e.g. TaskDetail.js)
export const clearDetail = () => (dispatch) => {
  dispatch(setPending());
};

// task detail slice reducer
// to update state in store
// for task detail page to use it to display data in UI components (e.g. TaskDetail.js)
const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // set task detail state to pending
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    // set task detail state to pending
    setPending: (state, action) => {
      state.state = 'PENDING';
      state.detail = initialState.detail;
    },
    // set task detail state to success
    setSuccess: (state, action) => {
      state.state = 'SUCCESS';
      state.success = true;
      state.errors = [];
    },
    // set task detail state to error
    setError: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {
    // set task view state to done when view task detail is fulfilled
    [viewDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: action.payload,
      };
    },
  },
});

export const { setPending, setDetail, setSuccess, setError } = taskSlice.actions;
export const selectedTask = ({ app }) => app.dashboard.task.detail;
export const selectedTaskState = ({ app }) => app.dashboard.task;

export default taskSlice.reducer;
