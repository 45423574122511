import PropTypes from 'prop-types';
import Backdrop from '@gvlab/react-ui/v2/Backdrop';
import { useSelector } from 'react-redux';

const GvLoading = (props) => {
  const { loading } = useSelector(({ app }) => ({ loading: app.loading }));

  if (loading.state !== 'open') return null;

  return (
    <Backdrop
      className="z-999"
      open
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <div className="flex justify-center items-center h-full text-white">
        Houzmoni
        <br />
        <span className="loading loading-ring loading-xs" />
        <span className="loading loading-ring loading-sm" />
        <span className="loading loading-ring loading-md" />
        <span className="loading loading-ring loading-lg" />
      </div>
    </Backdrop>
  );
};

GvLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

GvLoading.defaultProps = {
  delay: false,
};

export default GvLoading;
