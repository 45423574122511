import { combineReducers } from '@reduxjs/toolkit';
import FormReducers from '@gvlab/react-form/store';
import datasourceSlice from '@gvlab/react-dataset/store/datasourceSlice';
import datastoreSlice from '@gvlab/react-dataset/store/datastoreSlice';
import DbControlReducers from '@gvlab/react-dbcontrols/store';
import LayoutReducers from 'Layout/store';
import DashboardReducers from 'store/Dashboard';
import AuthReducers from 'store/Auth';

import lightBoxSlice from './lightBoxSlice';
import loadingSlice from './loadingSlice';

const AppReducers = combineReducers({
  layout: LayoutReducers,
  dashboard: DashboardReducers,
  auth: AuthReducers,
  form: FormReducers,
  datasource: datasourceSlice,
  datastore: datastoreSlice,
  dbcontrol: DbControlReducers,

  lightbox: lightBoxSlice,
  loading: loadingSlice,
});

export default AppReducers;
