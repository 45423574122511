/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import {
  selectedChatsState,
  clearDetail as clearChatDetail,
} from 'store/Dashboard/chatsSlice';

import ChatContent from './ChatContent';
import ChatActions from './ChatActions';

const Chat = (props) => {
  const dispatch = useDispatch();
  const chatsState = useSelector(selectedChatsState);

  if (!['DONE', 'RELOAD'].includes(chatsState.state)) {
    return null;
  }

  return (
    <Drawer fullSize={false} open onClose={() => dispatch(clearChatDetail())} title="Chat">
      <div className="h-full bg-white-100 flex flex-col justify-center w-96">
        <ChatContent />
        <ChatActions />
      </div>
    </Drawer>
  );
};

export default Chat;
