import { safeSetValue } from '@gvlab/react-lib/utils';
import { setGlobalState, getGlobalState } from './GlobalState';

function GlobalValue() {
  const setGlobalValue = (patch) => {
    setGlobalState('custom', (preState) => safeSetValue(preState, patch));
  };

  const getGlobalValue = () => {
    return getGlobalState('custom');
  };

  return {
    setGlobalValue,
    getGlobalValue,
  };
}

export default GlobalValue;
