/* eslint-disable import/no-extraneous-dependencies */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';

const CardBase = styled('div')(({ theme }) => ({
  padding: '0px',
  '& figure': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div:first-of-type': {
      borderStartStartRadius: 'inherit',
      borderStartEndRadius: 'inherit',
      borderEndStartRadius: 'unset',
      borderEndEndRadius: 'unset',
      overflow: 'hidden',
    },
  }
}));

const Card = (props) => {
  const { children, className, ...rest } = props;

  return (
    <CardBase {...rest} className={clsx('card rounded shadow', className)}>
      {children}
    </CardBase>
  );
};

Card.propTypes = {
  className: PropTypes.string,
};

export default Card;
