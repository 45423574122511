/* eslint-disable import/no-named-as-default-member */
import { combineReducers } from '@reduxjs/toolkit';

import dashboardSlice from './dashboardSlice';
import quotationSlice from './quotationSlice';
import quotationDetailSlice from './quotationDetailSlice';
import quotationDraftSlice from './quotationDraftSlice';
import invoiceSlice from './invoiceSlice';
import invoiceExportSlice from './invoiceExportSlice';

import chatSlice from './chatSlice';
import chatsSlice from './chatsSlice';
import taskSlice from './taskSlice';
import taskDraftSlice from './taskDraftSlice';
import orderSlice from './orderSlice';
import mediaSlice from './mediaSlice';
import walletSlice from './walletSlice';
import walletBalanceSlice from './walletBalanceSlice';
import walletDetailSlice from './walletDetailSlice';
import paymentSlice from './paymentSlice';
import paymentDetailSlice from './paymentDetailSlice';
import withdrawDetailSlice from './withdrawDetailSlice';

const DashboardReducers = combineReducers({
  dashboard: dashboardSlice,
  quotation: quotationSlice,
  quotationDetail: quotationDetailSlice,
  quotationDraft: quotationDraftSlice,
  invoice: invoiceSlice,
  invoiceExport: invoiceExportSlice,
  chat: chatSlice,
  chats: chatsSlice,
  task: taskSlice,
  taskDraft: taskDraftSlice,
  order: orderSlice,
  media: mediaSlice,
  wallet: walletSlice,
  walletBalance: walletBalanceSlice,
  walletDetail: walletDetailSlice,

  payment: paymentSlice,
  paymentDetail: paymentDetailSlice,
  withdrawDetail: withdrawDetailSlice,
});

export default DashboardReducers;
