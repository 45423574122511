/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { Drawer } from '@gvlab/react-theme/mui';
import useStyles from './styled'

const Container = (props) => {
  const {
    anchor,
    fullSize,
    variant,
    progress,
    ...rest
  } = props;
  const classes = useStyles();

  if (variant === 'drawer') return (
    <Drawer
      {...rest}
      SlideProps={{ unmountOnExit: true }}
      anchor={anchor}
      classes={{ paper: fullSize ? classes.drawer : classes?.['drawer-small'],  }}
    >
      {props.children}
    </Drawer>
  );

  return <div>{props.children}</div>;
};


Container.defaultProps = {
  fullSize: true,
  anchor: 'right',
  variant: 'drawer',
};

Container.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  fullSize: PropTypes.bool,
  variant: PropTypes.oneOf(['panel', 'drawer']),
};

export default Container;
