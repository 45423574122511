/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable import/prefer-default-export */
import { Accordion, AccordionSummary, AccordionDetails } from '@gvlab/react-theme/mui';
import { ExpandMore as ExpandMoreIcon } from '@gvlab/react-icons';
import { numberUtils } from '@gvlab/react-lib/utils';
import { ListDetail } from './ListDetail';

export const ListTask = ({ task, formState, groupId, onAdd, onRemove, onUpdate, onViewTask }) => {
  return (
    <li className="border-t border-gray-200">
      <Accordion className="">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="ml-4 flex flex-1 flex-col w-full">
            <div className="flex justify-between text-base font-medium text-gray-900 items-center	">
              <div>
                <h3>{task.subject}</h3>
                <p className="text-sm text-gray-500 w-full">{task.description}</p>
              </div>
              <p className="ml-4">{numberUtils.currencyFormat(task.amount, 0, ' ')} ( Credit )</p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ListDetail
            task={task}
            formState={formState}
            groupId={groupId}
            onAdd={onAdd}
            onRemove={onRemove}
            onUpdate={onUpdate}
            onViewTask={onViewTask}
          />
        </AccordionDetails>
      </Accordion>
    </li>
  );
};
