/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { safeSetValue } from '@gvlab/react-lib/utils';
import { baseDomain, clientType, serviceId } from 'config/environment';
import Requestly from '@gvlab/requestly';

// initial datasource options and headers for request api with requestly   
const initialOptions = {
  clientType: clientType || 'auth',
  serviceId,
  serverkey: 'defaultKey',
  host: baseDomain,
  timeout: 60000,
  token: '',
};

export const Create = createAsyncThunk(
  'datasource/create',
  async ({tableName, method, url, params}, { dispatch, rejectWithValue }) => {
    try {
      // set order detail state to pending
      return await Requestly()
        .request({ method, url, params})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  state: false,
  options: initialOptions,
  headers: {},
  data: null,
  error: null,
};

// datasource slice for redux store 
const datasourceSlice = createSlice({
  name: 'datasource',
  initialState,
  reducers: {
    // format authorization header with jwt token     
    formatAuthHeader: (state, action) => {
      // set params for headers 
      const params = action.payload?.params || {};

      // set token
      if (action.payload?.token) {
        state.options.token = safeSetValue(state.token, action.payload?.token);
      }

      // return authorization header with jwt token
      if (state.options.token) {
        state.headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          crossDomain: true,
          Authorization: `Bearer ${state.options.token}`,
          ...params,
        };
      } else {
        state.headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          crossDomain: true,
          ...params,
        };
      }
    }, 

    // initial datasource
    initialDatasource: (state, action) => {
      if (action.payload) {
        state.state = true;
        state.options = {
          ...state.options,
          ...action.payload,
        };
      }
    },

    // set token to datasource
    setToken: (state, action) => {
      state.state = true;
      state.options = {
        ...state.options,
        token: action.payload,
      };
      if (state.options.token) {
        state.headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          crossDomain: true,
          Authorization: `Bearer ${state.options.token}`,
        };
      } else {
        state.headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          crossDomain: true,
        };
      }
    },
  },
  extraReducers: {
    [Create.pending]: (state) => {
      return { 
        ...initialState,
        state: 'CREATE',
      };
    },
    [Create.fulfilled]: (state, actions) => {
      return {
        ...initialState,
        state: 'DONE',
        data: actions.payload,
      };
    },
    [Create.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        error: action.payload,
      };
    },
  },
});

export const { formatAuthHeader, initialDatasource, setToken } = datasourceSlice.actions;

export const selectDatasourceOptions = ({ app }) => app.datasource.options;
export const selectDatasourceServiceId = ({ app }) => app.datasource.options.serviceId;

export default datasourceSlice.reducer;
