import React from 'react';
import { TextField } from '@gvlab/react-theme/mui';
import { makeStyles, alpha } from '@gvlab/react-theme/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 5,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

const CustomTextField = (props) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
};

export default CustomTextField;
