const StyledComponent = {
  styleOverrides: {
    '*': {
      fontFamily: '"Raleway","Roboto","Helvetica","Arial","Sans Serif"',
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      width: '100%',
    },
    body: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      minHeight: '100%',
      width: '100%',
    },
    '#__next': {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  },
};

export default StyledComponent;
