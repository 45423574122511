/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
import { useSelector, useDispatch } from 'react-redux';

import { Typography } from '@gvlab/react-theme/styles';
import { isFunction, isString, uniqueKey, isObject } from '@gvlab/react-lib/utils';
import {
  BaseField,
  Checkbox,
  DatePicker,
  DateTimePicker,
  DBSelectInput,
  ListInput,
  Media,
  MultiSelect,
  NumberInput,
  PasswordInput,
  RadioInput,
  SelectInput,
  TimePicker,
} from '../components';
import { StyledGridItem } from '../styles';

const FormField = () => {
  const { mode, columnCount, dataSource, fieldsDef, changeValue } = useSelector(({ app }) => ({
    mode: app.form.props.state,
    columnCount: app.form.props.options.columnCount,
    dataSource: app.form.props.options.dataSource,
    fieldsDef: app.form.props.fieldsDef,
    changeValue: app.form.events.changes,

  }));
  const dispatch = useDispatch();

  // renderFormItem func to render from items
  const renderFormItem = (_config) => {
    const uniqKey = uniqueKey();

    return Object.keys(_config)
      .map((ObjectId) => {
        const item = {
          ..._config[ObjectId],
        };
        // skip for hideAdd
        if (mode === 'add' && item?.hideAdd === true) {
          return null;
        }
        // skip render for hideEdit
        if (mode === 'edit' && item?.hideEdit === true) {
          return null;
        }
        const fieldProps = {
          error: item?.errorMessage?.length,
          helperText: item?.errorMessage?.toString(),
          required: item.required || false,
          defaultValue: item.value,
          value: item.value,
          label: item?.title || '',
          message: item?.errorMessage || '',
          title: item?.title || '',
          multiline: (item?.rows && item?.rows > 1) || false,
          rows: item?.rows,
        };
        const controlDisable = (item.allowEdit === false && mode === 'edit') || item.isReadOnly();

        const newProps = controlDisable
          ? {
              field: item,
              InputProps: {
                readOnly: true,
              },
              disabled: true,
              variant: 'standard',
              fieldProps,
            }
          : {
              field: item,
              variant: 'outlined',
              fieldProps,
            };

        if (item.component === 'Input') {
          return <BaseField {...newProps} />;
        }
        if (item.component === 'Password') {
          return <PasswordInput {...newProps} />;
        }
        if (item.component === 'Checkbox') {
          return <Checkbox {...newProps} />;
        }
        if (item.component === 'DBSelect') {
          return <DBSelectInput {...newProps} />;
        }
        if (item.component === 'MultiSelect') {
          return <MultiSelect {...newProps} />;
        }
        if (item.component === 'Select') {
          return <SelectInput {...newProps} />;
        }
        if (item.component === 'Radio') {
          return <RadioInput {...newProps} />;
        }
        if (item.component === 'Date') {
          return <DatePicker {...newProps} />;
        }
        if (item.component === 'Time') {
          return <TimePicker {...newProps} />;
        }
        if (item.component === 'Datetime') {
          return <DateTimePicker {...newProps} />;
        }
        if (item.component === 'List') {
          return <ListInput {...newProps} renderer={renderFormItem} />;
        }
        if (item.component === 'Media') {
          return <Media {...newProps} />;
        }
        if (item.component === 'Number') {
          return <NumberInput {...newProps} />;
        }
        if (item.component === 'Custom') {
          let customLabel = '';
          if (isFunction(item?.cell)) {
            customLabel = item.cell(dataSource);
          }
          if (isString(item?.cell)) {
            customLabel = item?.cell;
          }
          return <Typography component="h5">{customLabel}</Typography>;
        }
        return null;
      })
      .map((FormItemElement, idx) => (
        <StyledGridItem key={`${uniqKey}-${idx}`} md={columnCount} xs={12}>
          {FormItemElement}
        </StyledGridItem>
      ));
  };

  if (!fieldsDef || !isObject(fieldsDef)) return null;

  return renderFormItem(fieldsDef);
};

export default FormField;
