// wallet slice is the state of the wallet component
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// viewDetail action to view task detail from API and update state in store
export const viewDetail = createAsyncThunk(
  'paymentDetail/viewDetail',
  async ({ paymentId }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .get(`/user/v1/payments/${paymentId}?userType=pro`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.log('viewDetail error', error);
      // set task detail state to error
      return rejectWithValue(error);
    }
  }
);

// clearDetail action to clear task detail from store
export const clearDetail = createAsyncThunk('paymentDetail/clearDetail', (payload) => true);

// update wallet detail slice initial state
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// create payment detail slice reducer and actions
const paymentDetailSlice = createSlice({
  name: 'paymentDetail',
  initialState,
  reducers: {},
  extraReducers: {
    [clearDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
      };
    },

    [viewDetail.pending]: (state, action) => {
      return {
        ...state,
        state: 'PENDING',
      };
    },
    [viewDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: action.payload,
      };
    },
    [viewDetail.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        errors: action.payload,
      };
    },
  },
});

export const selectPaymentDetailState = ({ app }) => app.dashboard.paymentDetail;
export const selectPaymentDetail = ({ app }) => app.dashboard.paymentDetail.detail;

export default paymentDetailSlice.reducer;
