/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AuthGuard, useAuth } from '@gvlab/react-create-app-gv';
import { GlobalProvider } from '@gvlab/react-lib/Global';
import { Routes } from '@gvlab/react-router';
import { useModel } from '@gvlab/react-dataset/hooks';
import VersionPanel from '@gvlab/react-ui/components/VersionPanel';

import Widget from 'Widget';
import NavBar from 'Widget/NavBar';
import TopBar from 'Widget/TopBar';

import { closeNavBarMobile, selectLayoutOptions } from './store/layoutSlice';

// dashboard layout component with topbar, navbar, and content area (routes)
const Dashboard = ({ route, ...rest }) => {
  const dispatch = useDispatch();
  const authCtx = useAuth();
  const location = useLocation();
  const layout = useSelector(selectLayoutOptions);

  // allow roles for this layout (if not set, allow all roles)
  const allowRoles = ['staff', 'pro', 'pro-user'];

  useEffect(() => {
    dispatch(closeNavBarMobile());
  }, [dispatch, location]);

  const apiClient = useModel();

  // global context values (for use in any component)
  const value = useMemo(
    () => ({
      dispatch,
      authCtx,
      apiClient,
    }),
    []
  );

  return (
    <GlobalProvider values={value}>
      <AuthGuard roles={allowRoles}>
        <div className="flex flex-row h-full w-full overflow-hidden print:hidden">
          <NavBar />
          <div className="grow flex flex-col justify-start">
            <TopBar position="sticky" />
            <div className="overflow-auto h-full flex flex-col justify-start">
              <Routes paths={route.routes} extra={{ ...rest }} />
            </div>
            <VersionPanel copyright="houzmoni sdn. bhd." />
          </div>
        </div>
        <Widget />
      </AuthGuard>
    </GlobalProvider>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
