// EventEmitter  allows us to create and handle custom events easily
const EventEmitter = {
  events: {},

  _getEventListByName(event) {
    if (typeof this.events[event] === 'undefined') {
      this.events[event] = new Set();
    }
    return this.events[event];
  },

  // on function subscribe to event
  on(event, callback) {
    this._getEventListByName(event).add(callback);
  },

  // once function subscribe once
  once(event, callback) {
    const self = this;

    const onceFn = (...args) => {
      self.removeListener(event, onceFn);
      callback.apply(self, args);
    };
    this.on(event, onceFn);
  },

  // emit function dispatch event
  emit(event, ...args) {
    this._getEventListByName(event).forEach(callback => callback(args));
  },

  // removeListener function unsubscribe event
  removeListener(event, fn) {
    this._getEventListByName(event).delete(fn);
  }
};

export default EventEmitter;
