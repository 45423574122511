/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Line from '@gvlab/react-ui/v2/Line';
import { FormBase } from 'components/Common/FormBase';
import { Customer } from './Customer';
import { Property } from './Property';
import { Project } from './Project';
import { List } from './List/List';
import { ActionBar } from './ActionBar';

const Form = ({ values }) => {
  return (
    <FormBase values={values}>
      <ActionBar />
      <section className="bg-gray-50 p-8 mt-4">
        <Customer className="divide-y divide-solid mb-4" />
        <Line />
        <Property className="divide-y divide-solid mb-4" />
        <Line />
        <Project className="divide-y divide-solid mb-4" />
        <Line />
        <List className="divide-y divide-solid mb-4" />
      </section>
    </FormBase>
  );
};

export default Form;
