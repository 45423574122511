/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';

import { GridContainer } from '@gvlab/react-ui/components';
import { Loading, Suspense } from '@gvlab/react-create-app-gv';
import FormTopAlertMessage from './FormTopAlertMessage';
import FormContainer from './FormContainer';
import FormToolbar from './FormToolbar';
import FormField from './FormField';

import { FormDetailRoot } from '../styles';

const FormDetails = () => {
  const { fieldsDef, toolbar } = useSelector(({ app }) => ({
    fieldsDef: app.form.props.options.fieldsDef,
    toolbar: app.form.props.options.toolbar,
  }));

  if (fieldsDef?.length === 0) return <Loading />;

  return (
    <Suspense>
      <FormContainer>
        {toolbar.position === 'top' && <FormToolbar />}
        <FormDetailRoot>
          <FormTopAlertMessage />
          <GridContainer spacing={1}>
            <FormField />
          </GridContainer>
        </FormDetailRoot>
        {toolbar.position === 'bottom' && <FormToolbar />}
      </FormContainer>
    </Suspense>
  );
};

export default FormDetails;
