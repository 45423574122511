/* eslint-disable import/no-extraneous-dependencies */
import { initialDataStore, setGlobalState, getGlobalState } from '@gvlab/react-lib/Global/GlobalState';
import { createGlobalProviderMiddleware } from '@gvlab/react-lib/Global/GlobalProvider';

function DataStoreHelper() {
  const loadDataStore = (dataSource, state, newValue) => {
    // get dataStore from dataSource
    const dataStore = state?.[dataSource] || initialDataStore;

    return {
      ...state,
      [dataSource]: {
        // default or initial values
        ...dataStore,
        isLoading: newValue?.isLoading || dataStore.isLoading,
        data: [...(newValue?.data || dataStore.data)],
        meta: {
          ...(newValue?.meta || dataStore.meta),
          size: newValue?.meta?.size || dataStore.meta.size,
        },
      },
    };
  };
  const insertDataStore = (dataSource, newValue) => {
    const doInsert = (_dataSource, prevState, _value) => {
      // get dataStore from dataSource
      const dataStore = prevState?.[dataSource] || initialDataStore;

      // if newValue not data just ignore
      if (!_value || !_value?.data) return prevState;

      return {
        ...prevState,
        [_dataSource]: {
          // default or initial values
          ...dataStore,
          isLoading: _value?.isLoading || dataStore.isLoading,
          data: [...dataStore.data, _value?.data || null],
          meta: {
            ...(_value?.meta || dataStore.meta),
            total: dataStore.meta.total + 1,
          },
        },
      };
    };
    setGlobalState('dataStore', (prevState) => doInsert(dataSource, prevState, newValue));
  };

  const editDataStore = (
    dataSource,
    newValue,
    cbCheck = (prev, res) => {
      return prev.id === res.data.id ? res.data : prev;
    },
  ) => {
    const doUpdate = (_dataSource, state, _value) => {
      // get dataStore from dataSource
      const dataStore = state?.[_dataSource] || initialDataStore;

      // if _value not data just ignore
      if (!_value || !_value?.data) return state;

      return {
        ...state,
        [_dataSource]: {
          // default or initial values
          ...dataStore,
          isLoading: _value?.isLoading || dataStore.isLoading,
          data: dataStore.data.map((e) => cbCheck(e, _value)),
          meta: {
            ...(_value?.meta || dataStore.meta),
          },
        },
      };
    };
    setGlobalState('dataStore', (state) => doUpdate(dataSource, state, newValue));
  };
  const initDataStore = (dataSource) => {
    setGlobalState('dataStore', (state) => loadDataStore(dataSource, state, initialDataStore));
  };

  const resetDataStore = (dataSource) => {
    setGlobalState('dataStore', (state) => loadDataStore(dataSource, state, initialDataStore));
  };

  const setDataStore = (dataSource, values) => {
    setGlobalState('dataStore', (state) => loadDataStore(dataSource, state, values));
  };

  const getDataStore = (dataSource) => {
    const resp = getGlobalState('dataStore')[dataSource];
    if (resp) {
      return resp;
    }
    // if not found initialize it
    initDataStore(dataSource);
    return initialDataStore;
  };

  const getDataStoreState = (dataSource) => {
    const resp = getDataStore(dataSource);
    return resp.isLoading;
  };

  const setDataStoreState = (dataSource, values) => {
    return setDataStore(dataSource, { isLoading: values });
  };

  return {
    editDataStore,
    loadDataStore,
    insertDataStore,
    initDataStore,
    resetDataStore,

    setDataStore,
    getDataStore,

    getDataStoreState,
    setDataStoreState,
  };
}

// create global provider middleware
createGlobalProviderMiddleware('dataStoreHelper', DataStoreHelper);

export default DataStoreHelper;
