/* eslint-disable import/prefer-default-export */
import { InputLabel } from '@gvlab/react-theme/mui';
import { BlockFieldsetLR } from 'components/Common/BlockFieldsetLR';

import { FormField } from 'components/Common/FormField';

export const Customer = ({ formState = 'add' }) => {
  return (
    <BlockFieldsetLR
      title="Customer Information"
      subtitle="Use a permanent address where you can receive mail."
      className="p-6 bg-white"
    >
      <div className="col-span-6">
        <InputLabel htmlFor="email-address" className="block text-sm font-medium text-gray-700">
          Customer Name
        </InputLabel>
        <FormField
          type="text"
          name="owner_fullname"
          id="owner_fullname"
          autoComplete="owner_fullname"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formState={formState}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <InputLabel htmlFor="first-name" className="block text-sm font-medium text-gray-700">
          Passport / Identity Card #
        </InputLabel>
        <FormField
          type="text"
          name="owner_passport"
          id="owner_passport"
          autoComplete="owner_passport"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formState={formState}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <InputLabel htmlFor="last-name" className="block text-sm font-medium text-gray-700">
          Phone
        </InputLabel>
        <FormField
          type="text"
          name="owner_phone"
          id="owner_phone"
          autoComplete="owner_phone"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formState={formState}
        />
      </div>
      <div className="col-span-6">
        <InputLabel htmlFor="email-address" className="block text-sm font-medium text-gray-700">
          Email address
        </InputLabel>
        <FormField
          type="text"
          name="owner_email"
          id="owner_email"
          autoComplete="owner_email"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          formState={formState}
        />
      </div>
    </BlockFieldsetLR>
  );
};
