import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { Requestly } from '@gvlab/react-lib/utils';

export const getNotifications = createAsyncThunk(
  'houz/notifications/getNotifications',
  async ({ token, host }, { dispatch, getState }) => {
    // const response = await Requestly({ token, host }).request({
    //   method: 'get',
    //   url: `/user/v1/notifications?userType=pro`,
    // });

    // const data = await response.data;

    // return data;
    return {
      list: [],
      state: false,
    };
  }
);

const initialOptions = {
  navBarMobile: false,
  profileBar: false,
  notifications: {
    list: [],
    state: false,
  },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    state: false,
    options: initialOptions,
  },
  reducers: {
    openNavBarMobile: (state, action) => {
      state.state = true;
      state.options.navBarMobile = true;
    },
    closeNavBarMobile: (state, action) => {
      state.state = true;
      state.options.navBarMobile = false;
    },
    openProfileBar: (state, action) => {
      state.state = true;
      state.options.profileBar = true;
    },
    closeProfileBar: (state, action) => {
      state.state = true;
      state.options.profileBar = false;
    },
    openNotification: (state, action) => {
      state.state = true;
      state.options.notifications.state = true;
    },
    closeNotification: (state, action) => {
      state.state = true;
      state.options.notifications.state = false;
    },
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, action) => ({
      options: {
        ...state.options,
        notifications: action.payload,
      },
    }),
  },
});

export const {
  openNavBarMobile,
  closeNavBarMobile,
  openProfileBar,
  closeProfileBar,
  openNotification,
  closeNotification,
} = layoutSlice.actions;

export const selectLayout = ({ app }) => app.layout;
export const selectLayoutOptions = ({ app }) => app.layout.dashboard.options;
export const selectNotifications = ({ app }) => app.layout.dashboard.options.notifications;

export default layoutSlice.reducer;
