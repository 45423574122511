/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from '@gvlab/react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import { AppBar, Badge, IconButton, Toolbar, colors, MenuItem, Menu } from '@gvlab/react-theme/mui';
import {
  AccountCircle,
  List as ListItemIcon,
  Input as InputIcon,
  Notifications as NotificationsIcon,
  HomeSharp as HomeSharpIcon,
  Add as AddIcon,
  Apps as AppsIcon,
} from '@gvlab/react-icons';
import { useGlobalContext } from '@gvlab/react-lib/Global';
import NotificationsPopover from '@gvlab/react-ui/components/NotificationsPopover';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid transparent',
    borderBottomColor: '#1b1b1b1e',
    boxShadow: 'none',
  },
  container: {
    maxWidth: '975px',
    margin: '0 auto',
    width: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
  notificationsButton: {
    // marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logo: {
    color: 'black',
    filter: 'invert(1)',
    height: '24px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100px',
    },
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const TopBar = (props) => {
  const classes = useStyles();
  const notificationsRef = useRef(null);
  const [notifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { authCtx, setIsNewModal, isMobileMode, callCustomEvent } = useGlobalContext();
  const userInfo = authCtx.getAccountInfo();

  useEffect(() => {
    let mounted = false;

    const fetchNotifications = () => {
      // axios.get('/account/notifications').then(response => {
      //   if (mounted) {
      //     setNotifications(response.data.notifications);
      //   }
      // });
    };
    if (mounted) {
      fetchNotifications();
    }

    return () => {
      mounted = false;
    };
  }, []);

  // Notification Dialog
  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleMenu = (event) => {
    // console.log(event)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // New Post
  const handleNewPost = () => {
    setIsNewModal(true);
  };

  return (
    <AppBar className={clsx(classes.root, classes.flexGrow)} position="relative">
      <Toolbar className={classes.container}>
        <div className={classes.profile}>
          <img alt="Logo" className={classes.logo} src="/images/logos/large.png" />
        </div>
        <div className={classes.flexGrow} />
        <IconButton color="inherit" component={RouterLink} to="/me">
          <HomeSharpIcon fontSize={isMobileMode ? 'small' : 'medium'} />
        </IconButton>
        <IconButton color="inherit" component={RouterLink} to="/me/apps">
          <AppsIcon fontSize={isMobileMode ? 'small' : 'medium'} />
        </IconButton>
        <IconButton color="inherit" onClick={handleNewPost}>
          <AddIcon fontSize={isMobileMode ? 'small' : 'medium'} />
        </IconButton>
        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={notifications.length}
            classes={{ badge: classes.notificationsBadge }}
            variant="dot"
          >
            <NotificationsIcon fontSize={isMobileMode ? 'small' : 'medium'} />
          </Badge>
        </IconButton>
        <IconButton
          aria-controls="menu-appbar"
          aria-haspopup="true"
          aria-label="account of current user"
          color="inherit"
          onClick={(event) => {
            handleMenu(event);
            // callCustomEvent('onOpenProfileBar');
          }}
          ref={notificationsRef}
        >
          <AccountCircle fontSize={isMobileMode ? 'small' : 'medium'} />
        </IconButton>
        <Menu anchorEl={anchorEl} id="menu-appbar" keepMounted onClose={handleClose} open={open}>
          <MenuItem
            onClick={(event) => {
              handleMenu(event);
              callCustomEvent('onOpenProfileBar');
            }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              authCtx.logout();
            }}
          >
            <ListItemIcon>
              <InputIcon fontSize="small" />
            </ListItemIcon>
            <Typography noWrap variant="inherit">
              Sign Out
            </Typography>
          </MenuItem>
        </Menu>
        <div className={classes.profile}>
          <Typography className={classes.name} variant="h4">
            {userInfo?.attributes?.email}
          </Typography>
        </div>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  title: PropTypes.string,
};

export default TopBar;
