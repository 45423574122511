import { lazy } from 'react';
import { Redirect } from '@gvlab/react-router';
import UserAuthLayout from 'Layout/Auth';

const routes = [
  {
    path: '/auth',
    component: (props) => (
      <UserAuthLayout
        {...props}
        title="Houzmoni Pro"
        description="Get started with fast, secure, and reliable API services"
      />
    ),
    routes: [
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('../components/Auth/UserRegister')),
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('../components/Auth/UserLogin')),
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('../components/Auth/ForgotPassword')),
      },
      {
        path: '/auth/first-time-login',
        exact: true,
        component: lazy(() => import('../components/Auth/FirstTimeLogin')),
      },

      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
];

export default routes;
