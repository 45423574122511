/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import clsx from 'clsx';
import { styled } from '@gvlab/react-theme/styles';
import { ButtonBase } from '@gvlab/react-theme/mui';

export const Button = ({ 
  className,
  rounded,
  color,
  bgvariant,
  fontcolor,
  fontvariant,
  fullWidth,
  variant,
  ...rest }) => {
  const bgColorVariant = bgvariant ? `-${bgvariant}` : '-700';
  const fontColorVariant = fontvariant ? `-${fontvariant}` : '-300';
  let btnColor = '';

  switch (color) {
    case 'basic':
    case 'base':
      // base color
      btnColor = 'btn-base';
      break;
    case 'brand':
    case 'primary':
      // primary color
      btnColor = 'btn-primary';
      break;
    case 'secondary':
      // secondary color
      btnColor = 'btn-secondary';
      break;
    case 'accent':
    case 'tertiary':
      // tertiary color
      btnColor = 'btn-accent';
      break;
    case 'info':
      // info color
      btnColor = 'btn-info';
      break;
    case 'error':
      // error color
      btnColor = 'btn-error';
      break;
    case 'warning':
      // warning color
      btnColor = 'btn-warning';
      break;
    case 'success':
      // success color
      btnColor = 'btn-success';
      break;
    default:
      btnColor = color ? `bg-${color}${bgColorVariant}` : '';
  }

  let btnVariant = '';
  switch (variant) {
    case 'outline':
      btnVariant = 'btn btn-outline';
      break;
    case 'link':
      btnVariant = 'btn btn-link';
      break;
    default:
      btnVariant = 'btn';
  }

  return (
    <button
      type="button"
      className={clsx(
        // basic button prop
        `text-sm text-center font-medium `,
        btnVariant,
        btnColor,
        fontcolor ? `text-${fontcolor}${fontColorVariant}` : '',
        rounded ? `rounded-${rounded}` : 'rounded',
        fullWidth ? 'w-full' : 'w-auto',
        className,
      )}
      {...rest}
    />
  );
};

export const ButtonRoot = ({
  className,

  color,
  variant,
  fontcolor,
  fontvariant,
  rounded,

  ...rest
}) => {
  const colorVariant = variant ? `-${variant}` : '-700';
  const fontColorVariant = fontvariant ? `-${fontvariant}` : '-300';

  return (
    <Button
      className={clsx(
        // basic button prop
        "btn",
        `text-sm text-center font-medium`,
        // user define override
        className,
        color ? `border-${color}${colorVariant}` : `border-gray${colorVariant}`,
        rounded ? `rounded-${rounded}` : 'rounded-lg',
        fontcolor ? `text-${fontcolor}${fontColorVariant}` : `text-gray${fontColorVariant}`,
        // background
        color ? `bg-${color}-${colorVariant}` : `bg-indigo-${colorVariant}`,
      )}
      {...rest}
    />
  );
};

export const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
}));
export const CancelButton = styled(Button)(({ theme }) => ({}));
export const ConfirmButton = styled(Button)(({ theme }) => ({}));

export const ListButton = (props) => (
  <ButtonBase
    className="text-indigo-400 enabled:hover:bg-indigo-50 enabled:hover:text-indigo-700 flex flex-row items-center	justify-start	text-white text-sm font-normal	"
    {...props}
  />
);