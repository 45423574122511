// Response
export const isPromise = (target) => {
  return Promise.resolve(target) === target;
};
export const resolve = (data) => {
  return Promise.resolve(data);
};
export const reject = (data) => {
  return Promise.reject(data);
};
export const raise = (data) => {
  return Promise.reject(new Error(data));
};
