/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Routes } from '@gvlab/react-router';
import { styled } from '@gvlab/react-theme/styles';
import { useDialogContext } from '@gvlab/react-create-app-gv';
import { selectGvDialog } from '@gvlab/react-create-app-gv/stores';
import { baseDomain } from 'config/environment';
import { setHost } from '@gvlab/requestly';

const StyledAvatar = styled('img')(({ theme }) => ({
  width: '15vh',
  height: 'auto',
  mixBlendMode: 'color-dodge',
  filter: 'invert(1)',
}));

const Auth = ({ route, title, description }) => {
  const dialogState = useSelector(selectGvDialog);
  const dialog = useDialogContext();

  // set token for requestly client from environment
  setHost(baseDomain);

  useEffect(() => {
    if (dialogState.state) dialog.clear();
  }, [dialogState]);

  return (
    <div className="w-full h-full bg-white	bg-gradient-to-b from-base-50 to-base-900 flex flex-col flex-auto justify-center items-center ">
      <div className=" w-full h-full bg-white flex">
        <div className="relative hidden w-full lg:w-2/3 md:flex flex-col justify-center text-tertiary p-8 lg:p-16 bg-gradient-to-b from-primary-900 to-primary-500">
          <div className="space-y-4">
            <h1 className="text-5xl font-bold leading-none sm:text-6xl whitespace-normal text-gray-100">
              {title}
            </h1>
            <p className="mt-6 mb-8 text-lg sm:mb-12 whitespace-pre-line text-gray-100">
              {description}
            </p>
          </div>
          <StyledAvatar
            alt="logo"
            src="/images/logos/large.png"
            variant="rounded"
            className="space-y-4 sm:space-x-4"
          />
        </div>
        <div className="p-8 lg:p-16 w-full lg:w-1/3 flex flex-col justify-center">
          <div className="space-y-4 text-black-100 md:hidden">
            <h1 className="text-4xl font-bold leading-none whitespace-normal uppercase">{title}</h1>
          </div>
          <Routes paths={route.routes} />
        </div>
      </div>
    </div>
  );
};

export default Auth;
