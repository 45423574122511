/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { objectByString, uniqueKey } from '@gvlab/react-lib/utils';

import SelectInput from './SelectInput';

const DBSelectInput = (props) => {
  const { field } = props;
  const uniqKey = uniqueKey();

  return (
    <SelectInput {...props}>
      {field?.dataSource?.map &&
        field?.dataSource.map((dataset, uniqueId) => (
          <option key={`${uniqKey}-${uniqueId}`} value={objectByString(dataset, field.config.key)}>
            {objectByString(dataset, field.config.dataIndex)}
          </option>
        ))}
    </SelectInput>
  );
};

DBSelectInput.propTypes = {
  field: PropTypes.object.isRequired,
};

export default DBSelectInput;
