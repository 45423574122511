/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// initial state of task draft
const initialState = {
  detail: {},
  status: 'idle',
  error: null,
  showDraft: false,
  groupId: null,
};

// refetch task
export const refetch = createAsyncThunk(
  'taskDraft/refetch',
  async (request, { dispatch, rejectWithValue }) => {
    if (!request) return true;
    try {
      const { fetcher, callback } = request;
      if (!fetcher || !callback) return true;

      const response = await fetcher();
      return await callback(response);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const taskDraftSlice = createSlice({
  name: 'taskDraft',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      return {
        ...state,
        status: action.payload,
      };
    },
    clearState: (state) => {
      return {
        ...initialState,
      };
    },
    showDraft: (state, action) => {
      return {
        ...state,
        showDraft: true,
        groupId: action.payload,
      };
    },
    hideDraft: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [refetch.pending]: (state, action) => {
      return {
        ...state,
        status: 'loading',
      };
    },
    [refetch.fulfilled]: (state, action) => {
      return {
        ...state,
        status: 'succeeded',
        detail: action.payload,
      };
    },
    [refetch.rejected]: (state, action) => {
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    },
  },
});

export const { setStatus, clearState, showDraft, hideDraft } = taskDraftSlice.actions;
export default taskDraftSlice.reducer;
export const selectTaskDraftState = ({ app }) => app.dashboard.taskDraft;
