/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { safeSetValue } from '@gvlab/react-lib/utils';

const initialState = {
  state: false,
  errors: false,
  changes: {},
};

export const loopListChanges = (state, _list, _value) => {
  const itemValue = [];
  // loop config
  _list.forEach((_item) => {
    let isChange = false;
    if (state.values[_item.dataIndex]) {
      itemValue.push({
        name: _item.dataIndex,
        value: state.values[_item.dataIndex],
      });
      isChange = true;
    }

    // loop existing value
    if (isChange === false && _value) {
      _value
        .find((oldItem) => {
          return _item.dataIndex === oldItem.name;
        })
        .forEach((oldItem) => {
          itemValue.push(oldItem);
        });
    }
  });

  return itemValue;
};

const formEventSlice = createSlice({
  name: 'formEvent',
  initialState,
  reducers: {
    clearChange: (state, action) => {
      state.changes = {};
    },
    setErrors: (state, action) => {
      state.errors = safeSetValue(state.errors, action.payload);
    },
    updateChange: (state, action) => {
      const { field: _field, value: _value } = action.payload;

      const prevFieldDef = {
        ...state.changes,
        [_field.key]: _value,
      };
      state.changes = prevFieldDef;
    },
    updateValue: (state, action) => {
      const { field: _field, value: _value } = action.payload;

      const prevFieldDef = {
        ...state.fieldsDef,
      };

      Object.keys(prevFieldDef)
        .filter((ObjectId) => {
          return ObjectId === _field.key;
        })
        .forEach((ObjectId) => {
          if (prevFieldDef[ObjectId].key === _field.key) {
            const changeValue = _field?.changeValue || _value;
            prevFieldDef[ObjectId] = {
              ...prevFieldDef[ObjectId],
              value: changeValue,
              changeValue,
            };
          }
        });
      state.fieldsDef = prevFieldDef;
    },
  },
});

export const { actions } = formEventSlice;

export default formEventSlice.reducer;
