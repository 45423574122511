/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import { Routes } from '@gvlab/react-router';

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
}));

const Blank = (props) => {
  const { route, globalVariables } = props;
  const values = globalVariables || {};

  return (
    <StyledContainer>
      <Routes paths={route.routes} />
    </StyledContainer>
  );
};

Blank.propTypes = {
  route: PropTypes.object,
};

export default Blank;
