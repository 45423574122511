/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Card, Avatar } from '@gvlab/react-theme/mui';
import { colors, styled } from '@gvlab/react-theme/styles';
import SpaceUploader from '@gvlab/react-ui/components/SpaceUploader';
import { Loading } from '@gvlab/react-create-app-gv';
import { objectByString } from '@gvlab/react-lib/utils';

import InputComponentDefault from './InputComponent';

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: theme.spacing(3),
  boxShadow:
    '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
  '& .dzu-dropzone': {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    visibility: 'hidden',
  },
  '&:before': {
    position: 'absolute',
    content: '" "',
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  '&:hover': {
    '& $changeButton': {
      visibility: 'visible',
    },
    '& .dzu-dropzone': {
      visibility: 'visible',
    },
  },
}));
const StyledContainer = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backfaceVisibility: 'hidden',
}));
const StyledChangeButton = styled(SpaceUploader)(({ theme }) => ({
  visibility: 'hidden',
  position: 'absolute',
  bottom: theme.spacing(2),
  left: '25%',
  backgroundColor: colors.blueGrey[900],
  color: theme.palette.white,
  [theme.breakpoints.down('md')]: {
    bottom: theme.spacing(3),
    top: 'auto',
  },
  '&:hover': {
    backgroundColor: colors.blueGrey[900],
  },
}));

const Media = (props) => {
  const { dataSource, onChange, onGetValue } = useSelector(({ app }) => ({
    dataSource: app.form.props.options.dataSource,
    onChange: app.form.props.options.onChange,
    onGetValue: app.form.props.options.onGetValue,
  }));
  const { className, placeholder, field, onFileUpload, onSetValue, ...rest } = props;
  const [previewUrl, setPreviewUrl] = React.useState(placeholder);

  React.useMemo(() => {
    let mounted = true;
    const handleGetValue = () => {
      const getValue = objectByString(dataSource, field.dataIndex);

      if (mounted) {
        if (onGetValue) {
          onGetValue(getValue, handleSetPreviewUrl);
          return false;
        }
        handleSetPreviewUrl(getValue);
      }

      return true;
    };

    handleGetValue();

    return () => {
      mounted = false;
    };
  }, [dataSource, field.dataIndex]);

  const handleError = () => {
    setPreviewUrl(placeholder);
  };

  const handleFileLoad = (params) => {
    setPreviewUrl(params.meta.previewUrl);
    if (onFileUpload) onFileUpload(params);
  };

  const handleSetPreviewUrl = async (imageUrl) => {
    let fullPath = imageUrl;
    if (onSetValue) {
      fullPath = onSetValue(imageUrl);
    }
    const newImage = fullPath || placeholder;
    setPreviewUrl((prev) => {
      return prev !== newImage ? newImage : prev;
    });
    return true;
  };

  if (!previewUrl) return <Loading />;

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledAvatar
          component="div"
          id={field.key}
          image={previewUrl}
          name={field.key}
          onError={handleError}
          variant="rounded"
          imgProps={{
            onError: handleError,
          }}
          src={previewUrl}
          {...rest}
        />
      </StyledContainer>
      {!field?.readonly && (
        <StyledChangeButton
          {...rest}
          InputComponent={InputComponentDefault}
          onFileUpload={handleFileLoad}
        />
      )}
    </StyledRoot>
  );
};

Media.defaultProps = {
  placeholder: '/images/avatars/default.png',
};

Media.propTypes = {
  dataSource: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onFileUpload: PropTypes.func,
  onGetValue: PropTypes.func,
  onSetValue: PropTypes.func,
  placeholder: PropTypes.string,
};

export default React.memo(Media);
