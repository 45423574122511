/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { isFunction } from '../common';

const axiosFetcher = (options) => {

  const errorHandler = async (error, Reject) => {
    try {
      // Axios Error
      if (isFunction(error.toJSON)) {
        if (Array.isArray(error?.response?.data?.errors)) {
          const err = error?.response?.data?.errors[0];
          return Reject({
            message: err,
            code: err?.code || '',
            status: err?.status || error?.response?.status || 500,
          });
        }
        const status = error?.response?.status || 500;
        return Reject({
          message: error?.response?.data,
          status,
          code: status || '',
        });
      }

      return Reject(error);
    } catch (e) {
      return Reject(e);
    }
  };

  const request = async ({
    url,
    params,
  }) => {
    return new Promise((Resolve, Reject) => {
      axios(options)
        .then((result) => {
          if (!result.ok && result?.status !== 200) throw result;
          if (options?.noResolveJson) return result;

          if (isFunction(result.toJSON)) {
            return result.toJSON();
          }

          return result?.data || result;
        })
        .then((data) => {
          return Resolve(data);
        })
        .catch((error) => {
          return errorHandler(error, Reject);
        });
    });
  };

  return {
    request,
  }
};

export default axiosFetcher;
