import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@gvlab/react-theme/styles';
import { Button } from '@gvlab/react-theme/mui';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { FilterList as FilterListIcon } from '@gvlab/react-icons';

import { Search, Filter } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
  },
  filterButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
}));

const SearchBar = (props) => {
  const { onFilter, onSearch, className, ...rest } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <GridContainer {...rest} className={clsx(classes.root, className)} spacing={3}>
      <GridItem>
        <Search className={classes.search} onSearch={onSearch} />
      </GridItem>
      <GridItem>
        <Button
          // className={classes.filterButton}
          // color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant="contained"
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
      </GridItem>
      <Filter onClose={handleFilterClose} onFilter={onFilter} open={openFilter} />
    </GridContainer>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchBar;
