/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { useLocation } from '@gvlab/react-router';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

function Page(props) {
  const location = useLocation();
  const { title, children, ...rest } = props;

  // useEffect(() => {

  if (NODE_ENV === 'production' && window.gtag) {
    window.gtag('config', GA_MEASUREMENT_ID, {
      page_path: location.state?.from?.pathname,
      page_name: title,
    });
  }
  // }, []);

  return (
    <div {...rest}>
      <Helmet>
        {title}
      </Helmet>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
