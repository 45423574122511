/* eslint-disable import/no-extraneous-dependencies */
import { Dialog as MuiDialog } from '@gvlab/react-theme/mui';

const Dialog = ({ children, ...rest }) => {
  return (
    <MuiDialog {...rest} maxWidth="sm">
      {children}
    </MuiDialog>
  );
};

export default Dialog;
