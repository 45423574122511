/* eslint-disable import/no-anonymous-default-export */

const StyledComponent = {
  styleOverrides: {
    fontFamily: '"Raleway","Roboto","Helvetica","Arial",sans-serif',
    htmlFontSize: 10,
    fontSize: 13,
    root: {
      fontFamily: '"Raleway","Roboto","Helvetica","Arial",sans-serif',
      htmlFontSize: 10,
      fontSize: 13,
    },
    h1: {
      fontWeight: 300,
      fontSize: '3.5rem',
      letterSpacing: '1px',
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 300,
      fontSize: '3.5rem',
      lineHeight: 1.375,
      letterSpacing: '1px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.25rem',
      lineHeight: 1.375,
    },
    h4: {
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: 1.375,
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.375,
    },
    gutterBottom: {
      marginBottom: 8,
    },
  },
};

export default StyledComponent;
