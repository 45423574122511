/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.icon,
      minWidth: 32,
    },
  },
};
