/* eslint-disable import/named */
/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  datastoreReducers,
  resetDataStore,
} from './datastore/datastore';
import {
  helpersReducers,
} from './datastore/helpers';

export { 
  initialDataStore,
  resetDataStore,
  insertDataStore,
  updateDataStore,

  setDataStore,
} from './datastore/datastore';

export {
  create,
  read,
  update,
  remove,
} from './datastore/helpers';


// Global datastore
const defaultDataState = 'PENDING';

export const setDataStoreState = createAsyncThunk(
  'datastore/setDataStoreState',
  async ({ dataSource: datasource, state }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = getState().app.datastore.collections?.[datasource];

    // if not found initialize it
    if (!prevState) {
      dispatch(resetDataStore({ dataSource: datasource }));
    }

    // get dataStore from dataSource
    const prevData = getState().app.datastore.collections?.[datasource]?.data || [];
    const prevMeta = getState().app.datastore.collections?.[datasource]?.meta || {};
    const prevTotal = getState().app.datastore.collections?.[datasource]?.meta?.total || 0;

    // merge data
    const data = [...prevData];
    const meta = { ...prevMeta, state };

    // return dataStore
    return { dataSource: datasource, value: { data, meta }, state };
  }
);

const datastoreSlice = createSlice({
  name: 'datastore',
  initialState: {
    collections: {},
  },
 
  extraReducers: {
    [setDataStoreState.fulfilled]: (state, action) => {
      const dataSource = action.payload?.dataSource;
      const stateValue = action.payload?.state;

      const prevState = state.collections?.[dataSource];

      state.collections[dataSource] = {
        // default or initial values
        ...prevState,
        state: stateValue || prevState.state || defaultDataState,
      };
    },

    ...datastoreReducers,
    ...helpersReducers,
  },
});

export const { actions } = datastoreSlice;

export default datastoreSlice.reducer;