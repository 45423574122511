/* eslint-disable import/no-extraneous-dependencies */
import validate from 'validate.js';
import { useSelector } from 'react-redux';
import Requestly from '@gvlab/requestly';

import { selectGvAuthToken } from '@gvlab/react-create-app-gv/stores';

import { selectDatasourceOptions } from '../../store/datasourceSlice';


const useModel = (options) => {
  const datasource = useSelector(selectDatasourceOptions);
  const token = useSelector(selectGvAuthToken);

  const requestly = Requestly({ token, host: datasource.host });

  return {
    ...requestly,
    validate,
  };
};

export default useModel;
