import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import Menu from './Menu';
import MenuItem from './MenuItem';

const StyledMenus = styled(Menu)(({ theme }) => ({
  padding: theme.spacing(2),
  '& overline': {
    color: theme.palette.primary.main,
  },
}));

const MainMenu = ({ className, menus }) => {
  if (!menus || menus.length === 0) return null;

  return (
    <StyledMenus className={className}>
      {menus.map((list, key) => (
        <MenuItem component="div" key={key} pages={list?.pages} title={list.title} />
      ))}
    </StyledMenus>
  );
};

MainMenu.propTypes = {
  className: PropTypes.string,
  menus: PropTypes.array.isRequired,
};

export default MainMenu;
