import { createSlice } from '@reduxjs/toolkit';

// initial state of chat detail
const initialState = {
  state: 'close',
  success: false,
  errors: false,
  detail: null,
};

const lightBoxSlice = createSlice({
  name: 'lightbox',
  initialState,
  reducers: {
    show: (state, action) => {
      state.state = 'show';
      state.detail = action.payload;
      state.success = false;
      state.errors = [];
    },
    close: (state, action) => {
      state.state = initialState.state;
      state.detail = initialState.detail;
      state.success = initialState.success;
      state.errors = initialState.errors;
    },
  },
  extraReducers: {},
});

export const { show, close } = lightBoxSlice.actions;
export const selectLightBox = ({ app }) => app.lightBox;

export default lightBoxSlice.reducer;
