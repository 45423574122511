/* eslint-disable import/no-extraneous-dependencies */
import { authTokensSlice, setTokens, setError } from '@gvlab/react-session';
import { combineReducers } from '@reduxjs/toolkit';
import authUserSlice, { setUserData } from './authUserSlice';
import authMenusSlice, { setMenu } from './authMenusSlice';

export { Logout } from './authSlice';

export const selectGvAuth = ({ gv }) => gv.auth;
export const selectGvError = ({ gv }) => gv.auth.error;

export const selectGvUserState = ({ gv }) => gv.auth.user;
export const selectGvUser = ({ gv }) => gv.auth.user.detail;

export const selectGvMenusState = ({ gv }) => gv.auth.menus;
export const selectGvMenus= ({ gv }) => gv.auth.menus.menus;

export const selectGvState = ({ gv }) => gv.auth.state;
export const selectGvTokens = ({ gv }) => gv.auth.tokens;
export const selectGvAuthToken = ({ gv }) => gv.auth.tokens.token;
export const selectGvAuthRefreshToken = ({ gv }) => gv.auth.tokens.refreshToken;

export { setUserData, setTokens, setError, setMenu };

const authReducer = combineReducers({
  tokens: authTokensSlice,
  user: authUserSlice,
  menus: authMenusSlice,
});

export default authReducer;