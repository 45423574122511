/* eslint-disable import/no-extraneous-dependencies */
import Provider from 'react-redux/es/components/Provider';
import { BrowserRouter } from 'react-router-dom';

import { Routes } from '@gvlab/react-router';
import { CustomThemeProvider } from '@gvlab/react-theme/themes';
import { MomentUtils } from '@gvlab/react-lib/utils';
import { UtilsProvider } from '@gvlab/react-lib/utils/UtilsProvider';
import { InitialGlobalState } from '@gvlab/react-lib/Global/GlobalState';
import { LocalizationProvider } from '@gvlab/react-theme/mui';

import '@gvlab/react-ui/mixins/chartjs';
import '@gvlab/react-ui/mixins/moment';
import '@gvlab/react-ui/mixins/validate';
import '@gvlab/react-ui/mixins/prismjs';

import { DialogProvider, ScrollReset, GoogleAnalytics } from '..';
import ErrorBoundary from './ErrorBoundary';
import { reduxStore } from '../Redux/store';
import EventEmitter from '../Event/EventEmitter';

// BaseApp Core
const BaseApp = ({ routes }) => {
  InitialGlobalState();

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      {/* Redux Store */}
      <Provider store={reduxStore}>
        {/* theme  */}
        <CustomThemeProvider>
          <ErrorBoundary>
            <UtilsProvider utils={{events: EventEmitter}}>
              <BrowserRouter>
                <Routes paths={routes} />
                <ScrollReset />
                <GoogleAnalytics />
                <DialogProvider />
              </BrowserRouter>
            </UtilsProvider>
          </ErrorBoundary>
        </CustomThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
};

export default BaseApp;
