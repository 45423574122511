import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { clientType } from 'config/environment';

const initialState = {
  state: 'otp',
  success: false,
  errors: false,
  detail: null,
};

export const resend = createAsyncThunk(
  'verify/resend',
  async ({ email }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .post('/user/v1/user/resend', {
          email,
          factorType: 'EMAIL',
          userType: clientType,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verify = createAsyncThunk(
  'verify/confirm',
  async ({ email, password, passcode }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .post('/user/v1//user/verify', {
          email,
          password,
          passcode,
          userType: clientType,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const init = createAsyncThunk('verify/init', async (_, { dispatch }) => 'done');

// create slice for register verification
const verifySlice = createSlice({
  name: 'verify',
  initialState,
  reducers: {},
  extraReducers: {
    [resend.fulfilled]: (state, action) => {
      return { ...state, state: 'otp', success: true };
    },
    [resend.rejected]: (state, action) => {
      return { ...initialState, state: 'error', errors: action.payload };
    },
    [verify.pending]: (state, action) => {
      return { ...state, state: 'otp-pending' };
    },
    [verify.fulfilled]: (state, action) => {
      return { ...initialState, state: 'success', success: true, detail: action.payload };
    },
    [verify.rejected]: (state, action) => {
      return { ...state, state: 'otp-error', errors: action.payload };
    },
    [init.fulfilled]: (state) => {
      return { ...initialState };
    },
  },
});

export default verifySlice.reducer;

export const selectAuthen = ({ app }) => app.auth.verify;
