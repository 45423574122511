/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import BaseField from './BaseField';

/**
 * sample config
 * 
  {
  title: 'Platform',
  component: 'Select',
  options: [
    {
      value: ' ',
      label: 'Please select the platform'
    },
    {
      value: 'mbb',
      label: 'MBB2U',
    },
    {
      value: 'tnb',
      label: 'TNB',
    },
    {
      value: 'm1',
      label: 'M1',
    },
  ],
  dataIndex: 'attributes.platform',
  key: 'platform',
}, 
 */
const SelectInput = (props) => {
  const { onChange, onFormItemGetValue } = useSelector(({ app }) => ({
    onChange: app.form.props.options.onChange,
    onFormItemGetValue: app.form.props.options.onFormItemGetValue,
  }));
  const { field, children, ...rest } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    const retrieveValue = () => {
      try {
        setValue(onFormItemGetValue(props));
      } catch (error) {
        setValue('');
      }
    };

    retrieveValue();
  }, []);

  const handleFieldChange = (_field, _value) => {
    setValue(_value);
    if (onChange) onChange(_field, _value, _field.dataSource);
  };

  return (
    <BaseField
      {...rest}
      SelectProps={{ native: true }}
      field={field}
      select
      value={value}
      onChange={handleFieldChange}
    >
      <option value=" ">Please select {field?.title}</option>
      {!children &&
        field.getOptions().map((option) => {
          return (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          );
        })}
      {children}
    </BaseField>
  );
};

SelectInput.propTypes = {
  children: PropTypes.node,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default SelectInput;
