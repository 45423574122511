/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@gvlab/react-theme/styles';
import { CardContent, TextField, FormControl } from '@gvlab/react-theme/mui';
import { GridItem, Button } from '@gvlab/react-ui/components';

export const Root = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
  '& > *': {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '45vw',
    },
  },
}));

export const FormDetailRoot = styled(CardContent)(({ theme }) => ({
  margin: theme.spacing(1),
  color: theme.palette.secondary.contrastText,
  '& > *': {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '45vw',
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& > .Mui-disabled': {
    backgroundColor: theme.palette.white,
  },
}));

export const RadioField = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& > .Mui-disabled': {
    backgroundColor: theme.palette.white,
  },
  '& .MuiFormLabel-root': {
    color: 'rgba(0, 0, 0, 0.38)',
    zoom: '90%',
  },
}));

export const StyledButton = styled(Button)`
  margin-left: 'auto';
`;

export const CancelButton = styled(Button)``;

export const ConfirmButton = styled(Button)``;

export const CustomLabel = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white,
}));

export const Grow = styled('div')`
  flex-grow: 1;
`;

export const StyledGridItem = styled(GridItem)`
  align-self: 'flex-end';
`;
