const currencyFormat = (num, decimal = 2, thousandSeparator = ',') => {
  try {
    return Number(num)
      .toFixed(decimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`);
  } catch (e) {
    return num;
  }
};

const currencyFormatDE = (num) => {
  return `${Number(num)
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} €`; // use . as a separator
};

const numberFormat = (num) => {
  try {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } catch (e) {
    return num;
  }
};

const numberUtils = {
  currencyFormat,
  currencyFormatDE,
  numberFormat
};

export default numberUtils;