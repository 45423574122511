/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction } from '@gvlab/react-lib/utils';

const defaultPageSize = 10;
const defaultDataState = 'PENDING';
export const checkExist = (prev, res) => {
  return prev.id === res.data.id ? res.data : prev;
};

export const initialDataStore = {
  data: [],
  meta: {
    page: 0,
    totalPages: 0,
    size: defaultPageSize,
    total: 0,
  },
  state: defaultDataState,
};

export const formattedMeta = (prevState, newValue) => {
  const pageSize = newValue?.meta?.size || prevState.meta.size;
  const totalRecords = newValue?.meta?.total || prevState.meta.total;
  const totalPages = (newValue?.data && newValue.data.length ) && 
    totalRecords > pageSize ? Math.ceil(totalRecords / pageSize) : 1;
  return {
    ...prevState.meta,
    page: newValue?.meta?.page || prevState.meta.page,
    totalPages,
    size: pageSize,
    total: totalRecords,
  }
}

export const isDataStoreExist = (collections, dataSource) => {
  // if not found initialize it
  if (!collections?.[dataSource]) {
    return {
      ...collections,
      [dataSource]: initialDataStore,
    };
  }
  return collections;
};

export const resetDataStore = createAsyncThunk(
  'datastore/resetDataStore',
  async ({ dataSource: datasource }, thunkAPI) => {
    return { dataSource: datasource, value: initialDataStore };
  }
);

export const insertDataStore = createAsyncThunk(
  'datastore/insertDataStore',
  async ({ dataSource: datasource, value }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = getState().app.datastore.collections?.[datasource];

    // if not found initialize it
    if (!prevState) {
      dispatch(resetDataStore({ dataSource: datasource }));
    }

    // get dataStore from dataSource
    const prevData = getState().app.datastore.collections?.[datasource]?.data || [];
    const prevMeta = getState().app.datastore.collections?.[datasource]?.meta || {};
    const prevTotal = getState().app.datastore.collections?.[datasource]?.meta?.total || 0;

    // get data from value
    const newData = value?.data || [];
    const newMeta = value?.meta || {};

    // merge data
    const data = [...prevData, ...newData];
    const meta = formattedMeta({ meta: prevMeta }, { meta: newMeta });

    // update total
    meta.total = data.length;

    // return dataStore
    return { dataSource: datasource, value: { data, meta } };
  }
);

export const updateDataStore = createAsyncThunk(
  'datastore/updateDataStore',
  async ({ dataSource: datasource, value }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = getState().app.datastore.collections?.[datasource];

    // if not found initialize it
    if (!prevState) {
      dispatch(resetDataStore({ dataSource: datasource }));
    }

    // get dataStore from dataSource
    const prevData = getState().app.datastore.collections?.[datasource]?.data || [];
    const prevMeta = getState().app.datastore.collections?.[datasource]?.meta || {};
    const prevTotal = getState().app.datastore.collections?.[datasource]?.meta?.total || 0;

    // get data from value
    const newData = value?.data || [];
    const newMeta = value?.meta || {};

    // merge data
    const data = [...prevData, ...newData];
    const meta = formattedMeta({ meta: prevMeta }, { meta: newMeta });

    // update total
    meta.total = data.length;

    // return dataStore
    return { dataSource: datasource, value: { data, meta } };
  }
);

export const setDataStore = createAsyncThunk(
  'datastore/setDataStore',
  async ({ dataSource: datasource, value }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = getState().app.datastore.collections?.[datasource];

    // if not found initialize it
    if (!prevState) {
      dispatch(resetDataStore({ dataSource: datasource }));
    }

    // get dataStore from dataSource
    const prevData = getState().app.datastore.collections?.[datasource]?.data || [];
    const prevMeta = getState().app.datastore.collections?.[datasource]?.meta || {};
    const prevTotal = getState().app.datastore.collections?.[datasource]?.meta?.total || 0;

    // get data from value
    const newData = value?.data || [];
    const newMeta = value?.meta || {};

    // merge data
    const data = [...prevData, ...newData];
    const meta = formattedMeta({ meta: prevMeta }, { meta: newMeta });
   
    // return dataStore
    return { dataSource: datasource, value: { data, meta } };
  }
);

export const datastoreReducers = {
  [setDataStore.fulfilled]: (state, action) => {
    const dataSource = action.payload?.dataSource;
    const newValue = action.payload?.value;
    const stateValue = action.payload?.state;

    const prevState = state.collections?.[dataSource];

    state.collections[dataSource] = {
      // default or initial values
      ...prevState,
      data: [...(newValue?.data || prevState.data)],
      meta: formattedMeta(prevState, newValue),
      state: stateValue || prevState.state || defaultDataState,
    };
  },

   // Reset DataStore
   [resetDataStore.fulfilled]: (state, action) => {
    const dataSource = action.payload?.dataSource;

    state.collections[dataSource] = initialDataStore;
  },

  [insertDataStore.fulfilled]: (state, action) => {
    const dataSource = action.payload?.dataSource;
    const newValue = action.payload?.value;

    state.collections = isDataStoreExist(state.collections, dataSource);

    // get dataStore from dataSource
    const prevState = state.collections?.[dataSource];

    // if newValue not data just ignore
    if (!newValue?.data) {
      state.collections[dataSource] = {
        // default or initial values
        ...prevState,
        data: [...(newValue?.data || prevState.data)],
        meta: formattedMeta(prevState, newValue),
      };
    }
  },
  [updateDataStore.fulfilled]: (state, action) => {
    const dataSource = action.payload?.dataSource;
    const newValue = action.payload?.value;
    let onCheckExist = checkExist;

    state.collections = isDataStoreExist(state.collections, dataSource);

    // get dataStore from dataSource
    const prevState = state.collections?.[dataSource];

    if (isFunction(action.payload?.cbCheck)) {
      onCheckExist = action.payload.cbCheck;
    }

    // if newValue not data just ignore
    if (!newValue?.data) {
      const newData = prevState.data.map((e) => onCheckExist(e, newValue));

      state.collections[dataSource] = {
        // default or initial values
        ...prevState,
        data: [...(newData || prevState.data)],
        meta: formattedMeta(prevState, newValue),
      };
    }
  },
};