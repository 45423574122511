/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { safeSetValue } from '@gvlab/react-lib/utils';

// application setting slice 
const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    state: false,
    current: {
      theme: 'light',
      direction: 'ltr',
      customScrollbars: true,
      layout: {
        style: 'layout1',
        config: {
          scroll: 'content',
          navbar: {
            display: true,
            folded: false,
            position: 'left',
          },
          toolbar: {
            display: true,
            style: 'fixed',
            position: 'below',
          },
          footer: {
          },
        },
      },
    },
  },
  reducers: {
    setSetting: (state, action) => {
      state.current = safeSetValue(state.current, action.payload);
    }
  },
});

export const { setSetting } = settingSlice.actions;

export const selectSetting = () => ({gv}) => gv.setting;
export const selectSettingCurrent = () => ({gv}) => gv.setting.current;

export default settingSlice.reducer;
