/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.paper.contrastText,
      backgroundColor: palette.paper.main,
    },
    input: {
      '&::placeholder': {
        opacity: 1,
        color: palette.paper.contrastText,
        backgroundColor: palette.paper.main,
      },
    },
  },
};
