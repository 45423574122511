/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      border: '1px solid red',
    },
    colorPrimary: {
      backgroundColor: palette.colors.blueGrey[50],
    },
  },
};
