import { useHistory } from 'react-router-dom';

export {
  Link,
  useLocation,
  useHistory,
  useRouteMatch as useMatch,
  matchPath,
  Redirect,
} from 'react-router-dom';

export const useNavigate = (url) => {
  const history = useHistory();
  if (history) return history.push(url);

  return window.location.replace(url);
};

export const useResolvedPath = () => {
  return window.location.pathname;
};

// GV Routes
export {
  RenderRoutes,
  Routes,
  Routes as GvRoutes,
  RenderRoutesWithSubRoutes,
  RouteWithSubRoutes,
} from './renderRoutes';
