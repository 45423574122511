import palette from './palette';

const StyledComponent = {
  fontFamily: '"Raleway","Roboto","Helvetica","Arial",sans-serif',
  htmlFontSize: 10,
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: 1.375,
  },
  h2: {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: 1.375,
  },
  h3: {
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: 1.375,
  },
  h4: {
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: 1.375,
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: 1.375,
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: 1.375,
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.75,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.57,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.57,
  },
  body3: {
    fontSize: '0.75rem',
    lineHeight: 1.57,
  },
  button: {
    fontWeight: 600,
  },
  button2: {
    fontSize: '14px',
    fontWeight: 600,
    confirm: {
      color: palette.white,
      backgroundColor: palette.colors.green[600],
      '&:hover': {
        backgroundColor: palette.colors.green[900],
      },
    },
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
};

export default StyledComponent;
