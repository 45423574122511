import { colors } from '../../mui';

const white = '#FFFFFF';
const black = '#212121';
// const cyan

const StyledComponent = {
  colors,
  black,
  white,
  neutral: {
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  action: {
    active: '#6B7280',
    focus: 'rgba(55, 65, 81, 0.12)',
    hover: 'rgba(55, 65, 81, 0.04)',
    selected: 'rgba(55, 65, 81, 0.08)',
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)',
  },
  background: {
    default: '#334456',
    paper: white,
    main: white,
  },
  divider: '#eeeeee',
  primary: {
    contrastText: 'rgba(255, 255, 255, 0.8)',
    contrastText2: white,
    main: '#0f5598',
    dark: '#002d69',
    light: '#5281c9',
  },
  secondary: {
    contrastText: black,
    main: '#f9fafb',
    dark: '#a8a8a8',
    light: '#ffffff',
  },
  // grey (light)
  tertiary: {
    contrastText: black,
    main: '#91d5ff',
    dark: '#5ea4cc',
    light: '#c5ffff',
  },
  error: {
    contrastText: black,
    main: '#e5391c',
    dark: '#d33939',
    light: '#f25454',
  },
  success: {
    contrastText: black,
    main: '#3fb71d',
    dark: '#0ec254',
    light: '#28e070',
  },
  info: {
    main: '#2196F3',
    light: '#64B6F7',
    dark: '#0B79D0',
    contrastText: '#FFFFFF',
  },
  warning: {
    contrastText: black,
    main: '#ffce00',
    dark: '#e0b500',
    light: '#ffd31a',
  },
  dark: {
    main: '#222428',
    dark: '#1e2023',
    light: '#383a3e',
  },
  medium: {
    main: '#DBDBDB',
    dark: '#f7f7f7',
    light: '#EFEFEF',
  },
  light: {
    main: '#f4f5f8',
    dark: '#d7d8da',
    light: '#f5f6f9',
  },
  /// /////////// component level ////////
  paper: {
    contrastText: black,
    main: '#f7f7f7',
    dark: '#acacac',
    light: '#f8f8f8',
  },
  text: {
    primary: white,
    secondary: black,
    link: black,
  },
  link: black,
  icon: black,
};

export default StyledComponent;
