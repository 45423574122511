import { createTheme } from '../../styles';
import { overrides as baseComponents, base } from '../base';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createTheme({
  ...base,
  palette,
  typography,
  components: {
    ...baseComponents,
    ...overrides,
  },
});

export { palette, theme };
