/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';

const FormContainer = props => {
  const { customContainer: CustomContainer } = useSelector(({ app }) => ({
    customContainer: app.form.props.options.customContainer,
  }));

  // CustomContainer User define container 
  if (CustomContainer) {
    return <CustomContainer>{props.children}</CustomContainer>;
  }

  return props.children;
};

export default FormContainer;
