/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { renderComponent, safeGetValue } from '@gvlab/react-lib/utils';

const CardHeader = (props) => {
  const { action, avatar, className, position, title, subtitle } = props;

  return (
    <div className={clsx("card-header text-gray-600 bg-slate-200 z-10", className)}>
      <div className="flex items-center space-x-2">
        <div className="py-1">{renderComponent(avatar)}</div>
        {/* <img src="https://source.unsplash.com/50x50/?portrait" alt="" className="object-cover object-center w-8 h-8 rounded-full shadow-sm bg-gray-500 border-gray-300" /> */}
        <div className="-space-y-1">
          <h2 className="text-xl font-semibold leading-none">{safeGetValue(title)}</h2>
          {subtitle && (<span className="inline-block text-xs leading-none text-gray-600">{safeGetValue(subtitle)}</span>)}
        </div>
      </div>
      <div>
        {renderComponent(action)}
      </div>
      {/* <button title="Open options" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-5 h-5 fill-current">
          <path d="M256,144a64,64,0,1,0-64-64A64.072,64.072,0,0,0,256,144Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,256,48Z" />
          <path d="M256,368a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,368Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,464Z" />
          <path d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z" />
        </svg>
      </button> */}
    </div>
  );
};

CardHeader.defaultProps = {
  position: 'static',
};

CardHeader.propTypes = {
  position: PropTypes.string,
};

export default CardHeader;
