/* eslint-disable import/no-extraneous-dependencies */
import { useSelector } from 'react-redux';

import { CardActions } from '@gvlab/react-theme/mui';
import FormActions from './FormActions';
import { Grow } from '../styles';

const FormToolbar = () => {
  const { variant } = useSelector(({ app }) => ({
    variant: app.form.props.options.variant,
  }));

  if (variant === 'drawer') return null;
  return (
    <CardActions>
      <Grow />
      <FormActions />
    </CardActions>
  );
};

export default FormToolbar;
