import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { show, close } from 'store/loadingSlice';

// export invoice from API and return response data to reducer
export const exportPdf = createAsyncThunk(
  'invoiceExport/pdf',
  async ({ invoicesId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(show({ message: 'Exporting invoice...' }));

      return await Requestly({ noResolveJson: true })
        .get(`/user/v1/invoices/${invoicesId}/download?userType=pro`)
        .then((response) => {
          if (!response?._bodyBlob) {
            return false;
          }

          // writ to stream
          // const blob = new Blob([response], { type: 'application/pdf' });
          const blob = response._bodyBlob;
          const url = window.URL.createObjectURL(blob);

          // open in new tab
          window.open(url, '_blank');
          return true;
        })
        .then((success) => {
          dispatch(close());
          return success;
        })
        .catch((error) => {
          dispatch(close());
          throw error;
        });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

// initial state of order detail
const initialState = {
  state: 'PENDING',
  success: false,
  errors: false,
  detail: null,
};

const invoiceExportSlice = createSlice({
  name: 'invoiceExport',
  initialState,
});

// export order detail slice reducer
export default invoiceExportSlice.reducer;
