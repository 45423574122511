import { createGlobalState } from '../hooks';

// Global State Machine
export const initialState = {
  dataStore: {
    // table1: {},
    // table2: {},
    // table3: {},
  },
  onEvent: [],
  session: {},
  custom: {},
  isNewModal: false,
};

// Global datastore
const defaultPageSize = 10;
const defaultDataState = true;
export const initialDataStore = {
  data: [],
  meta: {
    page: 0,
    size: defaultPageSize,
    total: 0,
  },
  isLoading: defaultDataState,
};

let GlobalStateHandle;

export function InitialGlobalState() {
  if (GlobalStateHandle) {
    throw new Error('GlobalStateHandle already initial');
  }

  GlobalStateHandle = createGlobalState(initialState);
  return GlobalStateHandle;
}

// encapsulation
export function getGlobalState(stateKey) {
  if (!GlobalStateHandle) {
    throw new Error('GlobalStateHandle must be used initial');
  }

  return GlobalStateHandle.getGlobalState(stateKey);
}

// encapsulation
export function setGlobalState(stateKey, update) {
  if (!GlobalStateHandle) {
    throw new Error('GlobalStateHandle must be used initial');
  }

  return GlobalStateHandle.setGlobalState(stateKey, update);
}

// encapsulation
export function useGlobalState(stateKey) {
  if (!GlobalStateHandle) {
    throw new Error('GlobalStateHandle must be used initial');
  }

  GlobalStateHandle.useGlobalState(stateKey);
}
