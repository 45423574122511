/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
export const isObject = (value) => value !== null && typeof value === 'object';
export const isFunction = (value) => typeof value === 'function';
export const isString = (value) => typeof value === 'string';
export const isBoolean = (value) => typeof value === 'boolean';
export const isNumber = (value) => typeof value === 'number';
export const isUndef = (value) => typeof value === 'undefined';
export const isArray = (value) => typeof value === 'object' && Array.isArray(value);
export const _typeof = (obj) => {
  let __typeof;
  if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
    __typeof = (_obj) => {
      return typeof _obj;
    };
  } else {
    __typeof = (_obj) => {
      return _obj &&
        typeof Symbol === 'function' &&
        _obj.constructor === Symbol &&
        _obj !== Symbol.prototype
        ? 'symbol'
        : typeof _obj;
    };
  }

  return __typeof(obj);
};
export const isId = (n) => {
  return !!(n && String(n).match(/^[0-9]+/));
};
export const isUUID = (v) => {
  return (
    isString(v) &&
    v.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
  );
};

export function safeGetValue(patch) {
  return isFunction(patch) ? patch() : patch;
}

export function safeSetValue(prevState, patch) {
  const newState = isFunction(patch) ? patch(prevState) : patch;
  if (isObject(prevState)) {
    return newState ? { ...prevState, ...newState } : prevState;
  }
  return newState;
}

export const renderComponent = (CustomComponent) => {
  if (CustomComponent == null ) return <></>;
  if (isFunction(CustomComponent)) return CustomComponent();
  if (isString(CustomComponent)) return <>{CustomComponent}</>;
  if (isObject(CustomComponent)) return CustomComponent;
};

// safeCallEvent safe call event function 
export function safeCallEvent(prevState, patch) {
  return isFunction(patch) ? patch(prevState) : prevState;
}
