/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@gvlab/react-theme/mui';
import { safeSetValue } from '@gvlab/react-lib/utils';
import { setHost, setToken } from '@gvlab/requestly';

import { GlobalContext } from './GlobalContext';
import { setGlobalState, getGlobalState } from './GlobalState';

import EventHelper from './EventHelper';
import GlobalValueHelper from './GlobalValueHelper';

const GlobalProviderMiddleware = {};

// create global provider middleware
export const createGlobalProviderMiddleware = (name, middleware) => {
  GlobalProviderMiddleware[name] = middleware;
};

// Global Provider for global environment 
export const GlobalProvider = (props) => {
  const eventHelper = EventHelper();
  const globalValueHelper = GlobalValueHelper();
  // get token and host from redux store
  const { reduxToken, reduxHost } = useSelector(({app, gv}) => ({
    reduxToken: gv.auth.tokens.token,
    reduxHost: app.datasource.options.host,
  }));

  // set token and host to requestly
  setHost(reduxHost);
  setToken(reduxToken);

  // check mobile mode
  const isMobileMode = useMediaQuery('(max-width:625px)');

  const setIsNewModal = (patch) => {
    setGlobalState('isNewModal', (preState) => safeSetValue(preState, patch));
  };

  const isNewModal = () => {
    return getGlobalState('isNewModal');
  };

  const value = useMemo(
    () => ({
      ...props.value,
      // load global provider middleware
      ...GlobalProviderMiddleware,

      eventHelper,
      globalValueHelper,

      isNewModal,
      setIsNewModal,

      isMobileMode,
    }),
    [],
  );

  return <GlobalContext.Provider value={value}>{props.children}</GlobalContext.Provider>;
};
