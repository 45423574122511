/* eslint-disable import/no-extraneous-dependencies */
import { Button, Divider, Hidden } from '@gvlab/react-theme/mui';
import MainMenu from '@gvlab/react-ui/components/Menu/MainMenu';
import Menu from '@gvlab/react-ui/components/Menu/Menu';
import { Input as InputIcon } from '@gvlab/react-icons';
import { useGlobalContext } from '@gvlab/react-lib/Global';
import navigationConfig from 'config/navigationConfig';
import { styled } from '@gvlab/react-theme/styles';

const StyledContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
  backgroundColor: 'red', // theme.palette.background.default,
}));
const StyledProfile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'fit-content',
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledLogo = styled('img')(({ theme }) => ({
  color: 'black',
  filter: 'invert(1)',
  height: 32,
}));
const StyledNavigation = styled(Menu)(({ theme }) => ({
  padding: theme.spacing(2),
  '& overline': {
    color: theme.palette.primary.main,
  },
}));
const StyledLogoutButton = styled(Button)(({ theme }) => ({}));
const StyledLogoutButtonIcon = styled(InputIcon)(({ theme }) => ({}));

const NavbarContent = (props) => {
  const { authCtx } = useGlobalContext();

  return (
    <StyledContent>
      <StyledProfile>
        <StyledLogo alt="Logo" src="/images/logos/large.png" />
      </StyledProfile>
      <StyledDivider />
      <MainMenu menus={navigationConfig} />
      <Hidden mdUp>
        <StyledDivider />
        <StyledNavigation>
          <StyledLogoutButton
            color="inherit"
            onClick={() => {
              authCtx.logout();
            }}
          >
            <StyledLogoutButtonIcon />
            Sign out
          </StyledLogoutButton>
        </StyledNavigation>
      </Hidden>
    </StyledContent>
  );
};

export default NavbarContent;
