import React from 'react';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import { Card, CardHeader, CardMedia, CardContent, CardActions } from '@gvlab/react-theme/mui';
import Button from '@gvlab/react-ui/components/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
  },
  header: {
    backgroundColor: theme.palette.background.main,
  },
  media: {
    height: '180px',
    borderRadius: '90px',
    backgroundColor: 'black',
    objectFit: 'contain',
    width: '180px',
    border: '1px solid #eee',
    margin: '10px auto',
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
  },
}));

const GridViewItem = (props) => {
  const { dataset, onView } = props;
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState(false);
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  return (
    <Card className={classes.root}>
      <CardHeader
        // avatar={
        //   <Avatar aria-label="recipe" className={classes.avatar}>
        //     R
        //   </Avatar>
        // }
        // action={
        //   <IconButton
        //     aria-label="settings"
        //     onClick={() => { onView && onView(dataset)}}
        //   >
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        // title="Shrimp and Chorizo Paella"
        // subheader="September 14, 2016"
        className={classes.header}
      />
      <CardMedia
        className={classes.media}
        image="/images/avatars/avatar_1.png"
        title="Paella dish"
      />
      <CardContent>
        <Typography align="center" color="textSecondary" variant="h3">
          {dataset.coreId.name || ''}
        </Typography>
        <Typography align="center" color="textSecondary" variant="body2">
          Joined since May 2020
        </Typography>
        <Typography align="center" color="textSecondary" variant="body1">
          {dataset.email || ''}
        </Typography>
        <Typography align="center" color="textSecondary" variant="body1">
          Financial Advisor
        </Typography>
      </CardContent>
      <CardActions className={classes.footer} disableSpacing>
        <Button
          onClick={() => {
            onView && onView(dataset);
          }}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

export default GridViewItem;
