/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FormGroup, FormControlLabel, Switch } from '@gvlab/react-theme/mui';
import { useUtilsContext } from '@gvlab/react-lib/utils/UtilsProvider';

const Checkbox = (props) => {
  const { mode, errors, dataSource, onChange, onCheckDisabled, onGetValue } = useSelector(
    ({ app }) => ({
      mode: app.form.props.state,
      actionsAllow: app.form.props.options.actionsAllow,
      columnCount: app.form.props.options.columnCount,
      dataSource: app.form.props.options.dataSource,
      errors: app.form.props.errors,
      onChange: app.form.props.options.onChange,
      onCheckDisabled: app.form.props.options.onCheckDisabled,
      onGetValue: app.form.props.options.onGetValue,
    }),
  );

  const { errorMessage, field } = props;

  const { debug, objectByString } = useUtilsContext();

  const handleFieldChange = (e) => {
    if (field?.debug) debug(['handleFieldChange', e.target.name, e.target.checked]);
    e.persist();
    const value = e.target.checked;
    field.changeValue = value;
    onChange(field, value, dataSource);
  };

  const handleGetValue = () => {
    if (onGetValue) return !!onGetValue(dataSource, field);
    return !!objectByString(dataSource, field.dataIndex);
  };

  const handleCheckDisabled = () => {
    return onCheckDisabled({ dataSource, field });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            defaultChecked={handleGetValue()}
            disabled={handleCheckDisabled()}
            helpertext={errorMessage && errorMessage.toString()}
            id={field.key}
            // labelPlacement="end"
            name={field.key}
            onChange={handleFieldChange}
            variant="outlined"
          />
        }
        label={field.title}
      />
    </FormGroup>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default Checkbox;
