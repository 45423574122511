const LayoutComponent = (props) => {
  const { layout: Layout, input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } } = props;
  if (Layout) {
    return (
      <Layout {...props} />
    );
  }

  return <div {...dropzoneProps}>{files.length < maxFiles && input}</div>;
};

export default LayoutComponent;
