/* eslint-disable import/no-extraneous-dependencies */
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { promises } from '@gvlab/react-lib/utils';
import { AuthClient } from '@gvlab/auth-js';
import { useSessionHelper } from '@gvlab/react-session';
import { 
  setToken,
  selectDatasourceOptions,
  selectDatasourceServiceId,
} from '@gvlab/react-dataset/store/datasourceSlice';

import {
  Logout,
  setUserData, setError, setMenu, 
  selectGvUser, selectGvError, selectGvAuthToken,
} from './store'

// hook to handle authentication and authorization   
const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectGvUser);
  const errors = useSelector(selectGvError);
  const datasourceOptions = useSelector(selectDatasourceOptions);
  const serviceId = useSelector(selectDatasourceServiceId);
  const token = useSelector(selectGvAuthToken);
  const sessionHelper = useSessionHelper();
  const history = useHistory();
  const authAPI = AuthClient(datasourceOptions).Create();


  const redirect = (url) => {
    // if (navigate) return navigate(url, {replace: true});
    if (history) return history.push(url);

    return window.location.replace(url);
  };

  const getConfig = (options) => {
    const authType = options?.authType || 'bearer';

    const config = {  
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'cross-origin': true,
      },
    };

    if (token && authType === 'bearer' ) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  // check if auth is initial
  const checkAuth = () => {
    if (!authAPI) {
      throw new Error('useAuth must be initial');
    }
  };

  const clearSessions = () => {
    sessionHelper.clearSession();
  }

  // update session tokens    
  const updateSessions = async (_token, _refreshToken ) => {
    const sessionTokens = {
      token: _token,
      refreshToken: _refreshToken,
    }

    await sessionHelper.setSession(sessionTokens);
  }

  // error handler for auth  
  const errorHandler = async (err) => {
    clearSessions();
    setError(err.message);
    return promises.reject(err);
  };

  // login function for auth 
  const login = async ({ ...params }) => {
    try {
      checkAuth();

      const resp = await authAPI.login(params, getConfig({authType: 'basic'}));

      const userSession = {
        ...resp.data.attributes,
        id: resp.data.id,
      };

      const {
        token: loginToken,
        refreshToken,
      } = resp.data.attributes

      await updateSessions(loginToken, refreshToken);

      dispatch(setUserData(userSession));
      return promises.resolve(userSession);
    } catch (err) {
      return errorHandler(err);
    }
  };

  // logout function for auth
  const logout = async () => {
    try {
      checkAuth();

      dispatch(Logout());
      clearSessions();
      redirect('/auth/login');
      return promises.resolve(true);
    } catch (err) {
      return errorHandler(err);
    }
  };

  const refreshToken = async ({ ...params }) => {
    try {
      checkAuth();

      const resp = await authAPI.refreshToken(params, getConfig());

      const userSession = {
        ...resp.data.attributes,
        id: resp.data.id,
      };

      const {
        token: loginToken,
        refreshToken: tokenRefresh,
      } = resp.data.attributes

      await updateSessions(loginToken, tokenRefresh);

      dispatch(setUserData(userSession));
      return promises.resolve(userSession);
    } catch (err) {
      return errorHandler(err);
    }
  };

  // get token from session storage if exist and not expired
  const getToken = async () => {
    try {
      // check if auth is initial
      checkAuth();

      // get token from session storage if exist and not expired
      const sessionToken = sessionHelper.getToken();

      // if token exist in session storage and not expired use it
      if (sessionToken) { 
        dispatch(setToken(sessionToken));
        return promises.resolve(sessionToken);
      }

      // get refresh token from session storage if exist and not expired
      const sessionRefreshToken = sessionHelper.getRefreshToken();
      if (!sessionRefreshToken) { 
        throw new Error('ERR_SESSION_EXPIRED');
      }

      const {
        token: newToken,
        refreshToken: newRefreshToken,
      } = await refreshToken({ refreshToken: sessionHelper.getRefreshToken() });

      // update session storage with new token and refresh token
      await updateSessions(newToken, newRefreshToken);

      // dispatch new token to redux store
      dispatch(setToken(newToken));
      return promises.resolve(newToken);
    } catch (err) {
      console.error(err)
      return promises.reject(err);
    }
  };

  const getProfile = async () => {
    try {
      await getToken();
      const resp = await authAPI.getProfile(getConfig());
      const userSession = {
        ...resp.data.attributes,
        id: resp.data.id,
      };

      dispatch(setUserData(userSession));

      return promises.resolve(userSession);
    } catch (err) {
      return errorHandler(err);
    }
  };

  // get service details from auth service 
  const getServiceDetails = async () => {
    try {
      // check if serviceId is empty 
      if (!serviceId) {
        throw new Error('serviceId is Empty');
      }

      // get service details from auth service
      const resp = await authAPI.getServiceDetails(serviceId, getConfig());
      if (!resp.data?.attributes?.menus) {
        return promises.raise('ERR_MENU_EMPTY');
      }

      // dispatch service details to redux store
      dispatch(setMenu(resp.data.attributes.menus));

      // return service details
      return promises.resolve(resp.data);
    } catch (err) {
      // if (err?.message && err?.message === 'ERR_AUTH_FORBIDDEN') clearSessions();
      return promises.reject(err);
    }
  };

  const restoreSession = async () => {
    try {
      return await getProfile();
    } catch (err) {
      return errorHandler(err);
    }
  };

  return {
    redirect,
    login,
    logout,
    refreshToken,
    getToken,
    getProfile,
    getServiceDetails,

    // session functions
    restoreSession,

    authError: errors,
    getAccountInfo: () => user,
    errors,
  };
};

export default useAuth;
