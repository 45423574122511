/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@gvlab/react-ui/v2/Drawer';

import { BlockFieldsetTD } from 'components/Common/BlockFieldsetTD';
import { hideDraft, selectTaskDraftState, refetch } from 'store/Dashboard/taskDraftSlice';

import { ListPhase } from './ListPhase';
import { ListDetail } from './ListDetail';

export const List = ({ formState, onView, onViewTask }) => {
  const dispatch = useDispatch();
  const { setValue, getValues, formItems } = useFormContext();
  const [state, setState] = useState('DONE');
  const taskDraftState = useSelector(selectTaskDraftState);

  const handleRefresh = async () => {
    dispatch(
      refetch({
        fetcher: getValues,
        callback: async (response) => {
          const { items: phaseItems } = response;
          const tmpPhaseList = [];

          if (phaseItems) {
            const phaseList = response.phases;
            let totalAmount = 0;
            await phaseItems
              .filter((item, idx) => idx >= 0)
              .forEach((taskItem, taskIndex) => {
                let found = false;
                totalAmount += Number(taskItem.amount);
                tmpPhaseList.forEach((phaseItem) => {
                  if (phaseItem.groupId === taskItem.groupId) {
                    phaseItem.items.push(taskIndex);
                    phaseItem.total += Number(taskItem.amount);
                    found = true;
                  }
                });
                if (found === false) {
                  tmpPhaseList.push({
                    groupId: taskItem.groupId,
                    total: Number(taskItem.amount),
                    items: [taskIndex],
                  });
                }
              });
            const cleanItems = phaseItems.filter((item, idx) => idx >= 0);
            setValue('project_amount', totalAmount);
            setValue('phases', tmpPhaseList);
            setValue('items', cleanItems);
            setState('LOADED');
          }

          dispatch(hideDraft());

          return true;
        },
      })
    );
  };

  const handleAddTask = () => {
    // default holder for group
    const oldItems = getValues().items;
    const newItem = oldItems[taskDraftState.groupId * -1];
    delete oldItems[taskDraftState.groupId * -1];

    setValue(`items`, oldItems);

    formItems.append({
      ...newItem,
      amount: Number(newItem.amount),
      groupId: taskDraftState.groupId,
    });

    handleRefresh();
  };

  if (taskDraftState && taskDraftState.status === 'fetching') {
    handleRefresh();
  }

  const phaseList = getValues().phases ?? [];
  return (
    <BlockFieldsetTD
      title="Job Quote"
      // subtitle="Decide which communications you'd like to receive and how."
    >
      {phaseList.map((phase) => (
        <ListPhase
          key={`phase-${phase.groupId}`}
          formState={formState}
          phase={phase}
          groupId={phase.groupId}
          total={phase.total}
          items={phase.items}
          onRefresh={handleRefresh}
          onView={onView}
          onViewTask={onViewTask}
        />
      ))}
      <ListPhase
        formState={formState}
        groupId={phaseList.length + 1}
        total={0}
        items={[]}
        onRefresh={handleRefresh}
      />
      {taskDraftState.showDraft && taskDraftState.groupId && (
        <Drawer
          fullWidth={false}
          open
          onClose={() => dispatch(hideDraft())}
          title="Quotation Draft"
        >
          <ListDetail
            formState={formState}
            groupId={taskDraftState.groupId}
            onAdd={handleAddTask}
          />
        </Drawer>
      )}
    </BlockFieldsetTD>
  );
};
