/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from '@reduxjs/toolkit';

import dialogSlice from './dialogSlice';
// import datasourceSlice from './datasourceSlice';
// import datastoreSlice from './datastoreSlice';

const DbControlReducers = combineReducers({
  dialog: dialogSlice,
  // datasource: datasourceSlice,
  // datastore: datastoreSlice,
});

export const selectDbControlDialog = ({app}) => app.dbcontrol.dialog;

export default DbControlReducers;
