/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from '@reduxjs/toolkit';

import formSlice from './formSlice';
import formEventSlice from './formEventSlice';

const FormReducers = combineReducers({
  props: formSlice,
  events: formEventSlice,
});

export default FormReducers;
