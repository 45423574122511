/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Drawer from '@gvlab/react-ui/v2/Drawer';
import {
  selectedTask,
  selectedTaskState,
  startTask,
  completeTask,
  claimTask,
  clearDetail,
} from 'store/Dashboard/taskSlice';
import { numberUtils } from '@gvlab/react-lib/utils';

const Task = () => {
  const dispatch = useDispatch();
  // get task data from store (redux)
  const task = useSelector(selectedTask);
  const taskState = useSelector(selectedTaskState);

  if (!taskState) return null;

  if (taskState.state !== 'DONE') return null;

  return (
    <Drawer
      fullSize={false}
      onClose={() => dispatch(clearDetail())}
      open
      title="Task"
      // className="w-[390px]"
    >
      <div className="flex flex-col w-full h-full items-center	content-center	justify-center	">
        <div className="flex flex-col max-w-md p-6 space-y-4 divide-y sm:w-96 sm:p-10 divide-gray-300 bg-gray-50 text-gray-800">
          <h2 className="text-2xl font-semibold">Task items</h2>
          <ul className="flex flex-col pt-4 space-y-2">
            <li className="flex items-start justify-between">
              <h2 className="text-xl font-semibold uppercase">{task.subject}</h2>
            </li>
            <li className="flex items-start justify-between">
              <h3 className="text-lg ">{task.description}</h3>
            </li>
            <li className="flex items-start justify-between">
              <span>Status</span>
              <span className="uppercase">{task.status}</span>
            </li>
          </ul>
          <div className="pt-4 space-y-2">
            <div>
              <div className="flex justify-between">
                <span>Phase#</span>
                <span>{task.groupId}</span>
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <span>Create at</span>
                <span>{moment(task.createaAt).format('DD MMM YYYY')}</span>
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <span>Last Update at</span>
                <span>{moment(task.updateAt).format('DD MMM YYYY')}</span>
              </div>
            </div>
          </div>
          <div className="pt-4 space-y-2">
            {/* <div className="flex justify-between">
              <span>Service fee</span>
              <span>$0.50</span>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between">
                <span>Delivery fee</span>
                <span>$4.00</span>
              </div>
              <a rel="noopener noreferrer" href="#" className="text-xs hover:underline text-blue-600">
                How do our fees work?
              </a>
            </div> */}
            <div className="space-y-6">
              <div className="flex justify-between text-lg ">
                <span>&nbsp;</span>
                <span className="font-semibold">
                  {numberUtils.currencyFormat(task.amount, 0, ' ')}
                </span>
              </div>
              {task.status === 'paid' && (
                <button
                  type="button"
                  className="w-full py-2 font-semibold border rounded bg-blue-600 text-gray-50 border-blue-600"
                  onClick={() => dispatch(startTask({ taskId: task.id }))}
                >
                  Start Task
                </button>
              )}
              {task.status === 'start' && (
                <button
                  type="button"
                  className="w-full py-2 font-semibold border rounded bg-blue-600 text-gray-50 border-blue-600"
                  onClick={() => dispatch(completeTask({ taskId: task.id }))}
                >
                  Finish Task
                </button>
              )}
              {task.status === 'done' && (
                <button
                  type="button"
                  className="w-full py-2 font-semibold border rounded bg-blue-600 text-gray-50 border-blue-600"
                  onClick={() => dispatch(claimTask({ taskId: task.id }))}
                >
                  Claim Task
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Task;
