/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fetcher as fetchHelper }  from '@gvlab/requestly';

const defaultParams = {
  serverkey: '',
  host: '',
  timeout: 60000,
  cbFormatAuthHeader: () => {
    return null;
  },
};

const ProApi = (params = defaultParams) => {
  let BASE_URL = `${params?.host}` || '';

  const apiClient = fetchHelper({
    ...params.cbFormatAuthHeader,
  });

  /**
   * Authenticate an existing user using their email address.
   * @param email The email address of the user.
   * @param password The password of the user.
   * @param redirectTo A URL or mobile address to send the user to after they are confirmed.
   */
  async function login(body, options) {
    try {
      return await apiClient.put({
        url: `${BASE_URL}/user/v1/user/login`,
        body: {
          ...body,
          userType: 'pro',
        },
        options,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function refreshToken(body, options) {
    try {
      return await apiClient.put(`${BASE_URL}/user/v1/user/login`, body, options);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getProfile(options) {
    try {
      return await apiClient.get({ url: `${BASE_URL}/user/v1/me?userType=pro`, options});
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getServiceDetails(serviceId, options) {
    try {
      return await apiClient.get({
        url: `${BASE_URL}/admin/v1/services/${serviceId}`,
        options,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  function setFormatAuthHeader(cb) {
    apiClient.setFormatAuthHeader(cb);
  }

  async function logout() {
    try {
      return await apiClient.post({ url: `${BASE_URL}/user/v1/user/logout?userType=pro` });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  function setBaseUrl(url) {
    BASE_URL = url;
  }

  return {
    setBaseUrl,
    setFormatAuthHeader,
    getProfile,
    getServiceDetails,
    login,
    logout,
    refreshToken,
  };
};

export default ProApi;
