/* eslint-disable import/no-extraneous-dependencies */
import { createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

import { formattedMeta, initialDataStore, checkExist } from './datastore';

export const create = createAsyncThunk(
  'datastore/create',
  async ({dataSource, method, url, params, cbCheck}, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .request({ method, url, params})
        .then((response) => {
          return {
            dataSource,
            value: response,
            cbCheck
          };
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue({
        dataSource,
        error,
      });
    }
  },
);

export const read = createAsyncThunk(
  'datastore/read',
  async ({dataSource, method, url, params, cbCheck}, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .request({ method, url, params})
        .then((response) => {
          return {
            dataSource,
            value: response,
            cbCheck
          };
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue({
        dataSource,
        error,
      });
    }
  },
);

export const update = createAsyncThunk(
  'datastore/update',
  async ({dataSource, method, url, params, cbCheck}, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .request({ method, url, params})
        .then((response) => {
          return {
            dataSource,
            value: response,
            cbCheck
          };
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue({
        dataSource,
        error,
      });
    }
  },
);

export const remove = createAsyncThunk(
  'datastore/remove',
  async ({dataSource, method, url, params, cbCheck}, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .request({ method, url, params})
        .then((response) => {
          return {
            dataSource,
            value: response,
            cbCheck
          };
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue({
        dataSource,
        error,
      });
    }
  },
);

export const helpersReducers = {
  [create.pending]: (state, action) => {
    const dataSource = action.payload?.dataSource ?? action.meta.arg?.dataSource;
    return { 
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...state?.collections?.[dataSource] ?? initialDataStore,
          state: 'LOAD',
        },
      }
    };
  },
  [create.fulfilled]: (state, action) => {
    const { dataSource, value, cbCheck } = action.payload;
    return {
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...state.collections[dataSource],
          state: 'DONE',
          value: {
            ...state.collections[dataSource].value,
            data: [value, ...state.collections[dataSource].value.data],
            meta: formattedMeta(state, value),
          },
        },
      }
    };
  },

  [read.pending]: (state, action) => {
    const dataSource = action.payload?.dataSource ?? action.meta.arg?.dataSource;
    return { 
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...state?.collections?.[dataSource] ?? initialDataStore,
          state: 'LOAD',
        },
      }
    };
  },
  [read.fulfilled]: (state, action) => {
    const { dataSource, value, cbCheck } = action.payload;
    const onCheckExist = cbCheck || checkExist;

    // get dataStore from dataSource
    const prevState = state.collections[dataSource];
    let newData = prevState.data;
  
    // if newValue not data just ignore
    if (!value?.data && onCheckExist) {
      newData = value.data.map((e) => onCheckExist(e, value));
    }
  
    // get dataStore from dataSource
    return {
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...prevState,
          state: 'DONE',
          ...value,
          // data: [...newData, ...prevState.data],
        },
      }
    };
  },
  [read.rejected]: (state, action) => {
    const { dataSource, error } = action.payload;
    return {
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...state.collections[dataSource],
          state: 'ERROR',
          error,
        },
      }
    };
  },

  [update.pending]: (state, action) => {
    const dataSource = action.payload?.dataSource ?? action.meta.arg?.dataSource;
    return { 
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...state?.collections?.[dataSource] ?? initialDataStore,
          state: 'UPDATE',
        },
      }
    };
  },
  [update.fulfilled]: (state, action) => {
    const { dataSource, value, cbCheck } = action.payload;
    const onCheckExist = cbCheck || checkExist;

    // get dataStore from dataSource
    const prevState = state.collections[dataSource];
    let newData = prevState.data;

    // if newValue not data just ignore
    if (!value?.data && onCheckExist) {
      newData = newData.data.map((e) => onCheckExist(e, value));
    }

    return {
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...prevState,
          ...value,
          state: 'DONE',
          data: [...(newData && prevState.data)],
          meta: formattedMeta(prevState, value),
        },
      }
    };
  },

  [remove.pending]: (state, action) => {
    const dataSource = action.payload?.dataSource ?? action.meta.arg?.dataSource;
    return { 
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...state?.collections?.[dataSource] ?? initialDataStore,
          state: 'REMOVE',
        },
      }
    };
  },
  [remove.fulfilled]: (state, action) => {
    const { dataSource, value, cbCheck } = action.payload;
    const onCheckExist = cbCheck || checkExist;

    // get dataStore from dataSource
    const prevState = state.collections[dataSource];
    let newData = prevState.data;

    // if newValue not data just ignore
    if (!value?.data && onCheckExist) {
      newData = newData.data.map((e) => onCheckExist(e, value));
    }

    return {
      ...state,
      collections: {
        ...state.collections,
        [dataSource]: {
          ...prevState,
          ...value,
          state: 'DONE',
          data: [...(newData && prevState.data)],
          meta: formattedMeta(prevState, value),
        },
      }
    };
  },

};