import objectByString from './objectByString';

export const listGetValue = (dataSource, field) => {
  if (!dataSource) return '';
  const listData = objectByString(dataSource, field.listDataIndex);

  if (!listData || listData === undefined) return '';
  if (listData.length === 0) return '';

  const selectedData = listData.filter((data) => {
    return data.name === field.dataIndex;
  });
  if (!selectedData || selectedData.length === 0) return '';
  return selectedData.pop().value;
};

export const getListValue = (listData, field) => {
  if (!listData || listData === undefined) return '';
  if (listData.length === 0) return '';
  const selectedData = listData.filter((data) => {
    return data.name === field.dataIndex;
  });
  if (!selectedData || selectedData.length === 0) return '';
  return selectedData.pop().value;
};
