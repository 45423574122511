import clsx from 'clsx';

const NoOptionsMessage = ({className, children, ...rest}) => {

  return (
    <span className={clsx("text-primary-content font-1 px-2 py-0", className)} {...rest}>
      {children}
    </span>
  );
}

export default NoOptionsMessage;