// wallet slice is the state of the wallet component
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// get wallet available balance from API and update state in store
export const GetWalletBalance = createAsyncThunk(
  'walletBalance/GetWalletBalance',
  async ({ dispatch, rejectWithValue }) => {
    try {
      // set task detail state to pending
      return await Requestly()
        .get(`/user/v1/wallets/available?userType=pro`)
        .then((response) => {
          return response.data?.wallets;
        })
        .catch((error) => {
          // set task detail state to error
          return rejectWithValue(error);
        });
    } catch (error) {
      // set task detail state to error
      return rejectWithValue(error);
    }
  }
);

// update wallet slice initial state
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {
    balance: 0,
    available: 0,
    pending: {
      Lock: 0,
      topup: 0,
      withdraw: 0,
    },
  },
};

// create wallet balance slice reducer and actions
const walletBalanceSlice = createSlice({
  name: 'walletBalance',
  initialState,
  reducers: {},
  extraReducers: {
    [GetWalletBalance.pending]: (state, action) => {
      return {
        ...initialState,
        state: 'PENDING',
      };
    },
    [GetWalletBalance.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: {
          balance: action.payload?.balance || 0,
          available: action.payload?.available || 0,
          pending: {
            Lock: action.payload?.pending?.Lock || 0,
            topup: action.payload?.pending?.topup || 0,
            withdraw: action.payload?.pending?.withdraw || 0,
          },
        },
      };
    },
    [GetWalletBalance.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        errors: action.payload,
      };
    },
  },
});

export const selectWalletBalance = ({ app }) => app.dashboard.walletBalance.detail;
export const selectWalletBalanceState = ({ app }) => app.dashboard.walletBalance;

export default walletBalanceSlice.reducer;
