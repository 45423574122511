/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { InputAdornment, IconButton } from '@gvlab/react-theme/mui';
import { Visibility, VisibilityOff } from '@gvlab/react-icons';
import Input from './TextField';

const PasswordField = (props) => {
  const { dataSource, onChange, title, drawerFullSize } = useSelector(({ app }) => ({
    dataSource: app.form.props.options.dataSource,
    onChange: app.form.props.options.onChange,
    title: app.form.props.options.title,
    drawerFullSize: app.form.props.options.drawerFullSize,
  }));
  const { disabled, field } = props;
  const [values, setValues] = React.useState({
    password: '',
    verify: '',
    showPassword: false,
  });

  const handleChange = (prop) => async (e) => {
    setValues({ ...values, [prop]: e.changeValue });
    if (values.password === values.verify && values.password !== '') {
      field.changeValue = values.password;
      if (onChange) onChange(field, values.password, dataSource);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (disabled) return null;

  return (
    <>
      <Input
        autoComplete="false"
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleChange('password')}
        type={values.showPassword ? 'text' : 'password'}
      />
      <Input
        {...props}
        autoComplete="false"
        label="Verify Password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleChange('verify')}
        type={values.showPassword ? 'text' : 'password'}
      />
    </>
  );
};

PasswordField.propTypes = {
  field: PropTypes.object,
};

export default PasswordField;
