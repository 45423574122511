/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Alert = (props, ref) => {
  const { className, icon, severity, message, onClose, ...rest } = props;
  const classes = useStyles();

  const getIcons = (variant) => {
    if (variant === 'close') return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>;
    if (variant === 'success') return <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>;
    if (variant === 'warning') return <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>;
    if (variant === 'error') return <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>;
    // if (variant === 'info') return <InfoIcon />;
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>;
  };
  return (
    <div className={clsx("alert", `alert-${severity}`, "shadow-lg")} ref={ref}>
      <span>{getIcons(severity)}</span>
      <span>{message}</span>
      {onClose && (
        <button className="btn btn-circle btn-outline" onClick={onClick}>
          {getIcons('close')}
        </button>
      )}
    </div>
  );
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  severity: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error']),
};

Alert.defaultProps = {
  severity: 'default',
};

export default Alert;
