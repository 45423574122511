import objectByString from './objectByString';

// Local utils functions
export const getErrorMessage = (errors, field) => {
  if (!errors || errors === undefined) return '';
  if (errors.length === 0) return '';
  // if error not an array
  if (Array.isArray(errors) === false) return errors;

  const errorMessage = errors.filter((err) => {
    if (!err?.meta) return false;
    if (!err?.meta?.param) return false;
    if (err?.meta?.param !== field.key) return false;

    return true;
  });

  if (!errorMessage) return '';

  if (errorMessage.length > 0) {
    const lastError = errorMessage.slice(-1).pop();
    return lastError?.meta?.message || lastError?.message || '';
  }

  return '';
};

export const getGeneralError = (errors) => {
  if (!errors || errors === undefined) return '';
  if (errors.length === 0) return '';
  if (Array.isArray(errors) === false) return errors;
  return '';
};

export const hasError = (errors, field) => {
  return getErrorMessage(errors, field) !== '';
};

export const getHelperText = (errors, field) => {
  const formatedErrors = hasError(errors, field);
  if (formatedErrors === false) return field?.helperText;
  return formatedErrors.slice(-1).pop().message;
};

export const getValue = (dataset, field) => {
  try {
    if (!dataset) return '';
    let defaultValue = field.changeValue;
    if (defaultValue === undefined) {
      defaultValue = dataset && objectByString(dataset, field.dataIndex);
    }
    if (defaultValue === undefined) {
      defaultValue = objectByString(dataset, field.key);
    }
    return defaultValue;
  } catch (err) {
    return '';
  }
};
