import React from 'react';
import { Button, CircularProgress } from '@gvlab/react-theme/mui';
import { colors, styled } from '@gvlab/react-theme/styles';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: colors.red[500],
}));

const CustomButton = (props) => {
  const { disabled, progress, ...rest } = props;
  return (
    <div className="relative flex items-center m-0">
      <Button {...rest} disabled={progress ? true : false} variant="contained">
        {props.children}
        {progress && <StyledCircularProgress size={24} className="absolute top-[50%] left-[50%] mt-[-12px] ml-[-12px] green-500"/>}
      </Button>
    </div>
  );
};

export default CustomButton;
