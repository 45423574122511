/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

const StyledComponent = {
  styleOverrides: {
    root: {
      '&$selected': {
        backgroundColor: palette.secondary.dark,
      },
      '&$hover': {
        '&:hover': {
          backgroundColor: palette.secondary.dark,
        },
      },
    },
  },
};

export default StyledComponent;
