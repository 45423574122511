// wallet slice is the state of the wallet component
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// viewDetail action to view task detail from API and update state in store
export const viewDetail = createAsyncThunk(
  'withdrawDetail/viewDetail',
  async ({ withdrawId }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .get(`/user/v1/withdraws/${withdrawId}?userType=pro`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log('viewDetail error', error);
          // set task detail state to error
          return rejectWithValue(error);
        });
    } catch (error) {
      console.log('viewDetail error', error);
      // set task detail state to error
      return rejectWithValue(error);
    }
  }
);

// clearDetail action to clear task detail from store
export const clearDetail = createAsyncThunk('withdrawDetail/clearDetail', (payload) => true);

// update wallet detail slice initial state
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// create payment detail slice reducer and actions
const withdrawDetailSlice = createSlice({
  name: 'withdrawDetail',
  initialState,
  reducers: {},
  extraReducers: {
    [clearDetail.fulfilled]: (state, action) => {
      state.detail = initialState.detail;
      state.state = initialState.state;
      state.success = initialState.success;
      state.errors = initialState.errors;
    },

    [viewDetail.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    [viewDetail.fulfilled]: (state, action) => {
      state.state = 'DONE';
      state.errors = initialState.errors;
      state.success = true;
      state.detail = action.payload;
    },
    [viewDetail.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = initialState.success;
      state.errors = action.payload;
    },
  },
});

export const selectWithdrawDetailState = ({ app }) => app.dashboard.withdrawDetail;
export const selectWithdrawDetail = ({ app }) => app.dashboard.withdrawDetail.detail;

export default withdrawDetailSlice.reducer;
