/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk'
import rootReducer from './rootReducer';

const middlewares = [
  /* YOUR CUSTOM MIDDLEWARES HERE */
  thunkMiddleware
];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });

  middlewares.push(logger);
}

export const reduxStore = configureStore({
  // register reducer
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    reduxStore.replaceReducer(newRootReducer.createReducer());
  });
}

reduxStore.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (reduxStore.asyncReducers[key]) {
    return false;
  }
  reduxStore.asyncReducers[key] = reducer;
  reduxStore.replaceReducer(rootReducer(reduxStore.asyncReducers));
  return reduxStore;
};
