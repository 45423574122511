/* eslint-disable import/prefer-default-export */
import clsx from 'clsx';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@gvlab/react-theme/mui';
import Card from '@gvlab/react-ui/v2/Card';
import CardContent from '@gvlab/react-ui/v2/CardContent';

export const Document = (props) => (
  <Card className={clsx('text-black mt-4 mb-4 overflow-visible', props.className)}>
    <CardContent>{props.children}</CardContent>
  </Card>
);

export const DocumentSection = (props) => (
  <div className={clsx('flex flex-row', 'justify-between', 'mt-4', 'text-black', props.className)}>
    {props.children}
  </div>
);

export const DocumentHeader = (props) => (
  <DocumentSection className={clsx('mt-6', props.className)}>
    {props.logo && <img alt="Brand" src={props.logo} />}
    <Typography size="text-4xl text-black uppercase" type="h3">
      {props.title}
    </Typography>
  </DocumentSection>
);

export const DocumentStatus = (props) => (
  <span className="ribbon">
    <span className="ribbon-inner text-xs">{props.children}</span>
  </span>
);

export const DocumentFooter = (props) => <div className="mt-4 text-black ">{props.children}</div>;

export const ContentBlock = (props) => {
  return (
    <div className={clsx('mt-4 text-black', props.className)}>
      <p className="text-base font-semibold">{props.title}</p>
      <p className="text-xs">{props.children}</p>
    </div>
  );
};

export const ContentTable = (props) => <Table className="mt-4">{props.children}</Table>;

export const TableTitleCell = (props) => {
  const { label, ...rest } = props;
  return (
    <TableCell className="font-bold" {...rest}>
      <p className="text-sm font-semibold">{label}</p>
      {rest.children}
    </TableCell>
  );
};

export const TableContentCell = (props) => {
  const { label, ...rest } = props;
  return (
    <TableCell align="right" {...rest}>
      {props.children}
    </TableCell>
  );
};

export const TableHeader = (props) => (
  <TableHead>
    <TableRow>{props.children}</TableRow>
  </TableHead>
);

export const DocumentTitle = (props) => (
  <div className={clsx('flex flex-row justify-between', props.className)}>
    <img alt="Brand" src={props.logo} />
    <h3 className="text-4xl text-black uppercase">{props.title}</h3>
  </div>
);

export const Typography = (props) => {
  const { size, align, children, type, ...rest } = props;

  const classes = clsx(size || 'text-base ', `text-${align || 'left '}`);

  if (type === 'h1')
    return (
      <h1 className={classes} {...rest}>
        {children}
      </h1>
    );

  if (type === 'h2')
    return (
      <h2 className={classes} {...rest}>
        {children}
      </h2>
    );

  if (type === 'h3')
    return (
      <h3 className={classes} {...rest}>
        {children}
      </h3>
    );

  if (type === 'h4')
    return (
      <h4 className={classes} {...rest}>
        {children}
      </h4>
    );

  return (
    <p className={classes} {...rest}>
      {children}
    </p>
  );
};

export { TableBody, TableRow, TableCell };
