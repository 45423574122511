/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import status2Code from '../status2Code';
import crossFetcher from './crossFetcher';
import axiosFetcher from './axiosFetcher';

// const {
// 	Blob,
// 	File,
// 	fileFromSync,
// 	fileFrom,
// 	blobFromSync,
// 	blobFrom
// } = require('fetch-blob/from.js');

// export const RequestMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
const fetchHelper = (fetchOptions) => {
  const FetchOptions = {
    headers: {},
    form: false,
    noResolveJson: false,
    redirect: 'follow',
    ...fetchOptions,
  };

  const noResolveJson = FetchOptions.noResolveJson || false;

  // get error message from error object
  function _getErrorMessage(err) {
    return (
      err?.msg ||
      err?.detail ||
      err?.message ||
      err?.error_description ||
      err?.error ||
      err?.errors ||
      err?.title ||
      err?.statusText ||
      JSON.stringify(err)
    );
  }

  // get request params
  function _getRequestParams({ method, options = FetchOptions, body }) {
    const params = {
      method,
      headers: options?.headers || {},
    };

    if (method === 'GET') {
      return params;
    }

    params.body = body ? JSON.stringify(body) : {};
    params.data = options?.data || {};
    return params;
  }

  async function _handleRequest({
    fetcher = crossFetcher,
    method,
    url,
    options = FetchOptions,
    body,
  }) {
    return new Promise((resolve, reject) => {
      fetcher(options)
        .request({url, params: _getRequestParams({ method, options, body })})
        .then((data) => {
          if (noResolveJson) {
            return resolve(data);
          }

          if (data?.meta) {
            const resp = {
              data: data.data.map((ele) => {
                return {
                  ...ele,
                  attributes: {
                    ...ele.attributes,
                    id: ele.id,
                  },
                };
              }),
            };
            resp.meta = { ...data.meta };
            return resp;
          }
          return data;
        })
        .then((data) => {
          return resolve(data);
        })
        .catch((error) => {
          return handleError(error, reject);
        });
    });
  }

  // error handler
  async function handleError(error, reject) {
    try {
      let errors = await error;
      if (errors?.message?.errors) {
        errors = errors.message.errors;
      }

      if (Array.isArray(errors)) {
        errors = errors[0];
      }

      return reject({
        message: _getErrorMessage(errors),
        status: errors.status,
        code: status2Code(errors.status),
      });
    } catch (e) {
      return reject(e);
    }
  }
  
  async function get({ fetcher, url, options }) {
    return _handleRequest({ fetcher, method: 'GET', url, options });
  }

  async function post({ fetcher, url, body, options }) {
    return _handleRequest({ fetcher, method: 'POST', url, options, body });
  }

  async function put({ fetcher, url, body, options }) {
    return _handleRequest({ fetcher, method: 'PUT', url, options, body });
  }

  async function remove({ fetcher, url, body, options }) {
    return _handleRequest({ fetcher, method: 'DELETE', url, options, body });
  }

  async function upload({ url, body, options }) {
    const method = options.method || 'post';
    const uploadParams = {
      method,
      url,
      headers: options.headers || {},
      data: body,
    };
  
    return _handleRequest({ fetcher: axiosFetcher, method, url, options: uploadParams, body });
  }

  return {
    Fetch: crossFetcher,
    Axios: axiosFetcher,

    put,
    get,
    post,
    remove,
    upload,
  };
};

export default fetchHelper;
