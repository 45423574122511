/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { dialogCritical } from '@gvlab/react-create-app-gv/src/Dialog/store/dialogSlice';
import { show, close } from 'store/loadingSlice';

// validate request to update quotation detail
const validateUpdateDetail = ({ request }) => {
  const changes = {
    status: 'draft',
    items: [],
    owner: {},
    property: {},
    project: {
      status: 'draft',
    },
  };

  if (request.items) {
    changes.items = request.items
      .filter((item, idx) => idx >= 0)
      .map((item, idx) => {
        return {
          ...item,
          seq: idx,
          price: item.amount,
          billable: 't',
          group_id: item.groupId,
        };
      });
  }

  if (request.owner_email) {
    changes.owner.email = request.owner_email;
  }

  if (request.owner_fullname || request.owner_name) {
    changes.owner.fullName = request.owner_fullname || request.owner_name;
  }

  if (request.owner_phone) {
    changes.owner.phone = request.owner_phone;
  }

  if (request.owner_passport) {
    changes.owner.passport = request.owner_passport;
  }

  if (request.status) {
    changes.project.status = request.status;
    changes.status = request.status;
  }

  if (request.project_budget) {
    changes.project.budget = Number(request.project_budget);
  }

  if (request.project_amount) {
    changes.project.amount = Number(request.project_amount);
  }

  if (request.project_description) {
    changes.project.description = request.project_description;
  }

  if (request.project_subject) {
    changes.project.subject = request.project_subject;
  }

  if (request.project_duedate) {
    changes.project.duedate = request.project_duedate;
  }

  if (request.property_description) {
    changes.property.description = request.property_description;
  }

  if (request.property_state) {
    changes.property.state = request.property_state;
  }

  if (request.property_postcode || request.property_zipCode) {
    changes.property.postcode = request.property_postcode || request.property_zipCode;
  }

  if (request.property_line1) {
    changes.property.line1 = request.property_line1;
  }

  if (request.property_line2) {
    changes.property.line2 = request.property_line2;
  }

  if (request.property_city) {
    changes.property.city = request.property_city;
  }

  if (request.property_state) {
    changes.property.state = request.property_state;
  }

  if (request.property_country) {
    changes.property.country = request.property_country;
  }

  if (request.property_bedroom) {
    changes.property.bedroom = request.property_bedroom;
  }

  if (request.property_bathroom) {
    changes.property.bathroom = request.property_bathroom;
  }

  if (request.property_size) {
    changes.property.size = request.property_size;
  }

  if (request.property_uom) {
    changes.property.uom = request.property_uom;
  }

  // update property type
  if (request.property_type) {
    changes.property.type = request.property_type;
  }

  return changes;
};

// update quotation detail thunk action creator to update quotation detail to API
export const updateDetail = createAsyncThunk(
  'quotationDraft/updateDetail',
  async ({ quotationId, request }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(show());
      // before update quotation detail, update quotation status to draft
      const { id } = request;
      const changes = validateUpdateDetail({ request });

      // default request params to status draft
      let requestParams = {
        method: 'put',
        url: `/user/v1/quotations/${quotationId}?userType=pro`,
        params: changes,
      };

      // if quotation status is confirm, update quotation status to confirm
      if (changes.status === 'confirm') {
        requestParams = {
          method: 'post',
          url: `/user/v1/quotations/${quotationId}/confirm?userType=pro`,
          params: changes,
        };
      }

      // update quotation detail to API
      const response = await Requestly().request(requestParams);

      dispatch(close());
      // if response is null, show critical dialog
      if (!response) {
        dispatch(dialogCritical());
        return rejectWithValue('empty response');
      }

      // return response data to reducer to update state in store
      return response.data;
    } catch (error) {
      dispatch(close());
      return rejectWithValue(error);
    }
  }
);

// create quotation detail thunk action creator to create quotation detail to API
export const create = createAsyncThunk(
  'quotationDraft/create',
  async ({ request }, { dispatch, rejectWithValue }) => {
    try {
      // before create quotation detail, update quotation status to draft
      const changes = validateUpdateDetail({ request });

      dispatch(show());
      // create quotation detail to API
      const response = await Requestly().request({
        method: 'post',
        url: '/user/v1/quotations?userType=pro',
        params: changes,
      });

      dispatch(close());
      // if response is null, show critical dialog
      if (!response) {
        dispatch(dialogCritical());
        return rejectWithValue('empty response');
      }

      // return response data to reducer to update state in store
      return response.data;
    } catch (error) {
      dispatch(close());
      return rejectWithValue(error);
    }
  }
);

// query quotation detail from API and return response data to reducer to update state in store
const queryDetail = ({ quotationId }) => {
  return Requestly()
    .request({
      method: 'get',
      url: `/user/v1/quotations/${quotationId}?showchild=true&userType=pro`,
    })
    .then((response) => {
      return response.data;
    })
    .then((response) => {
      const { contexts, orders, projectsId, propertyId, profile, property, project, ...rest } =
        response;
      const resp = {
        ...rest,
        orders: response?.orders,
        items: response.items,
        project_duedate: response.dueAt ? response.dueAt.substr(0, 10) : '',
        project_subject: response.subject,
        project_description: response.description,
        project_amount: response.totalAmount,
      };
      resp.items = response.items;
      if (response.items) {
        resp.items = response.items.map((task, idx) => ({ ...task, idx }));
      }

      if (resp.items) {
        const phaseList = [];
        let totalAmount = 0;
        resp.items.forEach((task) => {
          let found = false;
          totalAmount += Number(task.amount);
          phaseList.forEach((phaseItem) => {
            if (phaseItem.groupId === task.groupId) {
              phaseItem.items.push(task.idx);
              phaseItem.total += task.amount * 1;
              found = true;
            }
          });
          if (found === false) {
            const orderItem = response?.orders.filter((order) => order.groupId === task.groupId);
            phaseList.push({
              groupId: task.groupId,
              total: task.amount,
              items: [task.idx],
              order: orderItem,
            });
          }
        });

        resp.phases = phaseList;
        resp.project_amount = totalAmount;
      }

      contexts.forEach((ctx) => {
        resp[ctx?.namespace] = ctx.value;
      });

      if (response.status !== 'draft') {
        if (profile) {
          Object.keys(profile).forEach((key) => {
            resp[`owner_${key.toLowerCase()}`] = profile[key];
          });
        }
        if (property) {
          Object.keys(property).forEach((key) => {
            if (key === 'propertyType') resp.property_type = property[key];
            if (key === 'zipCode') resp.property_postcode = property[key];
            resp[`property_${key.toLowerCase()}`] = property[key];
          });
        }
        if (project) {
          Object.keys(project).forEach((key) => {
            resp[`project_${key.toLowerCase()}`] = project[key];
          });
        }
      }

      resp.manager = response.manager;
      return resp;
    });
};

// view quotation detail thunk action creator to get quotation detail from API
// and open dialog to view quotation detail
export const viewDetail = createAsyncThunk(
  'quotationDraft/viewDetail',
  async ({ quotationId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(show());
      // query quotation detail from API
      const response = await queryDetail({ quotationId });

      dispatch(close());
      // if response is null, show critical dialog
      if (!response) {
        return rejectWithValue('empty response');
      }

      return response;
    } catch (error) {
      dispatch(close());
      dispatch(dialogCritical());
      return rejectWithValue(error);
    }
  }
);

// initial state of quotation detail
const initialState = {
  state: 'PENDING',
  success: false,
  errors: false,
  detail: null,
};

// quotation detail slice reducer to update state in store
const quotationDraftSlice = createSlice({
  name: 'quotationDraft',
  initialState,
  reducers: {
    newQuotation: (state, action) => {
      return {
        ...initialState,
        state: 'DRAFT',
        detail: action.payload,
      };
    },
    clearDetail: (state, action) => {
      return {
        ...initialState,
      };
    },
    refetching: (state, action) => {
      return {
        ...initialState,
        state: 'REFETCHING',
      };
    },
  },
  extraReducers: {
    [create.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    // set quotation create state to done when create quotation detail is fulfilled
    [create.fulfilled]: (state, action) => {
      state.state = 'FETCHING';
      state.success = true;
      state.errors = false;
      state.detail = initialState.detail;
    },
    [create.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
      state.detail = initialState.detail;
    },

    [updateDetail.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    [updateDetail.fulfilled]: (state, action) => {
      state.state = 'FETCHING';
      state.success = true;
      state.errors = false;
      state.detail = initialState.detail;
    },
    [updateDetail.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
      state.detail = initialState.detail;
    },

    [viewDetail.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    [viewDetail.fulfilled]: (state, action) => {
      state.state = 'DRAFT';
      state.success = true;
      state.errors = false;
      state.detail = action.payload;
    },
    [viewDetail.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
      state.detail = initialState.detail;
    },
  },
});

// export quotation detail slice actions
export const { newQuotation, clearDetail, refetching } = quotationDraftSlice.actions;
export const selectQuotationState = ({ app }) => app.dashboard.quotationDraft;
export const selectQuotation = ({ app }) => app.dashboard.quotationDraft.detail;

export default quotationDraftSlice.reducer;
