/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch } from 'react-redux';
import { isTokenExpired } from '@gvlab/react-token';
import { setTokens, init } from './store';
import useCookieState from './storage';

// session helper to handle session storage and cache   
const SessionHelper = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useCookieState('t');
  const [refreshToken, setRefreshToken] = useCookieState('r', { 
    expires: (() => new Date(+new Date() + (60*24*60*60*1000)))(),
  });

  // session functions
  const setSession = (patch) => {
    if (!patch?.token || patch?.token === '') return;
    if (!patch?.refreshToken || patch?.refreshToken === '') return;

    if (isTokenExpired(patch.token)) return;
    if (isTokenExpired(patch.refreshToken)) return;

    Promise.all([
      setToken(patch.token),
      setRefreshToken(patch.refreshToken),
      dispatch(setTokens(patch)),
    ])
  };

  // clear function to kill all session storage and cache
  const clearSession = async () => {
    // clear session from storage
    dispatch(init());
    setToken('');
    setRefreshToken('');
  };

  // check if token in session is expired
  const isSessionExpired = () => {
    try {
      return isTokenExpired(token);
    } catch (err) {
      return true;
    }
  };

  // get token from session storage or cache if not expired
  const getToken = () => {
    if (isTokenExpired(token) === false) return token;
    return null;
  };

  // get refresh token from session storage or cache if not expired
  const getRefreshToken = () => {
    if (isTokenExpired(refreshToken) === false) return refreshToken;
    return null;
  };

  return {
    token,
    getToken,
    getRefreshToken,

    setSession,
    clearSession,
    isSessionExpired,
    isTokenExpired,
  };
};

export default SessionHelper;

export const useSessionHelper = () => SessionHelper()
