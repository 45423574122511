/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment';

/**
 * 01/02 03:04:05PM '06 -0700
 * 
 * 01 - month
 * 02 - day
 * 03 - hour
 * 04 - minute
 * 05 - second
 * 06 - year
 * 07 - time zone offset
 * 
 * 01 - month
 * -------------------------
 * 1        M       "7"
 * 02       MM      "07"
 * Jan      MMM     "Jul"
 * January  MMMM    "July"
 * 
 * 02 - day
 * -------------------------
 * __2      __d     " 074"
 * 002      ddd     "074"
 * 2        D       "8"
 * _2       _D      " 8"
 * 02       DD      "08"
 * Mon      DDD     "Tue"
 * Monday   DDDD    "Tuesday"
 * 
 * 03 - hour
 * -------------------------
 * pm       am/pm   "am"
 * PM       am/pm   "AM"
 * 3        h       "5"
 * 03       hh      "05"
 * 15       hh      "17"
 * 
 * 04 - minute
 * -------------------------
 * 4        m       "7"
 * 04       mm      "07"
 * 
 * 05 - second
 * ---------------------------------------------
 * 05                       ss      "09"
 * 5                        s       "9"
 * .0, .00, …, .000000000   .s      ".126284000"
 * .9, .99, …, .999999999   .s      ".126284"
 * 
 * 06 - year
 * ---------------------------------------------
 * 06       YY      "22"
 * 2006     YYYY    "2022"
 * 
 * 07 - time zone offset
 * ---------------------------------------------
 * MST        TTT             "CEST"
 * -070000    ±hhmmss         "+010000"
 * -07:00:00  ±hh:mm:ss       "+01:00:00"
 * -0700      ±hhmm           "+0100"
 * -07:00     ±hh:mm          "+01:00"
 * -07        ±hh             "+01"
 * Z070000    Z or ±hhmmss    "+010000"
 * Z07:00:00  Z or ±hh:mm:ss  "+01:00:00"
 * Z700       Z or ±hhmm      "+0100"
 * Z07:00     Z or ±hh:mm     "+01:00"
 * Z07        Z or ±hh        "+01"
 * 
 */
export const thisWeek = {
  startDate: moment().subtract(0, 'weeks').startOf('isoWeek').unix(),
  endDate: moment().subtract(0, 'weeks').endOf('isoWeek').unix(),
};

export const thisMonth = {
  startDate: moment().subtract(0, 'month').startOf('month').unix(),
  endDate: moment().subtract(0, 'month').endOf('month').unix(),
};

export const thisYear = {
  startDate: moment().subtract(0, 'year').startOf('year').unix(),
  endDate: moment().subtract(0, 'year').endOf('year').unix(),
};

export const lastWeek = {
  startDate: moment().subtract(1, 'weeks').startOf('isoWeek').unix(),
  endDate: moment().subtract(1, 'weeks').endOf('isoWeek').unix(),
};

export const lastMonth = {
  startDate: moment().subtract(1, 'month').startOf('month').unix(),
  endDate: moment().subtract(1, 'month').endOf('month').unix(),
};

export const lastYear = {
  startDate: moment().subtract(1, 'year').startOf('year').unix(),
  endDate: moment().subtract(1, 'year').endOf('year').unix(),
};

export const yesterday = (date1) => {
  let dt = new Date();
  if (date1) dt = new Date(date1);
  return new Date(dt.setDate(dt.getDate() - 1)).toString();
};

export const tomorrow = (date1) => {
  let dt = new Date();
  if (date1) dt = new Date(date1);
  return new Date(dt.setDate(dt.getDate() + 1)).toString();
};

export const formatDate = (unixDate, fmt = 'YYYY-MM-DD') => moment.unix(unixDate).format(fmt);
export const formatDateTime = (unixDate, fmt = 'YYYY-MM-DD HH:mm:ss') =>
  moment.unix(unixDate).format(fmt);
export const formatDBDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};
export const formatDBDateTime = (DBDateTime, fmt = 'YYYY-MM-DD HH:mm:ss') =>
  moment(DBDateTime).format(fmt);

export const timeToUnix = (time) => moment.duration(`${time}:00`).asSeconds();
export const dateToUnix = (date, fmt = 'YYYY-MM-DD', origValue = false) => {
  if (origValue && date === Number(origValue)) return Number(origValue);
  return Number(moment(date, fmt).unix());
};
export const dateTimeToUnix = (datetime, fmt = 'YYYY-MM-DD HH:mm:ss') =>
  moment(datetime, fmt).unix();
export const today = () => moment();

export const renderDate = (value) => {
  return moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD');
};

export const renderTime = (value) => {
  return moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm:ss');
};

export const renderDateTime = (value) => {
  return moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD HH:mm:ss');
};

export const renderMonthDay = (value) => {
  return moment(value, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD');
};
