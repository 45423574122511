/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from '@gvlab/react-router';

import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@gvlab/react-theme/styles';
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@gvlab/react-theme/mui';
import {
  AccountCircle,
  Menu as MenuIcon,
  Input as InputIcon,
  Notifications as NotificationsIcon,
} from '@gvlab/react-icons';

import { NotificationsPopover } from '@gvlab/react-ui/components';
import { useGlobalContext } from '@gvlab/react-lib/Global';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logo: {
    height: '36px',
  },
}));

const TopBar = (props) => {
  const { className, title, ...rest } = props;
  const { authCtx, onMobileClose, onMobileOpen, onProfileBarOpen, onProfileBarClose } =
    useGlobalContext();

  const classes = useStyles();
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userInfo = authCtx.getAccountInfo();

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="secondary">
      <Toolbar>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onMobileOpen}>
            <MenuIcon />
          </IconButton>
          <RouterLink to="/">
            <img alt="Logo" className={classes.logo} src="/favicon.png" />
          </RouterLink>
        </Hidden>
        <Typography className={classes.name} variant="h6">
          &nbsp;&nbsp;{title}
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Hidden>
        {userInfo && (
          <div>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="account of current user"
              color="inherit"
              onClick={onProfileBarOpen}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="menu-appbar"
              keepMounted
              onClose={handleClose}
              open={open}
            >
              <MenuItem onClick={onProfileBarOpen}>Profile</MenuItem>
              <MenuItem
                onClick={() => {
                  authCtx.logout();
                }}
              >
                <ListItemIcon>
                  <InputIcon fontSize="small" />
                </ListItemIcon>
                <Typography noWrap variant="inherit">
                  Sign Out
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        )}
        {userInfo && (
          <div className={classes.profile}>
            <Typography className={classes.name} variant="h4">
              {userInfo?.attributes?.email}
            </Typography>
          </div>
        )}
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  onOpenProfileBar: PropTypes.func,
  title: PropTypes.string,
};

export default TopBar;
