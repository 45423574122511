import ProfileBar from 'Widget/ProfileBar';
import Task from 'Widget/Task';
import Withdraw from 'Widget/Withdraw';
import Reload from 'Widget/Reload';
import LightBox from 'Widget/LightBox/Widget';
import PaymentDetail from 'Widget/PaymentDetail';
import WithdrawDetail from 'Widget/WithdrawDetail';
import WalletDetail from 'Widget/WalletDetail';
import Chat from 'Widget/Chat';
import Invoice from 'Widget/Invoice';
import QuotationDetail from 'Widget/Quotation';
import QuotationDraft from 'Widget/QuotationDraft';
import GvLoading from 'Widget/Loading/Widget';
// import SplashScreen from 'components/Widget/SplashScreen';
// import Message from 'components/Widget/Message';

const Widget = () => {
  return (
    <>
      <ProfileBar />
      <Task />
      <Withdraw />
      <Reload />
      <PaymentDetail />
      <WithdrawDetail />
      <WalletDetail />
      <Chat />
      <Invoice />
      <QuotationDetail />
      <QuotationDraft />
      <GvLoading />
      <LightBox />
      {/* <SplashScreen /> */}
      {/* <Message /> */}
    </>
  );
};

export default Widget;
