import typography from '../typography';

const StyledComponent = {
  styleOverrides: {
    root: {
      ...typography.body3,
    },
    elevation1: {
      boxShadow: 'none',
    },
  },
};

export default StyledComponent;
