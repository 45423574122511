import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// reload chats detail state
export const reloadDetail = createAsyncThunk(
  'chats/reloadDetail',
  async ({ ordersId }, { dispatch, rejectWithValue }) => {
    try {
      // set task detail state to pending
      dispatch(setReload());
      return await Requestly()
        .get(`/user/v1/orders/${ordersId}/comments?userType=pro`)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch(setDetail(response));
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// viewdetail action to view task detail from API and update state in store
export const viewDetail = createAsyncThunk(
  'chats/viewDetail',
  async ({ ordersId }, { dispatch }) => {
    try {
      // set task detail state to pending
      dispatch(setPending());
      return await Requestly()
        .get(`/user/v1/orders/${ordersId}/comments?userType=pro`)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch(setDetail(response));
          return response;
        })
        .catch((error) => {
          console.log('viewDetail error', error);
          // set task detail state to error
          dispatch(setError(error));
          return false;
        });
    } catch (error) {
      console.log('viewDetail error', error);
      // set task detail state to error
      dispatch(setError(error));
      return false;
    }
  }
);

// clear chats detail state
export const clearDetail = () => (dispatch) => {
  dispatch(setPending());
};

// initial state of chats detail
const initialState = {
  state: 'PENDING',
  success: false,
  errors: false,
  detail: null,
};

// chats slice reducer to update state in store
const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    // set chats detail state to pending
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    // set chats detail state to reload
    setReload: (state, action) => {
      state.state = 'RELOAD';
    },
    // set chats detail state to pending
    setPending: (state, action) => {
      state.state = 'PENDING';
      state.detail = initialState.detail;
    },
    // set chats detail state to success
    setSuccess: (state, action) => {
      state.state = 'SUCCESS';
      state.success = true;
      state.errors = [];
    },
    // set chats detail state to error
    setError: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {
    // set task view state to done when view task detail is fulfilled
    [viewDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: action.payload,
      };
    },
    [reloadDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: action.payload,
      };
    },
  },
});

// export chats slice actions to use it in other files
export const { setPending, setDetail, setReload, setSuccess, setError } = chatsSlice.actions;
export const selectedChats = ({ app }) => app.dashboard.chats.detail;
export const selectedChatsState = ({ app }) => app.dashboard.chats;

// export chats slice reducer to use it in other files
export default chatsSlice.reducer;
