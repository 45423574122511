/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { Button } from '@gvlab/react-ui/v2/Button';
import Card from '@gvlab/react-ui/v2/Card';
import CardContent from '@gvlab/react-ui/v2/CardContent';
import CardFooter from '@gvlab/react-ui/v2/CardFooter';


const CardSessionExpired = (props) => {
  const { className, onClose, ...rest } = props;

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Card className="relative z-10" {...rest} >
      <CardContent>
        <div className="sm:flex sm:items-start">
          {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div> */}
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Session Expired
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Please re-login to renew your sessions.
              </p>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="px-6 py-6 flex justify-center">
        <span />
        <Button
          onClick={handleClose}
          color="info"
          className="w-fit px-6"
        >
          OK
        </Button>
        <span />
      </CardFooter>
    </Card>
  );
};

CardSessionExpired.propTypes = {
  className: PropTypes.string,
};

export default CardSessionExpired;
