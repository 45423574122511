/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const ListInput = (props) => {
  const { field: InputField, renderer: RenderFormItem, ...rest } = props;

  if (!InputField) return null;

  return <RenderFormItem {...rest} config={InputField.config.list} />;
};

ListInput.propTypes = {
  field: PropTypes.object.isRequired,
};

export default ListInput;
