/* eslint-disable camelcase */
// wallet slice is the state of the wallet component
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { yup } from '@gvlab/react-form';

// add fund wallet slice initial state
export const addFund = createAsyncThunk(
  'payment/addFund',
  async ({ request }, { dispatch, rejectWithValue }) => {
    const schema = yup.object().shape({
      amount: yup.number().required().min(100, 'Minimum amount is 100'),
      bank_name: yup.string().required('Bank name is required'),
      location: yup.string().required('Bank location is required'),
      date: yup.string().required('Date is required'),
      time: yup.string().required('Time is required'),
      receipt: yup.string().required('Receipt is required'),
      reference: yup.string().required('Reference is required'),
    });

    try {
      await schema.validateSync(request);

      // eslint-disable-next-line camelcase
      const { amount, bank_name, location, date, time, receipt, reference } = request;
      const formatedRequest = {
        total_amount: amount,
        payment_method: 'BANK_TRANSFER',
        contexts: [
          ['bank', bank_name],
          ['location', location],
          ['date', date],
          ['time', time],
          ['receipt', receipt],
          ['reference', reference],
        ],
      };
      return await Requestly()
        .post(`/user/v1/payments?userType=pro`, formatedRequest)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // set task detail state to error
          throw error;
        });
    } catch (error) {
      // set task detail state to error
      return rejectWithValue(error);
    }
  }
);

// payment slice initial state
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// clearDetail action to clear from store
export const clearDetail = createAsyncThunk('payment/clearDetail', (payload) => true);
export const addFundForm = createAsyncThunk('payment/addFundForm', (payload) => true);

// create payment slice reducer and actions
const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: {
    [addFundForm.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'SHOW',
      };
    },
    [clearDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
      };
    },

    [addFund.pending]: (state, action) => {
      return {
        ...state,
        state: 'PENDING',
      };
    },
    [addFund.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'REFETCHING',
        detail: action.payload,
      };
    },
    [addFund.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        errors: action.payload,
      };
    },
  },
});

export const selectPaymentState = ({ app }) => app.dashboard.payment;
export const selectPayment = ({ app }) => app.dashboard.payment.detail;

export default paymentSlice.reducer;
