/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
// wallet slice is the state of the wallet component
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { yup } from '@gvlab/react-form';

export const withdrawalWallet = createAsyncThunk(
  'wallet/withdrawal',
  async ({ request }, { dispatch, rejectWithValue }) => {
    const schema = yup.object().shape({
      amount: yup.number().required().min(1000, 'Minimum amount is 1000').max(50000, "Maximum amount is 50000"),
      bank_name: yup.string().required('Bank name is required'),
      bank_account: yup.string().required('Bank account is required'),
    });

    try {
      await schema.validateSync(request);
      // eslint-disable-next-line camelcase
      const { amount, bank_name, bank_account } = request;

      const formatedRequest = {
        total_amount: amount,
        payment_method: 'BANK_TRANSFER',
        contexts: [
          ['bank', bank_name],
          ['account', bank_account],
        ],
      };
      return await Requestly()
        .post(`/user/v1/withdraws?userType=pro`, formatedRequest)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // set task detail state to error
          return rejectWithValue(error);
        });
    } catch (error) {
      // set task detail state to error
      return rejectWithValue(error);
    }
  }
);

// update wallet slice initial state
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// create wallet slice reducer and actions
const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    withdrawal: (state, action) => {
      state.state = 'WITHDRAW';
      state.detail = [];
      state.success = false;
      state.errors = [];
    },
    clearDetail: (state, action) => {
      state.detail = {};
      state.state = 'INIT';
      state.success = false;
      state.errors = [];
    },
  },
  extraReducers: {
    [withdrawalWallet.pending]: (state, action) => {
      state.state = 'PENDING';
    },
    [withdrawalWallet.fulfilled]: (state, action) => {
      state.state = 'DONE';
      state.success = true;
      state.errors = [];
      state.detail = action.payload;
      state.state = 'REFETCHING';
    },
    [withdrawalWallet.rejected]: (state, action) => {
      state.state = 'ERROR';
      state.success = false;
      state.errors = action.payload;
    },
  },
});

export const { withdrawal, clearDetail } = walletSlice.actions;
export const selectWalletState = ({ app }) => app.dashboard.wallet;
export const selectWallet = ({ app }) => app.dashboard.wallet.detail;

export default walletSlice.reducer;
