import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import { Grid } from '@gvlab/react-theme/mui';

/**
 * Grid Item encap
 *
 */
const GridItem = (props) => {
  // const useStyles = makeStyles({
  //   grid: {
  //     position: 'relative',
  //     width: '100%',
  //     minHeight: '1px',
  //     paddingRight: '15px',
  //     paddingLeft: '15px',
  //     flexBasis: 'auto'
  //   }
  // });

  // const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
};

GridItem.defaultProps = {
  className: '',
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default GridItem;
