/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@gvlab/react-theme/styles';
import VersionPanel from '@gvlab/react-ui/components/VersionPanel';
import { Routes } from '@gvlab/react-router';
import { AuthGuard } from '@gvlab/react-create-app-gv';
import { GlobalProvider } from '@gvlab/react-lib/Global';
import NavBar from './components/NavBar';
import TopBar from './components/TopBar';
import ProfileBar from './components/ProfileBar';

const drawerWidth = 256;

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));
const StyledCard = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
}));
const StyledNavBar = styled(NavBar)(({ theme }) => ({
  zIndex: 3,
  width: drawerWidth,
  minWidth: drawerWidth,
  flex: '0 0 auto',
}));
const StyledContent = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  flex: '1 1 auto',
  backgroundColor: theme.palette.background.default,
}));
const StyledProfileBar = styled(ProfileBar)(({ theme }) => ({
  width: drawerWidth,
  minWidth: drawerWidth,
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
}));
const StyledTopbar = styled(TopBar)(({ theme }) => ({
  zIndex: 2,
}));
const StyledFooter = styled('div')(({ theme }) => ({
  padding: '24px',
}));

const Dashboard = (props) => {
  const { route, ...rest } = props;
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [openProfileBar, setOpenProfileBar] = useState(false);

  const allowRoles = ['admin', 'superAdmin'];

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const handleProfileBarOpen = () => {
    setOpenProfileBar(true);
  };

  const handleProfileBarClose = () => {
    setOpenProfileBar(false);
  };

  const value = {
    onProfileBarClose: handleProfileBarClose,
    onProfileBarOpen: handleProfileBarOpen,
    onMobileClose: handleNavBarMobileClose,
    onMobileOpen: handleNavBarMobileOpen,
    openMobile: openNavBarMobile,
    openProfile: openProfileBar,
  };

  return (
    <GlobalProvider value={value}>
      <AuthGuard roles={allowRoles}>
        <StyledContainer>
          <StyledCard>
            <StyledNavBar />
            <StyledContent>
              <StyledTopbar position="sticky" title={process?.ctx?.displayName} />
              <Routes paths={route.routes} extra={{ ...rest }} />
              <StyledFooter>
                <VersionPanel />
              </StyledFooter>
            </StyledContent>
            {!!openProfileBar && <StyledProfileBar />}
          </StyledCard>
        </StyledContainer>
      </AuthGuard>
    </GlobalProvider>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
