/* eslint-disable import/no-anonymous-default-export */
import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      ...typography.body3,
      // borderBottom: `1px solid ${palette.divider}`,
    },
  },
};
