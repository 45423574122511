/* eslint-disable import/no-extraneous-dependencies */
import { createContext, useContext } from 'react';

export const GlobalContext = createContext();

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider');
  }
  return context;
};
