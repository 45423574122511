import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Drawer } from '@gvlab/react-theme/mui';
import { makeStyles } from '@gvlab/react-theme/styles';
import { isFunction, isObject } from '@gvlab/react-lib/utils';
import CardHeader from '../Card/CardHeader';
import Progress from '../Progress';
import LHSPanel from './LHSPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  drawer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  sdrawer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'visible',
    width: '624px',
  },
  sContent: {
    padding: '0 48px',
    width: 'auto',
    overflow: 'scroll',
  },
  content: {
    padding: '0 16px',
    width: 'auto',
    overflow: 'scroll',
  },
  contentNoPadding: {
    padding: '0',
    width: 'auto',
  },
  '@media (max-width: 624px)': {
    sdrawer: {
      width: '100vw',
    },
  },
  '@media (min-width: 480px)': {
    drawer: {
      width: '87vw',
    },
    content: {
      padding: '0 24px',
    },
  },
  '@media (min-width: 582px)': {
    drawer: {
      width: '89vw',
    },
  },
  '@media (min-width: 640px)': {
    drawer: {
      width: '90vw',
    },
  },
  '@media (min-width: 712px)': {
    drawer: {
      width: '91vw',
    },
  },
  '@media (min-width: 768px)': {
    content: {
      padding: '0 48px',
    },
  },
  '@media (min-width: 800px)': {
    drawer: {
      width: '92vw',
    },
  },
  '@media (min-width: 914px)': {
    drawer: {
      width: '93vw',
    },
    content: {
      paddingLeft: 'calc((93vw - 768px)/2)',
      paddingRight: 'calc((93vw - 768px)/2)',
    },
  },
  '@media (min-width: 1035px)': {
    drawer: {
      width: '92vw',
    },
    content: {
      paddingLeft: 'calc((92vw - 768px)/2)',
      paddingRight: 'calc((92vw - 768px)/2)',
    },
  },
  '@media (min-width: 1104px)': {
    drawer: {
      width: '91vw',
    },
    content: {
      paddingLeft: 'calc((91vw - 768px)/2)',
      paddingRight: 'calc((91vw - 768px)/2)',
    },
  },
  '@media (min-width: 1172px)': {
    drawer: {
      width: '90vw',
    },
    content: {
      paddingLeft: 'calc((90vw - 768px)/2)',
      paddingRight: 'calc((90vw - 768px)/2)',
    },
  },
  '@media (min-width: 1244px)': {
    drawer: {
      width: '89vw',
    },
    content: {
      paddingLeft: 'calc((89vw - 960px)/2)',
      paddingRight: 'calc((89vw - 960px)/2)',
    },
  },
  '@media (min-width: 1312px)': {
    drawer: {
      width: '88vw',
    },
    content: {
      paddingLeft: 'calc((88vw - 960px)/2)',
      paddingRight: 'calc((88vw - 960px)/2)',
    },
  },
  '@media (min-width: 1450px)': {
    drawer: {
      width: '86vw',
    },
    content: {
      paddingLeft: 'calc((86vw - 960px)/2)',
      paddingRight: 'calc((86vw - 960px)/2)',
    },
  },
  '@media (min-width: 1560px)': {
    drawer: {
      width: '84vw',
    },
    content: {
      paddingLeft: 'calc((84vw - 960px)/2)',
      paddingRight: 'calc((84vw - 960px)/2)',
    },
  },
  '@media (min-width: 1728px)': {
    drawer: {
      width: '82vw',
    },
    content: {
      paddingLeft: 'calc((82vw - 960px)/2)',
      paddingRight: 'calc((82vw - 960px)/2)',
    },
  },
  '@media (min-width: 1868px)': {
    drawer: {
      width: '80vw',
    },
    content: {
      paddingLeft: 'calc((80vw - 1152px)/2)',
      paddingRight: 'calc((80vw - 1152px)/2)',
    },
  },
  '@media (min-width: 2004px)': {
    drawer: {
      width: '78vw',
    },
    content: {
      paddingLeft: 'calc((78vw - 1152px)/2)',
      paddingRight: 'calc((78vw - 1152px)/2)',
    },
  },
  '@media (min-width: 2144px)': {
    drawer: {
      width: '76vw',
    },
    content: {
      paddingLeft: 'calc((76vw - 1280px)/2)',
      paddingRight: 'calc((76vw - 1280px)/2)',
    },
  },
  '@media (min-width: 2284px)': {
    drawer: {
      width: '74vw',
    },
    content: {
      paddingLeft: 'calc((74vw - 1280px)/2)',
      paddingRight: 'calc((74vw - 1280px)/2)',
    },
  },
  '@media (min-width: 2420px)': {
    drawer: {
      width: '72vw',
    },
    content: {
      paddingLeft: 'calc((72vw - 1280px)/2)',
      paddingRight: 'calc((72vw - 1280px)/2)',
    },
  },
  '@media (min-width: 2560px)': {
    drawer: {
      width: '70vw',
    },
    content: {
      paddingLeft: 'calc((70vw - 1280px)/2)',
      paddingRight: 'calc((70vw - 1280px)/2)',
    },
  },
}));

const CustomDrawer = (props) => {
  const {
    anchor,
    actions: ActionControl,
    avatar: Avatar,
    children,
    border,
    onClose,
    title,
    fullSize,
    variant,
    progress,
    ...rest
  } = props;

  const classes = useStyles();

  const renderActionsComponent = (props) => {
    if (isFunction(ActionControl)) return ActionControl({ ...props, onClose});
    if (isObject(ActionControl)) {
      return ActionControl({ ...props, onClose});
    };
    return <></>;
  };

  const renderCloseComponent = (props) => {
    if (isFunction(Avatar)) return Avatar({ ...props, onClose});
    if (isObject(Avatar)) {
      return <Avatar { ...props} onClose={onClose} />
    };
    return <LHSPanel { ...props} onClose={onClose} />
  };

  const ListBaseDrawer = (props) => {
    return (
      <Drawer
        {...rest}
        SlideProps={{ unmountOnExit: true }}
        anchor={anchor}
        classes={{ paper: fullSize ? classes.drawer : classes.sdrawer }}
      >
        {props.children}
      </Drawer>
    );
  };
  const ListBase = (props) => {
    if (variant === 'drawer') return ListBaseDrawer(props);
    return <div>{props.children}</div>;
  };

  const getContentStyle = () => {
    if (border === 'none') return classes.contentNoPadding;
    return fullSize ? classes.content : classes.sContent;
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 200 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ bounceDamping: 0 }}
    >
      <ListBase>
        <CardHeader action={renderActionsComponent} avatar={renderCloseComponent} title={title} />
        <div className={getContentStyle()}>{children}</div>
        {progress && <Progress />}
      </ListBase>
    </motion.div>
  );
};

CustomDrawer.defaultProps = {
  border: 'normal',
  fullSize: true,
  anchor: 'right',
  variant: 'drawer',
  progress: false,
};

CustomDrawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
  avatar: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.node,
  border: PropTypes.string,
  fullSize: PropTypes.bool,
  progress: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  variant: PropTypes.oneOf(['panel', 'drawer']),
};

export default CustomDrawer;
