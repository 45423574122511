/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button } from '@gvlab/react-ui/v2';
import { addFund } from 'store/Dashboard/paymentSlice';
import { selectMediaDetail } from 'store/Dashboard/mediaSlice';

export const ActionBar = (props) => {
  const mediaDetail = useSelector(selectMediaDetail);
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  return (
    <div className="pt-4 space-y-2">
      <div className="space-y-6">
        {/* <div className="flex justify-between text-lg ">
          <span>Fee</span>
        </div>
        <div>
          <div className="flex justify-between">
            <span>-</span>
            <span>0</span>
          </div>
        </div> */}
        <Button
          fullWidth
          className="py-2 font-semibold border rounded bg-blue-600 text-gray-50 border-blue-600"
          onClick={(event) => {
            const request = getValues();

            if (mediaDetail?.id) {
              request.receipt = mediaDetail.id;
            }

            dispatch(addFund({ request }));
          }}
        >
          Process
        </Button>
      </div>
    </div>
  );
};
