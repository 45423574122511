/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';

const StyledComponent = {
  styleOverrides: {
    root: {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      '&.bordered': {
        borderBottom: `1px solid ${palette.secondary.dark}`,
      },
    },
  },
};

export default StyledComponent;
