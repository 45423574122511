/* eslint-disable import/no-anonymous-default-export */
import palette from '../palette';
import typography from '../typography';

export default {
  title: {
    fontFamily: typography.fontFamily,
    ...typography.h6,
  },
  item: {
    display: 'block',
    padding: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: palette.secondary.contrastText,
  },
  buttonLeaf: {
    borderRadius: '0',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: typography.fontWeightMedium,
    },
    '&.depth-1': {
      fontWeight: typography.fontWeightMedium,
      borderBottom: `1px solid ${palette.secondary.light}`,
    },
    '[depth="depth=0"]': {
      fontWeight: typography.fontWeightMedium,
    },
    '[depth="depth=1"]': {
      fontWeight: typography.fontWeightMedium,
      borderBottom: `1px solid ${palette.secondary.light}`,
    },
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
    '& > svg': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
    },
    '& .expandIcon': {
      marginLeft: 'auto',
      height: 16,
      width: 16,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: palette.secondary.main,
    fontWeight: typography.fontWeightMedium,
    '&.depth-0': {
      backgroundColor: palette.primary.main,
      borderRadius: '50vh',
      color: palette.primary.contrastText,
    },
    '&.depth-1': {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
    },
    '& $icon': {
      color: palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
};
