const status2Code = (status) => {
  if (status === 0) return 'EMPTY_CODE';
  if (status === 100) return 'CONTINUE';
  if (status === 100) return 'CONTINUE';
  if (status === 101) return 'SWITCHING_PROTOCOLS';
  if (status === 200) return 'OK';
  if (status === 201) return 'CREATED';
  if (status === 202) return 'ACCEPTED';
  if (status === 203) return 'NON_AUTHORITATIVE_INFORMATION';
  if (status === 204) return 'NO_CONTENT';
  if (status === 205) return 'RESET_CONTENT';
  if (status === 206) return 'PARTIAL_CONTENT';

  if (status === 300) return 'MULTIPLE_CHOICES';
  if (status === 301) return 'MOVED_PERMANENTLY';
  if (status === 302) return 'FOUND';
  if (status === 303) return 'SEE_OTHER';
  if (status === 304) return 'NOT_MODIFIED';
  if (status === 307) return 'TEMPORARY_REDIRECT';
  if (status === 308) return 'PERMANENT_REDIRECT';

  if (status === 400) return 'BAD_REQUEST';
  if (status === 401) return 'UNAUTHORIZED';
  if (status === 403) return 'FORBIDDEN';
  if (status === 404) return 'NOT_FOUND';
  if (status === 405) return 'METHOD_NOT_ALLOWED';
  if (status === 406) return 'NOT_ACCEPTABLE';
  if (status === 407) return 'PROXY_AUTHENTICATION_REQUIRED';
  if (status === 408) return 'REQUEST_TIMEOUT';
  if (status === 409) return 'CONFLICT';
  if (status === 410) return 'GONE';
  if (status === 411) return 'LENGTH_REQUIRED';
  if (status === 412) return 'PRECONDITION_FAILED';
  if (status === 413) return 'PAYLOAD_TOO_LARGE';
  if (status === 414) return 'URI_TOO_LONG';
  if (status === 415) return 'UNSUPPORTED_MEDIA_TYPE';
  if (status === 416) return 'RANGE_NOT_SATISFIABLE';
  if (status === 417) return 'EXPECTATION_FAILED';
  if (status === 422) return 'UNPROCESSABLE_ENTITY';
  if (status === 425) return 'TOO_EARLY';
  if (status === 426) return 'UPGRADE_REQUIRED';
  if (status === 428) return 'PRECONDITION_REQUIRED';
  if (status === 429) return 'TOO_MANY_REQUESTS';
  if (status === 431) return 'REQUEST_HEADER_FIELDS_TOO_LARGE';
  if (status === 451) return 'UNAVAILABLE_FOR_LEGAL_REASONS';

  if (status === 500) return 'INTERNAL_SERVER_ERROR';
  if (status === 501) return 'NOT_IMPLEMENTED';
  if (status === 502) return 'BAD_GATEWAY';
  if (status === 503) return 'SERVICE_UNAVAILABLE';
  if (status === 505) return 'HTTP_VERSION_NOT_SUPPORTED';
  if (status === 511) return 'NETWORK_AUTHENTICATION_REQUIRED';
  if (status === 512) return 'NETWORK_FAILURE';

  return 'EMPTY_CODE';
};

export default status2Code;
