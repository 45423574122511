import { ThemeProvider } from '../styles';
import { CssBaseline } from '../mui';

import { palette, theme } from './v6';

export const CustomThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { palette, CssBaseline };
