/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-multi-comp */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDialogContext, AuthGuard } from '@gvlab/react-create-app-gv';
import { GlobalProvider } from '@gvlab/react-lib/Global';

import { GvRoutes as Routes } from '@gvlab/react-router';
import { styled } from '@gvlab/react-theme/styles';
import TopBar from './components/TopBar';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
  height: '100vh',
  overflow: 'hidden',
}));

const StyledContent = styled('div')(({ theme }) => ({
  // width: theme.breakpoints.values.lg,
  height: '100vh',
  width: '100vw',
  maxWidth: '975px',
  margin: '0 auto',
  // padding: `100px ${theme.spacing(3)}px 50px`,
  padding: '30px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '15px 0',
    maxWidth: '100vw',
  },
}));

const UserDashboard = (props) => {
  const { route } = props;
  const dialog = useDialogContext();
  const allowRoles = ['admin', 'superAdmin'];

  useEffect(() => {
    dialog.clear();
  }, []);

  return (
    <GlobalProvider>
      <AuthGuard roles={allowRoles}>
        <StyledContainer>
          <TopBar />
          <StyledContent>
            <Routes paths={route.routes} />
          </StyledContent>
        </StyledContainer>
      </AuthGuard>
    </GlobalProvider>
  );
};

UserDashboard.propTypes = {
  route: PropTypes.object,
};

export default UserDashboard;
