/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable radix */
import * as date from './dateUtils';

export const isString = (param, defaultVal = '') => param || defaultVal;
export const isNumber = (param, defaultVal = 0) => parseInt(param || defaultVal);
export const isDate = (param, defaultVal = '1950-01-01') => date.dateToUnix(param || defaultVal);
export const isBool = (param, defaultVal = false) => param || defaultVal;
export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (obj?.hasOwnProperty && obj?.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};
