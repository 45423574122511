import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';
import { clientType } from 'config/environment';

const initialState = {
  state: 'idle',
  success: false,
  errors: false,
  detail: null,
};

export const login = createAsyncThunk(
  'authen/login',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .put('/user/v1/user/login', { email, password, userType: 'pro' })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk('authen/logout', async (_, { dispatch }) => {
  dispatch(login());
});

export const register = createAsyncThunk(
  'authen/register',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      return await Requestly()
        .put('/user/v1/user/register', { email, password, userType: clientType })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const init = createAsyncThunk('authen/init', async (_, { dispatch }) => 'done');

// create slice for auth
const authSlice = createSlice({
  name: 'authen',
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending]: (state) => {
      return { ...state, state: 'login' };
    },
    [login.fulfilled]: (state, action) => {
      return { ...state, state: 'done', success: true, detail: action.payload };
    },

    [init.fulfilled]: (state) => {
      return { ...initialState };
    },
  },
});

export default authSlice.reducer;

export const selectAuthen = ({ app }) => app.auth.forgotPassword;
