/* eslint-disable import/no-extraneous-dependencies */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Requestly from '@gvlab/requestly';

// initial state of media
const initialState = {
  state: 'INIT',
  success: false,
  errors: [],
  detail: {},
};

// action to view media detail
// this action will be used in MediaDetail.js
export const viewDetail = createAsyncThunk(
  'media/viewDetail',
  async ({ mediaId }, { dispatch, rejectWithValue }) => {
    try {
      // set media detail state to pending
      return await Requestly()
        .get(`/user/v1/media/${mediaId}?userType=pro`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// upload media thunk action creator to upload media from API
// and return response data to reducer to update state in store
// for media detail page to use it to display data in UI components (e.g. MediaDetail.js)
export const uploadMedia = createAsyncThunk(
  'media/uploadMedia',
  async ({ media }, { dispatch, rejectWithValue }) => {
    try {
      const FormData = require('form-data');
      const data = new FormData();
      data.append('file', media);

      const response = await Requestly().uploadFile(`/user/v1/media/upload?userType=pro`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// clear media detail state
export const clearDetail = createAsyncThunk('media/clearDetail', async (payload, { dispatch }) => {
  return true;
});

// media detail slice reducer
const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers: {
    [viewDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        success: true,
        detail: action.payload,
      };
    },
    [viewDetail.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        errors: action.payload,
      };
    },
    [uploadMedia.fulfilled]: (state, action) => {
      return {
        ...initialState,
        state: 'DONE',
        detail: action.payload,
      };
    },
    [uploadMedia.pending]: (state, action) => {
      return {
        ...initialState,
        state: 'PENDING',
      };
    },
    [uploadMedia.rejected]: (state, action) => {
      return {
        ...initialState,
        state: 'ERROR',
        errors: action.payload,
      };
    },
    [clearDetail.fulfilled]: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

// export media detail state selector
export const selectMedia = ({ app }) => app.dashboard.media;
export const selectMediaDetail = ({ app }) => app.dashboard.media.detail;

// export media detail state selector
export default mediaSlice.reducer;
