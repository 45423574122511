/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles } from '@gvlab/react-theme/styles';
import { AuthGuard } from '@gvlab/react-create-app-gv';
import { GlobalProvider } from '@gvlab/react-lib/Global';

// import { useMatch, useResolvedPath } from '@gvlab/react-router';
import { GvRoutes as Routes } from '@gvlab/react-router';
import VersionPanel from '@gvlab/react-ui/components/VersionPanel';

import Widget from 'Widget';
import TopBar from 'Widget/TopBar';

const containerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
  },
  // container: {
  //   display: 'flex',
  //   flex: '1 1 auto',
  //   overflow: 'hidden'
  // },
  navBar: {
    zIndex: 3,
    width: containerWidth,
    minWidth: containerWidth,
    flex: '0 0 auto',
  },
  profileBar: {
    width: containerWidth,
    minWidth: containerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  // content: {
  //   overflowY: 'auto',
  //   flex: '1 1 auto',
  //   backgroundColor: theme.palette.background.default,
  // },
  footer: {
    padding: '24px',
    color: theme.palette.background.default,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  scontainer: {
    width: '624px',
  },
  scontent: {
    padding: '0 48px',
    width: 'auto',
  },
  content: {
    padding: '0 16px',
    width: 'auto',
  },
  '@media (min-width: 480px)': {
    container: {
      width: '87vw',
    },
    content: {
      padding: '0 24px',
    },
  },
  '@media (min-width: 582px)': {
    container: {
      width: '89vw',
    },
  },
  '@media (min-width: 640px)': {
    container: {
      width: '90vw',
    },
  },
  '@media (min-width: 712px)': {
    container: {
      width: '91vw',
    },
  },
  '@media (min-width: 768px)': {
    content: {
      padding: '0 48px',
    },
  },
  '@media (min-width: 800px)': {
    container: {
      width: '92vw',
    },
  },
  '@media (min-width: 914px)': {
    container: {
      width: '93vw',
    },
    content: {
      paddingLeft: 'calc((93vw - 768px)/2)',
      paddingRight: 'calc((93vw - 768px)/2)',
    },
  },
  '@media (min-width: 1035px)': {
    container: {
      width: '92vw',
    },
    content: {
      paddingLeft: 'calc((92vw - 768px)/2)',
      paddingRight: 'calc((92vw - 768px)/2)',
    },
  },
  '@media (min-width: 1104px)': {
    container: {
      width: '91vw',
    },
    content: {
      paddingLeft: 'calc((91vw - 768px)/2)',
      paddingRight: 'calc((91vw - 768px)/2)',
    },
  },
  '@media (min-width: 1172px)': {
    container: {
      width: '90vw',
    },
    content: {
      paddingLeft: 'calc((90vw - 768px)/2)',
      paddingRight: 'calc((90vw - 768px)/2)',
    },
  },
  '@media (min-width: 1244px)': {
    container: {
      width: '89vw',
    },
    content: {
      paddingLeft: 'calc((89vw - 960px)/2)',
      paddingRight: 'calc((89vw - 960px)/2)',
    },
  },
  '@media (min-width: 1312px)': {
    container: {
      width: '88vw',
    },
    content: {
      paddingLeft: 'calc((88vw - 960px)/2)',
      paddingRight: 'calc((88vw - 960px)/2)',
    },
  },
  '@media (min-width: 1450px)': {
    container: {
      width: '86vw',
    },
    content: {
      paddingLeft: 'calc((86vw - 960px)/2)',
      paddingRight: 'calc((86vw - 960px)/2)',
    },
  },
  '@media (min-width: 1560px)': {
    container: {
      width: '84vw',
    },
    content: {
      paddingLeft: 'calc((84vw - 960px)/2)',
      paddingRight: 'calc((84vw - 960px)/2)',
    },
  },
  '@media (min-width: 1728px)': {
    container: {
      width: '82vw',
    },
    content: {
      paddingLeft: 'calc((82vw - 960px)/2)',
      paddingRight: 'calc((82vw - 960px)/2)',
    },
  },
  '@media (min-width: 1868px)': {
    container: {
      width: '80vw',
    },
    content: {
      paddingLeft: 'calc((80vw - 1152px)/2)',
      paddingRight: 'calc((80vw - 1152px)/2)',
    },
  },
  '@media (min-width: 2004px)': {
    container: {
      width: '78vw',
    },
    content: {
      paddingLeft: 'calc((78vw - 1152px)/2)',
      paddingRight: 'calc((78vw - 1152px)/2)',
    },
  },
  '@media (min-width: 2144px)': {
    container: {
      width: '76vw',
    },
    content: {
      paddingLeft: 'calc((76vw - 1280px)/2)',
      paddingRight: 'calc((76vw - 1280px)/2)',
    },
  },
  '@media (min-width: 2284px)': {
    container: {
      width: '74vw',
    },
    content: {
      paddingLeft: 'calc((74vw - 1280px)/2)',
      paddingRight: 'calc((74vw - 1280px)/2)',
    },
  },
  '@media (min-width: 2420px)': {
    container: {
      width: '72vw',
    },
    content: {
      paddingLeft: 'calc((72vw - 1280px)/2)',
      paddingRight: 'calc((72vw - 1280px)/2)',
    },
  },
  '@media (min-width: 2560px)': {
    container: {
      width: '70vw',
    },
    content: {
      paddingLeft: 'calc((70vw - 1280px)/2)',
      paddingRight: 'calc((70vw - 1280px)/2)',
    },
  },
}));

const Profile = (props) => {
  const { route, ...rest } = props;
  const classes = useStyles();
  const [openProfileBar, setOpenProfileBar] = useState(false);
  const dispatch = useDispatch();

  const allowRoles = ['staff', 'pro', 'pro-user'];

  const handleProfileBarOpen = () => {
    setOpenProfileBar(true);
  };

  const handleProfileBarClose = () => {
    setOpenProfileBar(false);
  };

  const extraProps = {
    ...rest,
  };

  // global context values (for use in any component)
  const value = useMemo(
    () => ({
      dispatch,
    }),
    []
  );

  return (
    <GlobalProvider values={value}>
      <AuthGuard roles={allowRoles}>
        <div className={classes.root}>
          <TopBar position="sticky" />
          <div className={classes.content}>
            <Routes paths={route.routes} extra={{ ...rest }} />
          </div>
          <div className={classes.footer}>
            <VersionPanel copyright="houzmoni sdn. bhd." />
          </div>
        </div>
        <Widget />
      </AuthGuard>
    </GlobalProvider>
  );
};

Profile.propTypes = {
  route: PropTypes.object,
};

export default Profile;
