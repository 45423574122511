/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fetcher as fetchHelper }  from '@gvlab/requestly';

const defaultParams = {
  serverkey: '',
  host: '',
  timeout: 60000,
  cbFormatAuthHeader: () => {
    return null;
  },
};
/**
 *      REQUEST_OPTIONS = {
 *          headers: {
 *            'User-Agent': 'scrumhr-api/1.0',
 *            'Content-Type': 'application/json; charset=utf-8',
 *            'Connection': 'keep-alive'
 *          },
 *          redirect: 'follow'
 *      },
 */

const AuthApi = (params = defaultParams) => {
  let BASE_URL = params?.host || '';

  const apiClient = fetchHelper({
    ...params.cbFormatAuthHeader,
  });

  /**
   * Authenticate an existing user using their email address.
   * @param email The email address of the user.
   * @param password The password of the user.
   * @param redirectTo A URL or mobile address to send the user to after they are confirmed.
   */
  async function login(body, options) {
    try {
      return await apiClient.put({
        url: `${BASE_URL}/admin/v1/user/login`,
        body,
        options,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function refreshToken(options) {
    try {
      return await apiClient.post(`${BASE_URL}/admin/v1/session/refresh`, options);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getProfile(options) {
    try {
      return await apiClient.get({
        url: `${BASE_URL}/admin/v1/me/storage`,
        options,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getServiceDetails(serviceId, options) {
    try {
      return await apiClient.get({
        url: `${BASE_URL}/admin/v1/services/${serviceId}`,
        options,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  function setFormatAuthHeader(cb) {
    apiClient.setFormatAuthHeader(cb);
  }

  async function logout() {
    try {
      return await apiClient.post({
        url: `${BASE_URL}/auth/v1/session/logout`,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  function setBaseUrl(url) {
    BASE_URL = url;
  }

  return {
    setBaseUrl,
    setFormatAuthHeader,
    getProfile,
    getServiceDetails,
    login,
    logout,
    refreshToken,
  };
};

export default AuthApi;
